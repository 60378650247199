import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { spinnerColorBack, spinnerColorMain } from '../common/Const'
import { AppContext } from './App'

/**
 * ローディング表示
 */
const LoadingScreen: React.FunctionComponent = () => {
    const { state } = React.useContext(AppContext)

    return <Wrapper>{state.isLoading === true ? <Spinner /> : ''}</Wrapper>
}

const Wrapper = styled.div``

const spinnerAnimation = keyframes`
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
`
const Spinner = styled.div`
    position: absolute;
    left: 50%;
    top: 40%;
    height: 60px;
    width: 60px;
    margin: 0px auto;
    animation: ${spinnerAnimation} 1.2s infinite linear;
    border-left: 6px solid ${spinnerColorBack};
    border-right: 6px solid ${spinnerColorBack};
    border-bottom: 6px solid ${spinnerColorBack};
    border-top: 6px solid ${spinnerColorMain};
    border-radius: 100%;
`

export default LoadingScreen
