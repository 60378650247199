import { CaptureConsole } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import * as React from 'react'
import ReactDOM from 'react-dom'
import packageJson from '../package.json'
import App from './components/App'
import './index.css'
import reportWebVitals from './reportWebVitals'

Sentry.init({
    dsn: 'https://80c51af6c1d440fdb3926b8cfd546a57@o571297.ingest.sentry.io/5756103',
    integrations: [
        new CaptureConsole({
            levels: ['error'],
        }),
    ],
    environment: process.env.REACT_APP_TARGET ?? 'localhost',
    release: `field-viwer@${packageJson.version}`,
})

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
