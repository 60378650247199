/* eslint-disable no-console */
import axios from 'axios'
import React from 'react'
import packageJson from '../../package.json'
import { AppContext, getAxiosConfig } from '../components/App'
import { V3Api } from '../lib-ns-service'

export interface Logger {
    error(message: string, detail?: any): void

    info(message: string, detail?: any): void

    warn(message: string, detail?: any): void

    debug(message: string, detail?: any): void
}

export const useLogger = (): Logger => {
    const { state } = React.useContext(AppContext)

    const postLog = (level: string, message: string, detail?: any) => {
        const config = getAxiosConfig()
        const v3Api = new V3Api(config, config.basePath, axios)
        const userOrgId = state.user?.userorganizationSet?.[0]?.id
        if (userOrgId) {
            v3Api.v3MobileAppLogCreate({
                level,
                appName: packageJson.name,
                message,
                detail,
                userOrganization: userOrgId,
            })
        }
    }

    return {
        error(message: string, detail?: any) {
            postLog('error', message, detail)
            console.error(message)
        },

        info(message: string, detail?: any) {
            postLog('info', message, detail)
            console.info(message)
        },

        warn(message: string, detail?: any) {
            postLog('warn', message, detail)
            console.warn(message)
        },

        debug(message: string, detail?: any) {
            postLog('debug', message, detail)
            console.debug(message)
        },
    }
}
