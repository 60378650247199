import axios, { AxiosPromise } from 'axios'
import * as React from 'react'
import Select, { ActionMeta, OptionTypeBase } from 'react-select'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'
import { listitemSelectedColor } from '../common/Const'
import { useLogger } from '../common/Logger'
import {
    JigApiV3ActivationCreate,
    JigApiV3ActivationCreateResponse,
    JigApiV3DeactivationCreate,
    JigApiV3Pairing,
    V3Api,
} from '../lib-ns-service'
import { ActionType } from '../store/Store'
import { AppContext, getAxiosConfig } from './App'

interface GidListItem {
    name: string
    bs?: DeviceItem
    tb?: DeviceItem
    ac?: DeviceItem
    ca?: DeviceItem
    sy?: DeviceItem
}

function isGidListItemProperty(v: string): v is keyof GidListItem {
    return v === 'name' || v === 'tb' || v === 'bs' || v === 'ac' || v === 'ca' || v === 'sy'
}

interface DeviceItem {
    serial: string
    imei: string
    imsi: string
}

function instanceOfDeviceItem(object: any): object is DeviceItem {
    if (!object) return false
    return 'serial' in object && 'imei' in object && 'imsi' in object
}

interface SelectState {
    [key: string]: string
    gid: string
    bs: string
    tb: string
    ac: string
    ca: string
    sy: string
}

interface SelectOptionItem {
    label: string
    value: string
}

const defaultSelectState = { gid: '', bs: '', tb: '', ac: '', ca: '', sy: '' }
const removeValue = 'remove'
const removeOption = { value: removeValue, label: '<解除>' }

const GET_PAIRING_LIST_LIMIT = 1000

/**
 * GID一覧
 */
const GidList: React.FunctionComponent = () => {
    const log = useLogger()
    const { state, dispatch } = React.useContext(AppContext)

    const [vpnList] = React.useState(Array<GidListItem>())
    const [vpnListLength, setVpnListLength] = React.useState(0)

    const [bsOptionsDefault] = React.useState(Array<SelectOptionItem>())
    const [tbOptionsDefault] = React.useState(Array<SelectOptionItem>())
    const [acOptionsDefault] = React.useState(Array<SelectOptionItem>())
    const [caOptionsDefault] = React.useState(Array<SelectOptionItem>())
    const [syOptionsDefault] = React.useState(Array<SelectOptionItem>())

    const [bsOptions] = React.useState(Array<SelectOptionItem>())
    const [tbOptions] = React.useState(Array<SelectOptionItem>())
    const [acOptions] = React.useState(Array<SelectOptionItem>())
    const [caOptions] = React.useState(Array<SelectOptionItem>())
    const [syOptions] = React.useState(Array<SelectOptionItem>())

    const [selectedIndex, setSelectIndex] = React.useState(-1)
    const [selectState, setSelectState] = React.useState<SelectState>(defaultSelectState)
    const [errorMsg, setErrorMsg] = React.useState('')

    React.useEffect(() => {
        getVpnList()
    }, [state.user])

    // APIから取得したデータを表示用データに変換する
    const convApiData = (data: JigApiV3Pairing): GidListItem => {
        const ret: GidListItem = {
            name: data.vpnHub ?? '',
        }

        if (data.droneBs) {
            ret.bs = {
                serial: data.droneBs.serialNumber,
                imei: data.droneBs.imei,
                imsi: data.droneBs.imsi,
            }
        }
        if (data.droneTb) {
            ret.tb = {
                serial: data.droneTb.serialNumber,
                imei: data.droneTb.imei,
                imsi: data.droneTb.imsi,
            }
        }
        if (data.droneAc) {
            ret.ac = {
                serial: data.droneAc.serialNumber,
                imei: data.droneAc.imei,
                imsi: data.droneAc.imsi,
            }
        }
        if (data.droneCa) {
            ret.ca = {
                serial: data.droneCa.serialNumber,
                imei: '',
                imsi: '',
            }
        }
        if (data.droneSy) {
            ret.sy = {
                serial: data.droneSy.serialNumber,
                imei: data.droneSy.imei,
                imsi: data.droneSy.imsi,
            }
        }

        return ret
    }

    // APIサーバからVPN一覧を取得
    const getVpnList = () => {
        log.info('GidList#getVpnList')
        setErrorMsg('')
        setSelectIndex(-2)
        vpnList.splice(0)
        setVpnListLength(0)

        dispatch({ type: ActionType.ACTION_SET_LOADING, payload: { isLoading: true } })

        const config = getAxiosConfig()
        const v3Api = new V3Api(config, config.basePath, axios)
        v3Api
            .v3JigPairingList(GET_PAIRING_LIST_LIMIT)
            .then((result) => {
                vpnList.splice(0)
                result.data.results.forEach((data) => {
                    vpnList.push(convApiData(data))
                })
                setVpnListLength(vpnList.length)
                // 各プルダウンの選択候補を作成
                createSelectOptions('bs', bsOptionsDefault)
                createSelectOptions('tb', tbOptionsDefault)
                createSelectOptions('ac', acOptionsDefault)
                createSelectOptions('ca', caOptionsDefault)
                createSelectOptions('sy', syOptionsDefault)
            })
            .catch((ex: any) => {
                log.error('VPN一覧の取得に失敗', ex)
            })
            .finally(() => {
                dispatch({ type: ActionType.ACTION_SET_LOADING, payload: { isLoading: false } })
            })
    }

    // deactivate実行
    const postDeactivate = (): AxiosPromise<JigApiV3DeactivationCreate> => {
        log.info('GidList#postDeactivate')

        const target = vpnList[selectedIndex]

        const config = getAxiosConfig()
        const v3Api = new V3Api(config, config.basePath, axios)
        const param: JigApiV3DeactivationCreate = { gid: target.name }

        return v3Api.v3JigDeactivationCreate(param)
    }

    // activate実行
    const postActivate = (): AxiosPromise<JigApiV3ActivationCreateResponse> => {
        log.info('GidList#postActivate')

        const config = getAxiosConfig()
        const v3Api = new V3Api(config, config.basePath, axios)
        const param: JigApiV3ActivationCreate = {
            gid: selectState.gid,
            bs: { serialNumber: selectState.bs },
        }

        if (selectState.tb !== '' && selectState.tb !== removeValue)
            param.tb = { serialNumber: selectState.tb }
        if (selectState.ac !== '' && selectState.ac !== removeValue)
            param.ac = { serialNumber: selectState.ac }
        if (selectState.ca !== '' && selectState.ca !== removeValue)
            param.ca = { serialNumber: selectState.ca }
        if (selectState.sy !== '' && selectState.sy !== removeValue)
            param.sy = { serialNumber: selectState.sy }

        return v3Api.v3JigActivationCreate(param)
    }

    // SelectBoxのオプションを作成
    const createSelectOptions = (name: string, optionsDefault: SelectOptionItem[]) => {
        optionsDefault.splice(0)
        optionsDefault.push(removeOption)
        for (let i = 1; i < 300; i++) {
            const serial = `${name.toUpperCase()}2${`00000${i}`.slice(-5)}`
            const index = vpnList.findIndex((vpn) => {
                if (!isGidListItemProperty(name)) return false
                const item = vpn[name]
                if (!instanceOfDeviceItem(item)) return false
                return item.serial === serial
            })
            // 表に存在しないもののみ選択候補に追加
            if (index === -1) {
                optionsDefault.push({ value: serial, label: serial })
            }
        }
    }

    const handleSelectRow = (index: number) => {
        if (index === selectedIndex) return
        if (index === -1) {
            setSelectIndex(index)
            setSelectState({ gid: '', bs: '', tb: '', ac: '', ca: '', sy: '' })
        } else {
            const item = vpnList[index]
            const bsSerial = item.bs?.serial ?? ''
            const tbSerial = item.tb?.serial ?? ''
            const acSerial = item.ac?.serial ?? ''
            const caSerial = item.ca?.serial ?? ''
            const sySerial = item.sy?.serial ?? ''
            setSelectState({
                gid: item.name,
                bs: bsSerial,
                tb: tbSerial,
                ac: acSerial,
                ca: caSerial,
                sy: sySerial,
            })
            setSelectIndex(index)
        }
    }

    const handleSelectChange = (value: any, actionMeta: ActionMeta<OptionTypeBase>) => {
        if (!actionMeta.name) return

        if (value) {
            setSelectState({ ...selectState, [actionMeta.name]: value.value })
        } else {
            setSelectState({ ...selectState, [actionMeta.name]: '' })
        }
    }

    // Gid選択用SelectBoxのJSXを作成
    const createSelectGidBox = (): JSX.Element => {
        const options = Array<SelectOptionItem>()
        options.push({ label: 'FTEST01', value: 'FTEST01' })
        options.push({ label: 'FTEST02', value: 'FTEST02' })
        for (let i = 200; i < 480; i++) {
            const gid = `G${`00000${i}`.slice(-5)}`
            const index = vpnList.findIndex((vpn) => {
                return vpn.name === gid
            })
            if (index === -1) {
                options.push({ label: gid, value: gid })
            }
        }
        return (
            <Select
                options={options}
                name="gid"
                onChange={handleSelectChange}
                isSearchable={true}
                placeholder=""
                value={{ label: selectState.gid, value: selectState.gid }}
            />
        )
    }

    // SelectBoxのJSXを作成
    const createSelectBox = (
        name: string,
        options: Array<SelectOptionItem>,
        optionsDefault: Array<SelectOptionItem>
    ): JSX.Element => {
        const value = { label: selectState[name], value: selectState[name] }
        options.splice(0)
        options.push(value)
        Array.prototype.push.apply(options, optionsDefault)
        return (
            <Select
                options={options}
                value={value}
                name={name}
                onChange={handleSelectChange}
                isSearchable={true}
                placeholder=""
            />
        )
    }

    const isChanged = (isSelected: boolean, itemState: string, item?: DeviceItem): boolean => {
        if (!isSelected) return false
        const serial = item?.serial ?? ''
        return itemState !== serial
    }

    const showChangeError = (e: any) => {
        let errorDetail = ''
        if (e.response?.data?.errors instanceof Array) {
            const errors: Array<string> = e.response?.data?.errors
            errors.forEach((v) => {
                errorDetail += `\n${v}`
            })
        }
        if (e.response?.data?.errors?.bs?.serialNumber) {
            errorDetail += `\n${e.response?.data?.errors?.bs?.serialNumber}`
        }
        if (e.response?.data?.errors?.tb?.serialNumber) {
            errorDetail += `\n${e.response?.data?.errors?.tb?.serialNumber}`
        }
        if (e.response?.data?.errors?.ac?.serialNumber) {
            errorDetail += `\n${e.response?.data?.errors?.ac?.serialNumber}`
        }
        if (e.response?.data?.errors?.ca?.serialNumber) {
            errorDetail += `\n${e.response?.data?.errors?.ca?.serialNumber}`
        }
        if (e.response?.data?.errors?.sy?.serialNumber) {
            errorDetail += `\n${e.response?.data?.errors?.sy?.serialNumber}`
        }
        setErrorMsg(`更新処理に失敗 ${e} ${errorDetail}`)
        log.error('更新処理に失敗', e)
    }

    const getChangeInfo = (item: GidListItem) => {
        const bsStr = `${selectState.bs === '' ? '無し' : selectState.bs} ${
            isChanged(true, selectState.bs, item.bs) ? '(変更)' : ''
        }`
        const tbStr = `${selectState.tb === '' ? '無し' : selectState.tb} ${
            isChanged(true, selectState.tb, item.tb) ? '(変更)' : ''
        }`
        const acStr = `${selectState.ac === '' ? '無し' : selectState.ac} ${
            isChanged(true, selectState.ac, item.ac) ? '(変更)' : ''
        }`
        const caStr = `${selectState.ca === '' ? '無し' : selectState.ca} ${
            isChanged(true, selectState.ca, item.ca) ? '(変更)' : ''
        }`
        const syStr = `${selectState.sy === '' ? '無し' : selectState.sy} ${
            isChanged(true, selectState.sy, item.sy) ? '(変更)' : ''
        }`

        return (
            ` GID : ${selectState.gid}\n` +
            ` BS : ${bsStr}\n` +
            ` TB : ${tbStr}\n` +
            ` AC : ${acStr}\n` +
            ` CA : ${caStr}\n` +
            ` SY : ${syStr}`
        )
    }

    // 新規作成ボタン押下処理
    const handleSubmitCreate = async (item: GidListItem) => {
        // eslint-disable-next-line no-alert
        const res = window.confirm(`新規追加しますか\n\n${getChangeInfo(item)}`)
        if (!res) return

        setErrorMsg('')
        log.info('新規追加', selectState)

        try {
            const result = await postActivate()
            log.info('activateに成功', result)
            getVpnList()
        } catch (e) {
            showChangeError(e)
        }
    }

    // 変更ボタン押下処理
    const handleSubmitChange = async (item: GidListItem) => {
        // eslint-disable-next-line no-alert
        const res = window.confirm(`変更を実行しますか\n\n${getChangeInfo(item)}`)
        if (!res) return

        setErrorMsg('')
        log.info('変更', selectState)

        try {
            if (selectState.bs) {
                const r1 = await postDeactivate()
                log.info('deactivateに成功', r1)
            }

            if (selectState.bs !== removeValue) {
                const r2 = await postActivate()
                log.info('activateに成功', r2)
            }
            getVpnList()
        } catch (e) {
            showChangeError(e)
        }
    }

    const isAcCaSameNumber = (): boolean => {
        const acNum = selectState.ac.substr(2)
        const caNum = selectState.ca.substr(2)
        return acNum === caNum
    }

    // 表を描画
    const renderTableRow = () => {
        const list: JSX.Element[] = []

        for (let i = -1; i < vpnList.length; i++) {
            let item: GidListItem = { name: '' }
            if (i >= 0) item = vpnList[i]
            const isSelected = selectedIndex === i

            // 通常はテキスト、選択状態の場合はプルダウンを表示
            let gid: string | JSX.Element = '          '
            let bsSerial: string | JSX.Element = item.bs?.serial ?? ''
            let tbSerial: string | JSX.Element = item.tb?.serial ?? ''
            let acSerial: string | JSX.Element = item.ac?.serial ?? ''
            let caSerial: string | JSX.Element = item.ca?.serial ?? ''
            let sySerial: string | JSX.Element = item.sy?.serial ?? ''
            if (isSelected) {
                gid = createSelectGidBox()
                bsSerial = createSelectBox('bs', bsOptions, bsOptionsDefault)
                tbSerial = createSelectBox('tb', tbOptions, tbOptionsDefault)
                acSerial = createSelectBox('ac', acOptions, acOptionsDefault)
                caSerial = createSelectBox('ca', caOptions, caOptionsDefault)
                sySerial = createSelectBox('sy', syOptions, syOptionsDefault)
            }

            let submitButton: string | JSX.Element = ''
            if (isSelected && i === -1)
                submitButton = <button onClick={() => handleSubmitCreate(item)}>新規追加</button>
            else if (isSelected && i !== -1)
                submitButton = (
                    <button
                        onClick={() => handleSubmitChange(item)}
                        disabled={selectState.bs === ''}
                    >
                        変更
                    </button>
                )

            list.push(
                <GroupTableRow
                    key={uuidV4()}
                    onMouseDown={() => handleSelectRow(i)}
                    selected={isSelected}
                >
                    <GroupTableCellNo>{i === -1 ? '新規' : i + 1}</GroupTableCellNo>
                    <GroupTableCellGid>{i === -1 ? gid : item.name}</GroupTableCellGid>
                    <GroupTableCell2 isChanged={isChanged(isSelected, selectState.bs, item.bs)}>
                        {bsSerial} <br />
                        IMEI: {item.bs?.imei} <br />
                        IMSI: {item.bs?.imsi} <br />
                    </GroupTableCell2>
                    <GroupTableCell2 isChanged={isChanged(isSelected, selectState.tb, item.tb)}>
                        {tbSerial} <br />
                        IMEI: {item.tb?.imei} <br />
                        IMSI: {item.tb?.imsi} <br />
                        {isChanged(isSelected, selectState.tb, item.tb) &&
                        selectState.tb !== removeValue ? (
                            <WarnMessage>
                                GIDとのペアを変更した場合、再キッティングが必要です。
                            </WarnMessage>
                        ) : undefined}
                    </GroupTableCell2>
                    <GroupTableCell2 isChanged={isChanged(isSelected, selectState.ac, item.ac)}>
                        {acSerial} <br />
                        IMEI: {item.ac?.imei} <br />
                        IMSI: {item.ac?.imsi} <br />
                        {isSelected && !isAcCaSameNumber() ? (
                            <WarnMessage>CAとシリアル番号が異なります。</WarnMessage>
                        ) : undefined}
                    </GroupTableCell2>
                    <GroupTableCell2 isChanged={isChanged(isSelected, selectState.ca, item.ca)}>
                        {caSerial} <br />
                        {isSelected && !isAcCaSameNumber() ? (
                            <WarnMessage>ACとシリアル番号が異なります。</WarnMessage>
                        ) : undefined}
                        <br />
                        <br />
                    </GroupTableCell2>
                    <GroupTableCell2 isChanged={isChanged(isSelected, selectState.sy, item.sy)}>
                        {sySerial} <br />
                        IMEI: {item.sy?.imei} <br />
                        IMSI: {item.sy?.imsi} <br />
                    </GroupTableCell2>
                    <GroupTableCell>{submitButton}</GroupTableCell>
                </GroupTableRow>
            )
        }
        return <>{list}</>
    }

    return (
        <Wrapper>
            <Contents>
                <Info>
                    <button onClick={getVpnList}>Reload</button> &nbsp;{vpnListLength}件&nbsp;
                    <ErrorMessage>
                        {errorMsg.split('\n').map((msg, index) => {
                            // eslint-disable-next-line react/jsx-key
                            return <p key={uuidV4()}>{msg}</p>
                        })}
                    </ErrorMessage>
                </Info>
                <GroupTable key={uuidV4()}>
                    <tbody>
                        <GroupTableHeaderTr>
                            <GroupTableHeader />
                            <GroupTableHeader>GID</GroupTableHeader>
                            <GroupTableHeader>BS</GroupTableHeader>
                            <GroupTableHeader>TB</GroupTableHeader>
                            <GroupTableHeader>AC</GroupTableHeader>
                            <GroupTableHeader>CA</GroupTableHeader>
                            <GroupTableHeader>SY</GroupTableHeader>
                            <GroupTableHeader />
                        </GroupTableHeaderTr>
                        {renderTableRow()}
                    </tbody>
                </GroupTable>
            </Contents>
        </Wrapper>
    )
}

const Contents = styled.div`
    padding: 0.5em;
    overflow: auto;
    flex-grow: 1;
`

const Info = styled.div`
    margin: 10px 0px 10px 0px;
    font-size: large;
`

const ErrorMessage = styled.div`
    color: red;
`

const WarnMessage = styled.div`
    color: red;
`

const GroupTable = styled.table`
    border-collapse: collapse;
    border: 1px solid lightgray;
    padding: 3px;
`

const GroupTableRow = styled.tr<{ selected: boolean }>`
    background: ${(props) => (props.selected ? listitemSelectedColor : 'white')};
`

const GroupTableHeaderTr = styled.tr``

const GroupTableHeader = styled.th`
    border: 1px solid lightgray;
    padding: 3px;
    color: grey;
`

const GroupTableCell = styled.td`
    border: 1px solid lightgray;
    padding: 10px;
`

const GroupTableCellNo = styled(GroupTableCell)``

const GroupTableCellGid = styled(GroupTableCell)<{ isChanged?: boolean }>`
    width: 8em;
    ${(props) => (props.isChanged ? 'background: pink;' : '')}
`
const GroupTableCell2 = styled(GroupTableCell)<{ isChanged?: boolean }>`
    width: 12em;
    ${(props) => (props.isChanged ? 'background: pink;' : '')}
`

const Wrapper = styled.div`
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
`

export default GidList
