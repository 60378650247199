import * as React from 'react'
import styled from 'styled-components'
import packageJson from '../../package.json'
import { API_SERVER_URL, headerBackgroundColor, headerFontColor, LOGOUT_URL } from '../common/Const'
import { AppContext } from './App'

/**
 * ヘッダー部
 */
const Header: React.FunctionComponent = () => {
    const { state } = React.useContext(AppContext)

    const logout = () => {
        window.location.href = LOGOUT_URL
    }

    const envName = (): string => {
        if (API_SERVER_URL.indexOf('.io') !== -1) {
            return '本番環境'
        }
        if (API_SERVER_URL.indexOf('.net') !== -1) {
            return 'Staging'
        }
        if (API_SERVER_URL.indexOf('.org') !== -1) {
            return 'Development'
        }
        return 'ローカル'
    }

    return (
        <Wrapper>
            <Left>
                <Title>ペアリングツール v{packageJson.version}</Title>
                <div>(更新先：{envName()})</div>
            </Left>
            <Right>
                <UserInfo>
                    {state.user?.username}: {state.user?.lastName}&nbsp;
                    {state.user?.firstName}
                </UserInfo>
                <button onClick={logout}>Logout</button>
            </Right>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    color: ${headerFontColor};
    padding: 0.5em 1em 0.5em 1em;
    background-color: ${headerBackgroundColor};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const Left = styled.div`
    display: flex;
    flex-direction: row;
`

const Title = styled.div`
    font-size: large;
`

const UserInfo = styled.div`
    padding-right: 40px;
`

const Right = styled.div`
    display: flex;
    flex-direction: row;
`

export default Header
