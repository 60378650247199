/* tslint:disable */
/* eslint-disable */
/**
 * Nileworks Drone API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosInstance, AxiosPromise } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BaseAPI, BASE_PATH, RequestArgs, RequiredError } from './base'
import { Configuration } from './configuration'

/**
 *
 * @export
 * @interface DroneAc
 */
export interface DroneAc {
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof DroneAc
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof DroneAc
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof DroneAc
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof DroneAc
     */
    created: string
}
/**
 *
 * @export
 * @interface DroneBs
 */
export interface DroneBs {
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof DroneBs
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof DroneBs
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof DroneBs
     */
    imsi: string
    /**
     * e.g. nwss2019-0001. T19で使用。T20以降は使用しない。
     * @type {string}
     * @memberof DroneBs
     */
    wifiSsid: string
    /**
     * ランダム生成された10桁 英小文字・数字. T19で使用。T20以降は使用しない。
     * @type {string}
     * @memberof DroneBs
     */
    wifiPassword?: string
    /**
     *
     * @type {string}
     * @memberof DroneBs
     */
    created: string
}
/**
 *
 * @export
 * @interface DroneCa
 */
export interface DroneCa {
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof DroneCa
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof DroneCa
     */
    created: string
}
/**
 *
 * @export
 * @interface DroneSy
 */
export interface DroneSy {
    /**
     * e.g. SY190001
     * @type {string}
     * @memberof DroneSy
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof DroneSy
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof DroneSy
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof DroneSy
     */
    created: string
}
/**
 *
 * @export
 * @interface DroneTb
 */
export interface DroneTb {
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof DroneTb
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof DroneTb
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof DroneTb
     */
    imsi: string
    /**
     * タブレットロック解除に使用する4桁の数字。         レコードCREATE時に自動生成。         2019-05 以降、使用しなくなった。互換性のために残している。
     * @type {string}
     * @memberof DroneTb
     */
    pinCode?: string
    /**
     *
     * @type {number}
     * @memberof DroneTb
     */
    kind?: number
    /**
     *
     * @type {string}
     * @memberof DroneTb
     */
    created: string
}
/**
 *
 * @export
 * @interface GenerateJWT
 */
export interface GenerateJWT {
    /**
     *
     * @type {string}
     * @memberof GenerateJWT
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof GenerateJWT
     */
    defaultRole: GenerateJWTDefaultRoleEnum
    /**
     *
     * @type {Array<string>}
     * @memberof GenerateJWT
     */
    allowedRoles: Array<GenerateJWTAllowedRolesEnum>
    /**
     *
     * @type {string}
     * @memberof GenerateJWT
     */
    token: string
}

/**
 * @export
 * @enum {string}
 */
export enum GenerateJWTDefaultRoleEnum {
    NileworksStaff = 'nileworks_staff',
    NileworksDeveloper = 'nileworks_developer',
    OrgAdmin = 'org_admin',
    OrgUser = 'org_user',
    AuthenticatedUser = 'authenticated_user',
    SharingStaff = 'sharing_staff',
    SharingUser = 'sharing_user',
}
/**
 * @export
 * @enum {string}
 */
export enum GenerateJWTAllowedRolesEnum {
    NileworksStaff = 'nileworks_staff',
    NileworksDeveloper = 'nileworks_developer',
    OrgAdmin = 'org_admin',
    OrgUser = 'org_user',
    AuthenticatedUser = 'authenticated_user',
    SharingStaff = 'sharing_staff',
    SharingUser = 'sharing_user',
}

/**
 *
 * @export
 * @interface GeometryPoint
 */
export interface GeometryPoint {
    /**
     *
     * @type {string}
     * @memberof GeometryPoint
     */
    type: string
    /**
     *
     * @type {Array<number>}
     * @memberof GeometryPoint
     */
    coordinates: Array<number>
}
/**
 * 測量結果(生データ)のGeometry表現。NileStationが生成。
 * @export
 * @interface GeometryPolygon
 */
export interface GeometryPolygon {
    /**
     *
     * @type {string}
     * @memberof GeometryPolygon
     */
    type: string
    /**
     *
     * @type {Array<Array<Array<number>>>}
     * @memberof GeometryPolygon
     */
    coordinates: Array<Array<Array<number>>>
}
/**
 *
 * @export
 * @interface Group
 */
export interface Group {
    /**
     *
     * @type {string}
     * @memberof Group
     */
    name: string
    /**
     *
     * @type {Array<Permission>}
     * @memberof Group
     */
    permissions: Array<Permission>
}
/**
 *
 * @export
 * @interface Http400BadRequest
 */
export interface Http400BadRequest {
    /**
     *
     * @type {string}
     * @memberof Http400BadRequest
     */
    detail?: string
    /**
     *
     * @type {object}
     * @memberof Http400BadRequest
     */
    errors?: object
}
/**
 *
 * @export
 * @interface Http401Unauthorized
 */
export interface Http401Unauthorized {
    /**
     *
     * @type {string}
     * @memberof Http401Unauthorized
     */
    detail?: string
}
/**
 *
 * @export
 * @interface Http403Forbidden
 */
export interface Http403Forbidden {
    /**
     *
     * @type {string}
     * @memberof Http403Forbidden
     */
    detail?: string
}
/**
 *
 * @export
 * @interface Http404NotFound
 */
export interface Http404NotFound {
    /**
     *
     * @type {string}
     * @memberof Http404NotFound
     */
    detail?: string
}
/**
 *
 * @export
 * @interface Http409Conflict
 */
export interface Http409Conflict {
    /**
     *
     * @type {string}
     * @memberof Http409Conflict
     */
    detail?: string
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse200
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse200
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse200
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigNumberingBsList>}
     * @memberof InlineResponse200
     */
    results: Array<JigNumberingBsList>
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse2001
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse2001
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse2001
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigVpn>}
     * @memberof InlineResponse2001
     */
    results: Array<JigVpn>
}
/**
 *
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20010
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20010
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20010
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigApiV2NumberingTbCommonResponse>}
     * @memberof InlineResponse20010
     */
    results: Array<JigApiV2NumberingTbCommonResponse>
}
/**
 *
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20011
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20011
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20011
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigApiV2Vpn>}
     * @memberof InlineResponse20011
     */
    results: Array<JigApiV2Vpn>
}
/**
 *
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20012
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20012
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20012
     */
    previous?: string | null
    /**
     *
     * @type {Array<MachineApiV2ConnectionAcCommonResponse>}
     * @memberof InlineResponse20012
     */
    results: Array<MachineApiV2ConnectionAcCommonResponse>
}
/**
 *
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20013
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20013
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20013
     */
    previous?: string | null
    /**
     *
     * @type {Array<MachineApiV2ConnectionBsCommonResponse>}
     * @memberof InlineResponse20013
     */
    results: Array<MachineApiV2ConnectionBsCommonResponse>
}
/**
 *
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20014
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20014
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20014
     */
    previous?: string | null
    /**
     *
     * @type {Array<MachineApiV2ConnectionCaCommonResponse>}
     * @memberof InlineResponse20014
     */
    results: Array<MachineApiV2ConnectionCaCommonResponse>
}
/**
 *
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20015
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20015
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20015
     */
    previous?: string | null
    /**
     *
     * @type {Array<MachineApiV2ConnectionSyCommonResponse>}
     * @memberof InlineResponse20015
     */
    results: Array<MachineApiV2ConnectionSyCommonResponse>
}
/**
 *
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20016
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20016
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20016
     */
    previous?: string | null
    /**
     *
     * @type {Array<MachineApiV2ConnectionTbCommonResponse>}
     * @memberof InlineResponse20016
     */
    results: Array<MachineApiV2ConnectionTbCommonResponse>
}
/**
 *
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20017
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20017
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20017
     */
    previous?: string | null
    /**
     *
     * @type {Array<MobileApiV2AppNsAndroidKioskApkList>}
     * @memberof InlineResponse20017
     */
    results: Array<MobileApiV2AppNsAndroidKioskApkList>
}
/**
 *
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20018
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20018
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20018
     */
    previous?: string | null
    /**
     *
     * @type {Array<MobileApiV2OperationFlightList>}
     * @memberof InlineResponse20018
     */
    results: Array<MobileApiV2OperationFlightList>
}
/**
 *
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20019
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20019
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20019
     */
    previous?: string | null
    /**
     *
     * @type {Array<MobileApiV2OperationMinistryReportList>}
     * @memberof InlineResponse20019
     */
    results: Array<MobileApiV2OperationMinistryReportList>
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse2002
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002
     */
    previous?: string | null
    /**
     *
     * @type {Array<MobileAgriGrowthsList>}
     * @memberof InlineResponse2002
     */
    results: Array<MobileAgriGrowthsList>
}
/**
 *
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20020
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20020
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20020
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigApiV3Pairing>}
     * @memberof InlineResponse20020
     */
    results: Array<JigApiV3Pairing>
}
/**
 *
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse20021
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse20021
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse20021
     */
    previous?: string | null
    /**
     *
     * @type {Array<MobileApiV3TeamList>}
     * @memberof InlineResponse20021
     */
    results: Array<MobileApiV3TeamList>
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse2003
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse2003
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse2003
     */
    previous?: string | null
    /**
     *
     * @type {Array<MobileAppNsAndroidKioskApkList>}
     * @memberof InlineResponse2003
     */
    results: Array<MobileAppNsAndroidKioskApkList>
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse2004
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse2004
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse2004
     */
    previous?: string | null
    /**
     *
     * @type {Array<MobileOperationFlightTbList>}
     * @memberof InlineResponse2004
     */
    results: Array<MobileOperationFlightTbList>
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse2005
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse2005
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse2005
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigApiV2FarmOrganizationList>}
     * @memberof InlineResponse2005
     */
    results: Array<JigApiV2FarmOrganizationList>
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse2006
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse2006
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse2006
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigApiV2NumberingAcCommonResponse>}
     * @memberof InlineResponse2006
     */
    results: Array<JigApiV2NumberingAcCommonResponse>
}
/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse2007
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse2007
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse2007
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigApiV2NumberingBsCommonResponse>}
     * @memberof InlineResponse2007
     */
    results: Array<JigApiV2NumberingBsCommonResponse>
}
/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse2008
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse2008
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse2008
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigApiV2NumberingCaCommonResponse>}
     * @memberof InlineResponse2008
     */
    results: Array<JigApiV2NumberingCaCommonResponse>
}
/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     *
     * @type {number}
     * @memberof InlineResponse2009
     */
    count: number
    /**
     *
     * @type {string}
     * @memberof InlineResponse2009
     */
    next?: string | null
    /**
     *
     * @type {string}
     * @memberof InlineResponse2009
     */
    previous?: string | null
    /**
     *
     * @type {Array<JigApiV2NumberingSyCommonResponse>}
     * @memberof InlineResponse2009
     */
    results: Array<JigApiV2NumberingSyCommonResponse>
}
/**
 *
 * @export
 * @interface JigActivationCreate
 */
export interface JigActivationCreate {
    /**
     *
     * @type {JigActivationCreateBs}
     * @memberof JigActivationCreate
     */
    bs: JigActivationCreateBs
    /**
     *
     * @type {JigActivationCreateAc}
     * @memberof JigActivationCreate
     */
    ac?: JigActivationCreateAc
    /**
     *
     * @type {JigActivationCreateCa}
     * @memberof JigActivationCreate
     */
    ca?: JigActivationCreateCa
    /**
     *
     * @type {JigActivationCreateSy}
     * @memberof JigActivationCreate
     */
    sy?: JigActivationCreateSy
    /**
     *
     * @type {JigActivationCreateTb}
     * @memberof JigActivationCreate
     */
    tb?: JigActivationCreateTb
}
/**
 *
 * @export
 * @interface JigActivationCreateAc
 */
export interface JigActivationCreateAc {
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof JigActivationCreateAc
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigActivationCreateBs
 */
export interface JigActivationCreateBs {
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof JigActivationCreateBs
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigActivationCreateCa
 */
export interface JigActivationCreateCa {
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof JigActivationCreateCa
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigActivationCreateResponse
 */
export interface JigActivationCreateResponse {
    /**
     *
     * @type {JigActivationCreateResponseVpnHub}
     * @memberof JigActivationCreateResponse
     */
    vpnHub?: JigActivationCreateResponseVpnHub
}
/**
 *
 * @export
 * @interface JigActivationCreateResponseVpnHub
 */
export interface JigActivationCreateResponseVpnHub {
    /**
     *
     * @type {string}
     * @memberof JigActivationCreateResponseVpnHub
     */
    name: string
}
/**
 *
 * @export
 * @interface JigActivationCreateSy
 */
export interface JigActivationCreateSy {
    /**
     * e.g. SY190001
     * @type {string}
     * @memberof JigActivationCreateSy
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigActivationCreateTb
 */
export interface JigActivationCreateTb {
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof JigActivationCreateTb
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2ActivationCreate
 */
export interface JigApiV2ActivationCreate {
    /**
     *
     * @type {JigApiV2ActivationCreateBs}
     * @memberof JigApiV2ActivationCreate
     */
    bs: JigApiV2ActivationCreateBs
    /**
     *
     * @type {JigApiV2ActivationCreateAc}
     * @memberof JigApiV2ActivationCreate
     */
    ac?: JigApiV2ActivationCreateAc
    /**
     *
     * @type {JigApiV2ActivationCreateCa}
     * @memberof JigApiV2ActivationCreate
     */
    ca?: JigApiV2ActivationCreateCa
    /**
     *
     * @type {JigApiV2ActivationCreateSy}
     * @memberof JigApiV2ActivationCreate
     */
    sy?: JigApiV2ActivationCreateSy
    /**
     *
     * @type {JigApiV2ActivationCreateTb}
     * @memberof JigApiV2ActivationCreate
     */
    tb?: JigApiV2ActivationCreateTb
}
/**
 *
 * @export
 * @interface JigApiV2ActivationCreateAc
 */
export interface JigApiV2ActivationCreateAc {
    /**
     * e.g. AC200001
     * @type {string}
     * @memberof JigApiV2ActivationCreateAc
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2ActivationCreateBs
 */
export interface JigApiV2ActivationCreateBs {
    /**
     * e.g. BS200001
     * @type {string}
     * @memberof JigApiV2ActivationCreateBs
     */
    serialNumber: string
    /**
     *
     * @type {JigApiV2ActivationCreateBsOrganization}
     * @memberof JigApiV2ActivationCreateBs
     */
    organization?: JigApiV2ActivationCreateBsOrganization
}
/**
 * 出荷先を変更しない場合、未指定。
 * @export
 * @interface JigApiV2ActivationCreateBsOrganization
 */
export interface JigApiV2ActivationCreateBsOrganization {
    /**
     *
     * @type {string}
     * @memberof JigApiV2ActivationCreateBsOrganization
     */
    id: string
}
/**
 *
 * @export
 * @interface JigApiV2ActivationCreateCa
 */
export interface JigApiV2ActivationCreateCa {
    /**
     * e.g. CA200001
     * @type {string}
     * @memberof JigApiV2ActivationCreateCa
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2ActivationCreateResponse
 */
export interface JigApiV2ActivationCreateResponse {
    /**
     *
     * @type {JigApiV2ActivationCreateResponseVpnHub}
     * @memberof JigApiV2ActivationCreateResponse
     */
    vpnHub: JigApiV2ActivationCreateResponseVpnHub
}
/**
 *
 * @export
 * @interface JigApiV2ActivationCreateResponseVpnHub
 */
export interface JigApiV2ActivationCreateResponseVpnHub {
    /**
     *
     * @type {string}
     * @memberof JigApiV2ActivationCreateResponseVpnHub
     */
    name: string
}
/**
 *
 * @export
 * @interface JigApiV2ActivationCreateSy
 */
export interface JigApiV2ActivationCreateSy {
    /**
     * e.g. SY200001
     * @type {string}
     * @memberof JigApiV2ActivationCreateSy
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2ActivationCreateTb
 */
export interface JigApiV2ActivationCreateTb {
    /**
     * e.g. TB200001
     * @type {string}
     * @memberof JigApiV2ActivationCreateTb
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2DeactivationCreate
 */
export interface JigApiV2DeactivationCreate {
    /**
     *
     * @type {JigApiV2DeactivationCreateBs}
     * @memberof JigApiV2DeactivationCreate
     */
    bs: JigApiV2DeactivationCreateBs
    /**
     *
     * @type {JigApiV2DeactivationCreateAc}
     * @memberof JigApiV2DeactivationCreate
     */
    ac?: JigApiV2DeactivationCreateAc
    /**
     *
     * @type {JigApiV2DeactivationCreateCa}
     * @memberof JigApiV2DeactivationCreate
     */
    ca?: JigApiV2DeactivationCreateCa
    /**
     *
     * @type {JigApiV2DeactivationCreateSy}
     * @memberof JigApiV2DeactivationCreate
     */
    sy?: JigApiV2DeactivationCreateSy
    /**
     *
     * @type {JigApiV2DeactivationCreateTb}
     * @memberof JigApiV2DeactivationCreate
     */
    tb?: JigApiV2DeactivationCreateTb
}
/**
 *
 * @export
 * @interface JigApiV2DeactivationCreateAc
 */
export interface JigApiV2DeactivationCreateAc {
    /**
     * e.g. AC200001
     * @type {string}
     * @memberof JigApiV2DeactivationCreateAc
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2DeactivationCreateBs
 */
export interface JigApiV2DeactivationCreateBs {
    /**
     * e.g. BS200001
     * @type {string}
     * @memberof JigApiV2DeactivationCreateBs
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2DeactivationCreateCa
 */
export interface JigApiV2DeactivationCreateCa {
    /**
     * e.g. CA200001
     * @type {string}
     * @memberof JigApiV2DeactivationCreateCa
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2DeactivationCreateSy
 */
export interface JigApiV2DeactivationCreateSy {
    /**
     * e.g. SY200001
     * @type {string}
     * @memberof JigApiV2DeactivationCreateSy
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2DeactivationCreateTb
 */
export interface JigApiV2DeactivationCreateTb {
    /**
     * e.g. TB200001
     * @type {string}
     * @memberof JigApiV2DeactivationCreateTb
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV2FarmOrganizationList
 */
export interface JigApiV2FarmOrganizationList {
    /**
     *
     * @type {string}
     * @memberof JigApiV2FarmOrganizationList
     */
    id?: string
    /**
     *
     * @type {JigApiV2FarmOrganizationListParent}
     * @memberof JigApiV2FarmOrganizationList
     */
    parent?: JigApiV2FarmOrganizationListParent
    /**
     *
     * @type {string}
     * @memberof JigApiV2FarmOrganizationList
     */
    code?: JigApiV2FarmOrganizationListCodeEnum
    /**
     *
     * @type {string}
     * @memberof JigApiV2FarmOrganizationList
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2FarmOrganizationList
     */
    created?: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigApiV2FarmOrganizationListCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface JigApiV2FarmOrganizationListParent
 */
export interface JigApiV2FarmOrganizationListParent {
    /**
     *
     * @type {string}
     * @memberof JigApiV2FarmOrganizationListParent
     */
    id?: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingAcCommonResponse
 */
export interface JigApiV2NumberingAcCommonResponse {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingAcCommonResponseDroneAc}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    droneAc?: JigApiV2NumberingAcCommonResponseDroneAc
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    imei: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponse
     */
    url?: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingAcCommonResponseDroneAc
 */
export interface JigApiV2NumberingAcCommonResponseDroneAc {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingAcCommonResponseDroneAcModelNumber}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    droneAcModelNumber: JigApiV2NumberingAcCommonResponseDroneAcModelNumber
    /**
     *
     * @type {JigApiV2NumberingCommonDroneBs}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    droneBs?: JigApiV2NumberingCommonDroneBs
    /**
     *
     * @type {JigApiV2NumberingCommonDroneTb}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    droneTb?: JigApiV2NumberingCommonDroneTb
    /**
     *
     * @type {JigApiV2NumberingCommonVpnUser}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    vpnUser?: JigApiV2NumberingCommonVpnUser
    /**
     *
     * @type {JigApiV2NumberingCommonSimPairing}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    simPairing: JigApiV2NumberingCommonSimPairing
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    activated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    deactivated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    discarded?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAc
     */
    version: number
}
/**
 *
 * @export
 * @interface JigApiV2NumberingAcCommonResponseDroneAcModelNumber
 */
export interface JigApiV2NumberingAcCommonResponseDroneAcModelNumber {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAcModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAcModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingAcCommonResponseDroneAcModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingAcCreateRequest
 */
export interface JigApiV2NumberingAcCreateRequest {
    /**
     * 基地局で使用するSIMの電話番号. e.g. 07012345678
     * @type {string}
     * @memberof JigApiV2NumberingAcCreateRequest
     */
    tel: string
    /**
     * 基地局のLTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingAcCreateRequest
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingAcCreateRequest
     */
    imsi: string
    /**
     *
     * @type {JigApiV2NumberingAcCreateRequestDroneAcModelNumber}
     * @memberof JigApiV2NumberingAcCreateRequest
     */
    droneAcModelNumber: JigApiV2NumberingAcCreateRequestDroneAcModelNumber
}
/**
 *
 * @export
 * @interface JigApiV2NumberingAcCreateRequestDroneAcModelNumber
 */
export interface JigApiV2NumberingAcCreateRequestDroneAcModelNumber {
    /**
     * T20の場合、 4f5e571d-df8b-466e-86ba-83d6662edb8a を指定。
     * @type {string}
     * @memberof JigApiV2NumberingAcCreateRequestDroneAcModelNumber
     */
    id: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingBsCommonResponse
 */
export interface JigApiV2NumberingBsCommonResponse {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingBsCommonResponseDroneBs}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    droneBs?: JigApiV2NumberingBsCommonResponseDroneBs
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    imei: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponse
     */
    url?: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingBsCommonResponseDroneBs
 */
export interface JigApiV2NumberingBsCommonResponseDroneBs {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingBsCommonResponseDroneBsModelNumber}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    droneBsModelNumber: JigApiV2NumberingBsCommonResponseDroneBsModelNumber
    /**
     *
     * @type {JigApiV2NumberingBsCommonResponseOrganization}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    organization: JigApiV2NumberingBsCommonResponseOrganization
    /**
     *
     * @type {JigApiV2NumberingCommonVpnUser}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    vpnUser?: JigApiV2NumberingCommonVpnUser
    /**
     *
     * @type {JigApiV2NumberingCommonSimPairing}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    simPairing: JigApiV2NumberingCommonSimPairing
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    activated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    deactivated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    discarded?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBs
     */
    version: number
}
/**
 *
 * @export
 * @interface JigApiV2NumberingBsCommonResponseDroneBsModelNumber
 */
export interface JigApiV2NumberingBsCommonResponseDroneBsModelNumber {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBsModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBsModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseDroneBsModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingBsCommonResponseOrganization
 */
export interface JigApiV2NumberingBsCommonResponseOrganization {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseOrganization
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseOrganization
     */
    code?: JigApiV2NumberingBsCommonResponseOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseOrganization
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingBsCommonResponseOrganization
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigApiV2NumberingBsCommonResponseOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface JigApiV2NumberingBsCreateRequest
 */
export interface JigApiV2NumberingBsCreateRequest {
    /**
     * 基地局で使用するSIMの電話番号. e.g. 07012345678
     * @type {string}
     * @memberof JigApiV2NumberingBsCreateRequest
     */
    tel: string
    /**
     * 基地局のLTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingBsCreateRequest
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingBsCreateRequest
     */
    imsi: string
    /**
     *
     * @type {JigApiV2NumberingBsCreateRequestDroneAcModelNumber}
     * @memberof JigApiV2NumberingBsCreateRequest
     */
    droneBsModelNumber: JigApiV2NumberingBsCreateRequestDroneAcModelNumber
}
/**
 *
 * @export
 * @interface JigApiV2NumberingBsCreateRequestDroneAcModelNumber
 */
export interface JigApiV2NumberingBsCreateRequestDroneAcModelNumber {
    /**
     * T20の場合、 193fa57e-458c-4f73-b5a2-0ba27941c567 を指定。
     * @type {string}
     * @memberof JigApiV2NumberingBsCreateRequestDroneAcModelNumber
     */
    id: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCaCommonResponse
 */
export interface JigApiV2NumberingCaCommonResponse {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingCaCommonResponseDroneCa}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    droneCa?: JigApiV2NumberingCaCommonResponseDroneCa
    /**
     * カメラの識別子。T20から追加。カメラ毎にユニークだが、T19カメラコード未登録のため、unique=Falseにしている。
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    code: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponse
     */
    url?: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCaCommonResponseDroneCa
 */
export interface JigApiV2NumberingCaCommonResponseDroneCa {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingCaCommonResponseDroneCaModelNumber}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    droneCaModelNumber: JigApiV2NumberingCaCommonResponseDroneCaModelNumber
    /**
     *
     * @type {JigApiV2NumberingCommonDroneAc}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    droneAc?: JigApiV2NumberingCommonDroneAc
    /**
     *
     * @type {JigApiV2NumberingCommonVpnUser}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    vpnUser?: JigApiV2NumberingCommonVpnUser
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    serialNumber: string
    /**
     * カメラの識別子。T20から追加。カメラ毎にユニークだが、T19カメラコード未取得のため、unique=Falseにしている。
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    code?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    activated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    deactivated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    discarded?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCa
     */
    version: number
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCaCommonResponseDroneCaModelNumber
 */
export interface JigApiV2NumberingCaCommonResponseDroneCaModelNumber {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCaModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCaModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCaCommonResponseDroneCaModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCaCreateRequest
 */
export interface JigApiV2NumberingCaCreateRequest {
    /**
     * カメラの識別子。
     * @type {string}
     * @memberof JigApiV2NumberingCaCreateRequest
     */
    code: string
    /**
     *
     * @type {JigApiV2NumberingCaCreateRequestDroneCaModelNumber}
     * @memberof JigApiV2NumberingCaCreateRequest
     */
    droneCaModelNumber: JigApiV2NumberingCaCreateRequestDroneCaModelNumber
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCaCreateRequestDroneCaModelNumber
 */
export interface JigApiV2NumberingCaCreateRequestDroneCaModelNumber {
    /**
     * T20の場合、 93a6b6f7-627c-4842-ac72-5c440b08a478 を指定。
     * @type {string}
     * @memberof JigApiV2NumberingCaCreateRequestDroneCaModelNumber
     */
    id: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCommonDroneAc
 */
export interface JigApiV2NumberingCommonDroneAc {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneAc
     */
    id: string
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneAc
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneAc
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneAc
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingCommonDroneAc
     */
    version: number
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCommonDroneBs
 */
export interface JigApiV2NumberingCommonDroneBs {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneBs
     */
    id: string
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneBs
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneBs
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneBs
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingCommonDroneBs
     */
    version: number
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCommonDroneTb
 */
export interface JigApiV2NumberingCommonDroneTb {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneTb
     */
    id: string
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneTb
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneTb
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonDroneTb
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingCommonDroneTb
     */
    version: number
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCommonFirmwareUpdateServer
 */
export interface JigApiV2NumberingCommonFirmwareUpdateServer {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonFirmwareUpdateServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCommonMavLinkServer
 */
export interface JigApiV2NumberingCommonMavLinkServer {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonMavLinkServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCommonRsyncServer
 */
export interface JigApiV2NumberingCommonRsyncServer {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonRsyncServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCommonSimPairing
 */
export interface JigApiV2NumberingCommonSimPairing {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonSimPairing
     */
    id: string
    /**
     * e.g. 09012345678
     * @type {string}
     * @memberof JigApiV2NumberingCommonSimPairing
     */
    tel: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonSimPairing
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCommonVpnHub
 */
export interface JigApiV2NumberingCommonVpnHub {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingCommonVpnServer}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    vpnServer: JigApiV2NumberingCommonVpnServer
    /**
     *
     * @type {JigApiV2NumberingCommonRsyncServer}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    rsyncServer: JigApiV2NumberingCommonRsyncServer
    /**
     *
     * @type {JigApiV2NumberingCommonMavLinkServer}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    mavlinkServer: JigApiV2NumberingCommonMavLinkServer
    /**
     *
     * @type {JigApiV2NumberingCommonFirmwareUpdateServer}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    firmwareUpdateServer: JigApiV2NumberingCommonFirmwareUpdateServer
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    kind?: JigApiV2NumberingCommonVpnHubKindEnum
    /**
     * e.g. G00001
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    name: string
    /**
     * 仮想インターフェイスに割り当てるサブネットマスク.e.g. 255.255.255.224
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    netmask: string
    /**
     * 仮想インターフェイスの持つ IP アドレス. e.g. 10.0.1.94
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    routerIpAddress: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnHub
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigApiV2NumberingCommonVpnHubKindEnum {
    G = 'G',
    G20 = 'G20',
    Setup = 'SETUP',
    Ftest = 'FTEST',
    Ptest = 'PTEST',
    Factory = 'FACTORY',
    Devel = 'DEVEL',
    Server = 'SERVER',
}

/**
 *
 * @export
 * @interface JigApiV2NumberingCommonVpnServer
 */
export interface JigApiV2NumberingCommonVpnServer {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnServer
     */
    id: string
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnServer
     */
    hostname?: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingCommonVpnServer
     */
    portNumber?: number
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnServer
     */
    openVpnConfigTemplate?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnServer
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingCommonVpnUser
 */
export interface JigApiV2NumberingCommonVpnUser {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnUser
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingCommonVpnHub}
     * @memberof JigApiV2NumberingCommonVpnUser
     */
    vpnHub: JigApiV2NumberingCommonVpnHub
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnUser
     */
    password: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnUser
     */
    kind?: JigApiV2NumberingCommonVpnUserKindEnum
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingCommonVpnUser
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigApiV2NumberingCommonVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface JigApiV2NumberingSyCommonResponse
 */
export interface JigApiV2NumberingSyCommonResponse {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingSyCommonResponseDroneSy}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    droneSy?: JigApiV2NumberingSyCommonResponseDroneSy
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    imei: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponse
     */
    url?: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingSyCommonResponseDroneSy
 */
export interface JigApiV2NumberingSyCommonResponseDroneSy {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingSyCommonResponseDroneSyModelNumber}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    droneSyModelNumber: JigApiV2NumberingSyCommonResponseDroneSyModelNumber
    /**
     *
     * @type {JigApiV2NumberingCommonDroneBs}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    droneBs?: JigApiV2NumberingCommonDroneBs
    /**
     *
     * @type {JigApiV2NumberingCommonDroneTb}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    droneTb?: JigApiV2NumberingCommonDroneTb
    /**
     *
     * @type {JigApiV2NumberingCommonVpnUser}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    vpnUser?: JigApiV2NumberingCommonVpnUser
    /**
     *
     * @type {JigApiV2NumberingCommonSimPairing}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    simPairing: JigApiV2NumberingCommonSimPairing
    /**
     * e.g. SY190001
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    activated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    deactivated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    discarded?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSy
     */
    version: number
}
/**
 *
 * @export
 * @interface JigApiV2NumberingSyCommonResponseDroneSyModelNumber
 */
export interface JigApiV2NumberingSyCommonResponseDroneSyModelNumber {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSyModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSyModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingSyCommonResponseDroneSyModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingSyCreateRequest
 */
export interface JigApiV2NumberingSyCreateRequest {
    /**
     * 基地局で使用するSIMの電話番号. e.g. 07012345678
     * @type {string}
     * @memberof JigApiV2NumberingSyCreateRequest
     */
    tel: string
    /**
     * 基地局のLTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingSyCreateRequest
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingSyCreateRequest
     */
    imsi: string
    /**
     *
     * @type {JigApiV2NumberingSyCreateRequestDroneSyModelNumber}
     * @memberof JigApiV2NumberingSyCreateRequest
     */
    droneSyModelNumber: JigApiV2NumberingSyCreateRequestDroneSyModelNumber
}
/**
 *
 * @export
 * @interface JigApiV2NumberingSyCreateRequestDroneSyModelNumber
 */
export interface JigApiV2NumberingSyCreateRequestDroneSyModelNumber {
    /**
     * T20の場合、 3a9d52c6-8ad5-4a22-b5d4-0a5c5066c839 を指定。
     * @type {string}
     * @memberof JigApiV2NumberingSyCreateRequestDroneSyModelNumber
     */
    id: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingTbCommonResponse
 */
export interface JigApiV2NumberingTbCommonResponse {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingTbCommonResponseDroneTb}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    droneTb?: JigApiV2NumberingTbCommonResponseDroneTb
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    imei: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponse
     */
    url?: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingTbCommonResponseDroneTb
 */
export interface JigApiV2NumberingTbCommonResponseDroneTb {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    id: string
    /**
     *
     * @type {JigApiV2NumberingTbCommonResponseDroneTbModelNumber}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    droneTbModelNumber: JigApiV2NumberingTbCommonResponseDroneTbModelNumber
    /**
     *
     * @type {JigApiV2NumberingCommonDroneBs}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    droneBs?: JigApiV2NumberingCommonDroneBs
    /**
     *
     * @type {JigApiV2NumberingCommonVpnUser}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    vpnUser?: JigApiV2NumberingCommonVpnUser
    /**
     *
     * @type {JigApiV2NumberingCommonSimPairing}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    simPairing: JigApiV2NumberingCommonSimPairing
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    imsi: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    kind?: number
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    activated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    deactivated?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    discarded?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTb
     */
    version: number
}
/**
 *
 * @export
 * @interface JigApiV2NumberingTbCommonResponseDroneTbModelNumber
 */
export interface JigApiV2NumberingTbCommonResponseDroneTbModelNumber {
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTbModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTbModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2NumberingTbCommonResponseDroneTbModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2NumberingTbCreateRequest
 */
export interface JigApiV2NumberingTbCreateRequest {
    /**
     * 基地局で使用するSIMの電話番号. e.g. 07012345678
     * @type {string}
     * @memberof JigApiV2NumberingTbCreateRequest
     */
    tel: string
    /**
     * 基地局のLTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingTbCreateRequest
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2NumberingTbCreateRequest
     */
    imsi: string
    /**
     * 1: 飛行用, 2: 販売店・社内配布用
     * @type {number}
     * @memberof JigApiV2NumberingTbCreateRequest
     */
    kind?: number
    /**
     *
     * @type {JigApiV2NumberingTbCreateRequestDroneTbModelNumber}
     * @memberof JigApiV2NumberingTbCreateRequest
     */
    droneTbModelNumber: JigApiV2NumberingTbCreateRequestDroneTbModelNumber
}
/**
 *
 * @export
 * @interface JigApiV2NumberingTbCreateRequestDroneTbModelNumber
 */
export interface JigApiV2NumberingTbCreateRequestDroneTbModelNumber {
    /**
     * T20の場合、 b6321089-b0a1-4f76-ac72-b68284e188d4 を指定。
     * @type {string}
     * @memberof JigApiV2NumberingTbCreateRequestDroneTbModelNumber
     */
    id: string
}
/**
 *
 * @export
 * @interface JigApiV2Vpn
 */
export interface JigApiV2Vpn {
    /**
     *
     * @type {string}
     * @memberof JigApiV2Vpn
     */
    url?: string
    /**
     *
     * @type {JigApiV2VpnVpnServer}
     * @memberof JigApiV2Vpn
     */
    vpnServer: JigApiV2VpnVpnServer
    /**
     *
     * @type {JigApiV2VpnRsyncServer}
     * @memberof JigApiV2Vpn
     */
    rsyncServer: JigApiV2VpnRsyncServer
    /**
     *
     * @type {JigApiV2VpnMavLinkServer}
     * @memberof JigApiV2Vpn
     */
    mavlinkServer: JigApiV2VpnMavLinkServer
    /**
     *
     * @type {JigApiV2VpnFirmwareUpdateServer}
     * @memberof JigApiV2Vpn
     */
    firmwareUpdateServer: JigApiV2VpnFirmwareUpdateServer
    /**
     *
     * @type {string}
     * @memberof JigApiV2Vpn
     */
    kind?: JigApiV2VpnKindEnum
    /**
     * e.g. G00001
     * @type {string}
     * @memberof JigApiV2Vpn
     */
    name: string
    /**
     * 仮想インターフェイスに割り当てるサブネットマスク.e.g. 255.255.255.224
     * @type {string}
     * @memberof JigApiV2Vpn
     */
    netmask: string
    /**
     * 仮想インターフェイスの持つ IP アドレス. e.g. 10.0.1.94
     * @type {string}
     * @memberof JigApiV2Vpn
     */
    routerIpAddress: string
    /**
     *
     * @type {Array<JigApiV2VpnVpnUser>}
     * @memberof JigApiV2Vpn
     */
    vpnUsers: Array<JigApiV2VpnVpnUser>
    /**
     *
     * @type {string}
     * @memberof JigApiV2Vpn
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigApiV2VpnKindEnum {
    G = 'G',
    G20 = 'G20',
    Setup = 'SETUP',
    Ftest = 'FTEST',
    Ptest = 'PTEST',
    Factory = 'FACTORY',
    Devel = 'DEVEL',
    Server = 'SERVER',
}

/**
 *
 * @export
 * @interface JigApiV2VpnFirmwareUpdateServer
 */
export interface JigApiV2VpnFirmwareUpdateServer {
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnFirmwareUpdateServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface JigApiV2VpnMavLinkServer
 */
export interface JigApiV2VpnMavLinkServer {
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnMavLinkServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface JigApiV2VpnRsyncServer
 */
export interface JigApiV2VpnRsyncServer {
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnRsyncServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface JigApiV2VpnVpnServer
 */
export interface JigApiV2VpnVpnServer {
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof JigApiV2VpnVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof JigApiV2VpnVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof JigApiV2VpnVpnServer
     */
    hostname?: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2VpnVpnServer
     */
    portNumber?: number
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnServer
     */
    openVpnConfigTemplate?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnServer
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2VpnVpnUser
 */
export interface JigApiV2VpnVpnUser {
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnUser
     */
    password: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof JigApiV2VpnVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnUser
     */
    kind?: JigApiV2VpnVpnUserKindEnum
    /**
     *
     * @type {Array<JigApiV2VpnVpnUserDroneBs>}
     * @memberof JigApiV2VpnVpnUser
     */
    dronebsSet: Array<JigApiV2VpnVpnUserDroneBs>
    /**
     *
     * @type {Array<JigApiV2VpnVpnUserDroneAc>}
     * @memberof JigApiV2VpnVpnUser
     */
    droneacSet: Array<JigApiV2VpnVpnUserDroneAc>
    /**
     *
     * @type {Array<JigApiV2VpnVpnUserDroneCa>}
     * @memberof JigApiV2VpnVpnUser
     */
    dronecaSet: Array<JigApiV2VpnVpnUserDroneCa>
    /**
     *
     * @type {Array<JigApiV2VpnVpnUserDroneSy>}
     * @memberof JigApiV2VpnVpnUser
     */
    dronesySet: Array<JigApiV2VpnVpnUserDroneSy>
    /**
     *
     * @type {Array<JigApiV2VpnVpnUserDroneTb>}
     * @memberof JigApiV2VpnVpnUser
     */
    dronetbSet: Array<JigApiV2VpnVpnUserDroneTb>
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnUser
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigApiV2VpnVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface JigApiV2VpnVpnUserDroneAc
 */
export interface JigApiV2VpnVpnUserDroneAc {
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneAc
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneAc
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneAc
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneAc
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2VpnVpnUserDroneBs
 */
export interface JigApiV2VpnVpnUserDroneBs {
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneBs
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneBs
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneBs
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneBs
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2VpnVpnUserDroneCa
 */
export interface JigApiV2VpnVpnUserDroneCa {
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneCa
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneCa
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2VpnVpnUserDroneSy
 */
export interface JigApiV2VpnVpnUserDroneSy {
    /**
     * e.g. SY190001
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneSy
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneSy
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneSy
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneSy
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV2VpnVpnUserDroneTb
 */
export interface JigApiV2VpnVpnUserDroneTb {
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneTb
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneTb
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneTb
     */
    imsi: string
    /**
     *
     * @type {number}
     * @memberof JigApiV2VpnVpnUserDroneTb
     */
    kind?: number
    /**
     *
     * @type {string}
     * @memberof JigApiV2VpnVpnUserDroneTb
     */
    created: string
}
/**
 *
 * @export
 * @interface JigApiV3ActivationCreate
 */
export interface JigApiV3ActivationCreate {
    /**
     * e.g. G00201
     * @type {string}
     * @memberof JigApiV3ActivationCreate
     */
    gid: string
    /**
     *
     * @type {JigApiV3ActivationCreateBs}
     * @memberof JigApiV3ActivationCreate
     */
    bs: JigApiV3ActivationCreateBs
    /**
     *
     * @type {JigApiV3ActivationCreateAc}
     * @memberof JigApiV3ActivationCreate
     */
    ac?: JigApiV3ActivationCreateAc
    /**
     *
     * @type {JigApiV3ActivationCreateCa}
     * @memberof JigApiV3ActivationCreate
     */
    ca?: JigApiV3ActivationCreateCa
    /**
     *
     * @type {JigApiV3ActivationCreateSy}
     * @memberof JigApiV3ActivationCreate
     */
    sy?: JigApiV3ActivationCreateSy
    /**
     *
     * @type {JigApiV3ActivationCreateTb}
     * @memberof JigApiV3ActivationCreate
     */
    tb?: JigApiV3ActivationCreateTb
}
/**
 *
 * @export
 * @interface JigApiV3ActivationCreateAc
 */
export interface JigApiV3ActivationCreateAc {
    /**
     * e.g. AC200001
     * @type {string}
     * @memberof JigApiV3ActivationCreateAc
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV3ActivationCreateBs
 */
export interface JigApiV3ActivationCreateBs {
    /**
     * e.g. BS200001
     * @type {string}
     * @memberof JigApiV3ActivationCreateBs
     */
    serialNumber: string
    /**
     *
     * @type {JigApiV3ActivationCreateBsOrganization}
     * @memberof JigApiV3ActivationCreateBs
     */
    organization?: JigApiV3ActivationCreateBsOrganization
}
/**
 * 出荷先を変更しなhい場合、未指定。
 * @export
 * @interface JigApiV3ActivationCreateBsOrganization
 */
export interface JigApiV3ActivationCreateBsOrganization {
    /**
     *
     * @type {string}
     * @memberof JigApiV3ActivationCreateBsOrganization
     */
    id: string
}
/**
 *
 * @export
 * @interface JigApiV3ActivationCreateCa
 */
export interface JigApiV3ActivationCreateCa {
    /**
     * e.g. CA200001
     * @type {string}
     * @memberof JigApiV3ActivationCreateCa
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV3ActivationCreateResponse
 */
export interface JigApiV3ActivationCreateResponse {
    /**
     *
     * @type {JigApiV3ActivationCreateResponseVpnHub}
     * @memberof JigApiV3ActivationCreateResponse
     */
    vpnHub: JigApiV3ActivationCreateResponseVpnHub
}
/**
 *
 * @export
 * @interface JigApiV3ActivationCreateResponseVpnHub
 */
export interface JigApiV3ActivationCreateResponseVpnHub {
    /**
     *
     * @type {string}
     * @memberof JigApiV3ActivationCreateResponseVpnHub
     */
    name: string
}
/**
 *
 * @export
 * @interface JigApiV3ActivationCreateSy
 */
export interface JigApiV3ActivationCreateSy {
    /**
     * e.g. SY200001
     * @type {string}
     * @memberof JigApiV3ActivationCreateSy
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV3ActivationCreateTb
 */
export interface JigApiV3ActivationCreateTb {
    /**
     * e.g. TB200001
     * @type {string}
     * @memberof JigApiV3ActivationCreateTb
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV3DeactivationCreate
 */
export interface JigApiV3DeactivationCreate {
    /**
     * e.g. G00201
     * @type {string}
     * @memberof JigApiV3DeactivationCreate
     */
    gid: string
}
/**
 *
 * @export
 * @interface JigApiV3DroneAcConnection
 */
export interface JigApiV3DroneAcConnection {
    /**
     *
     * @type {string}
     * @memberof JigApiV3DroneAcConnection
     */
    pk?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3DroneAcConnection
     */
    imei: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3DroneAcConnection
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3DroneAcConnection
     */
    kind: string
    /**
     *
     * @type {object}
     * @memberof JigApiV3DroneAcConnection
     */
    status: object
    /**
     *
     * @type {object}
     * @memberof JigApiV3DroneAcConnection
     */
    jigStatus: object
}
/**
 *
 * @export
 * @interface JigApiV3DroneCaConnection
 */
export interface JigApiV3DroneCaConnection {
    /**
     *
     * @type {string}
     * @memberof JigApiV3DroneCaConnection
     */
    pk?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3DroneCaConnection
     */
    code: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3DroneCaConnection
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3DroneCaConnection
     */
    kind: string
    /**
     *
     * @type {object}
     * @memberof JigApiV3DroneCaConnection
     */
    status: object
    /**
     *
     * @type {object}
     * @memberof JigApiV3DroneCaConnection
     */
    jigStatus: object | null
}
/**
 *
 * @export
 * @interface JigApiV3EnvSyncAc
 */
export interface JigApiV3EnvSyncAc {
    /**
     *
     * @type {string}
     * @memberof JigApiV3EnvSyncAc
     */
    pk?: string
    /**
     *
     * @type {JigApiV3SimPairing}
     * @memberof JigApiV3EnvSyncAc
     */
    simPairing: JigApiV3SimPairing
    /**
     *
     * @type {JigApiV3DroneAcConnection}
     * @memberof JigApiV3EnvSyncAc
     */
    droneacconnection: JigApiV3DroneAcConnection
    /**
     *
     * @type {string}
     * @memberof JigApiV3EnvSyncAc
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3EnvSyncAc
     */
    imei: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3EnvSyncAc
     */
    imsi: string
}
/**
 *
 * @export
 * @interface JigApiV3EnvSyncCa
 */
export interface JigApiV3EnvSyncCa {
    /**
     *
     * @type {string}
     * @memberof JigApiV3EnvSyncCa
     */
    pk?: string
    /**
     *
     * @type {JigApiV3DroneCaConnection}
     * @memberof JigApiV3EnvSyncCa
     */
    dronecaconnection: JigApiV3DroneCaConnection
    /**
     *
     * @type {string}
     * @memberof JigApiV3EnvSyncCa
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3EnvSyncCa
     */
    code: string
}
/**
 *
 * @export
 * @interface JigApiV3Pairing
 */
export interface JigApiV3Pairing {
    /**
     *
     * @type {string}
     * @memberof JigApiV3Pairing
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3Pairing
     */
    vpnHub?: string
    /**
     *
     * @type {JigApiV3PairingDroneBs}
     * @memberof JigApiV3Pairing
     */
    droneBs: JigApiV3PairingDroneBs
    /**
     *
     * @type {JigApiV3PairingDroneTb}
     * @memberof JigApiV3Pairing
     */
    droneTb: JigApiV3PairingDroneTb
    /**
     *
     * @type {JigApiV3PairingDroneAc}
     * @memberof JigApiV3Pairing
     */
    droneAc: JigApiV3PairingDroneAc
    /**
     *
     * @type {JigApiV3PairingDroneCa}
     * @memberof JigApiV3Pairing
     */
    droneCa: JigApiV3PairingDroneCa
    /**
     *
     * @type {JigApiV3PairingDroneSy}
     * @memberof JigApiV3Pairing
     */
    droneSy: JigApiV3PairingDroneSy
}
/**
 *
 * @export
 * @interface JigApiV3PairingDroneAc
 */
export interface JigApiV3PairingDroneAc {
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof JigApiV3PairingDroneAc
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV3PairingDroneAc
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV3PairingDroneAc
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3PairingDroneAc
     */
    simPairing?: string
}
/**
 *
 * @export
 * @interface JigApiV3PairingDroneBs
 */
export interface JigApiV3PairingDroneBs {
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof JigApiV3PairingDroneBs
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV3PairingDroneBs
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV3PairingDroneBs
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3PairingDroneBs
     */
    simPairing?: string
}
/**
 *
 * @export
 * @interface JigApiV3PairingDroneCa
 */
export interface JigApiV3PairingDroneCa {
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof JigApiV3PairingDroneCa
     */
    serialNumber: string
}
/**
 *
 * @export
 * @interface JigApiV3PairingDroneSy
 */
export interface JigApiV3PairingDroneSy {
    /**
     * e.g. SY190001
     * @type {string}
     * @memberof JigApiV3PairingDroneSy
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV3PairingDroneSy
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV3PairingDroneSy
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3PairingDroneSy
     */
    simPairing?: string
}
/**
 *
 * @export
 * @interface JigApiV3PairingDroneTb
 */
export interface JigApiV3PairingDroneTb {
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof JigApiV3PairingDroneTb
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV3PairingDroneTb
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigApiV3PairingDroneTb
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3PairingDroneTb
     */
    simPairing?: string
}
/**
 *
 * @export
 * @interface JigApiV3SimPairing
 */
export interface JigApiV3SimPairing {
    /**
     *
     * @type {string}
     * @memberof JigApiV3SimPairing
     */
    pk?: string
    /**
     *
     * @type {string}
     * @memberof JigApiV3SimPairing
     */
    tel: string
}
/**
 *
 * @export
 * @interface JigNumberingAcCreate
 */
export interface JigNumberingAcCreate {
    /**
     * 基地局で使用するSIMの電話番号. e.g. 07012345678
     * @type {string}
     * @memberof JigNumberingAcCreate
     */
    tel: string
    /**
     * 基地局のLTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingAcCreate
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingAcCreate
     */
    imsi: string
}
/**
 *
 * @export
 * @interface JigNumberingAcList
 */
export interface JigNumberingAcList {
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcList
     */
    url?: string
    /**
     *
     * @type {JigNumberingAcListDroneBs}
     * @memberof JigNumberingAcList
     */
    droneBs: JigNumberingAcListDroneBs
    /**
     *
     * @type {JigNumberingAcListDroneTb}
     * @memberof JigNumberingAcList
     */
    droneTb: JigNumberingAcListDroneTb
    /**
     *
     * @type {JigNumberingAcListVpnUser}
     * @memberof JigNumberingAcList
     */
    vpnUser?: JigNumberingAcListVpnUser
    /**
     *
     * @type {JigNumberingAcListSimPairing}
     * @memberof JigNumberingAcList
     */
    simPairing?: JigNumberingAcListSimPairing
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof JigNumberingAcList
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingAcList
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingAcList
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcList
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcList
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigNumberingAcList
     */
    version: number
}
/**
 *
 * @export
 * @interface JigNumberingAcListDroneBs
 */
export interface JigNumberingAcListDroneBs {
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof JigNumberingAcListDroneBs
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListDroneBs
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingAcListDroneTb
 */
export interface JigNumberingAcListDroneTb {
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof JigNumberingAcListDroneTb
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListDroneTb
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingAcListSimPairing
 */
export interface JigNumberingAcListSimPairing {
    /**
     * e.g. 09012345678
     * @type {string}
     * @memberof JigNumberingAcListSimPairing
     */
    tel: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListSimPairing
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingAcListVpnHub
 */
export interface JigNumberingAcListVpnHub {
    /**
     *
     * @type {JigNumberingAcListVpnServer}
     * @memberof JigNumberingAcListVpnHub
     */
    vpnServer: JigNumberingAcListVpnServer
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListVpnHub
     */
    kind?: JigNumberingAcListVpnHubKindEnum
    /**
     * e.g. G00001
     * @type {string}
     * @memberof JigNumberingAcListVpnHub
     */
    name: string
    /**
     * 仮想インターフェイスに割り当てるサブネットマスク.e.g. 255.255.255.224
     * @type {string}
     * @memberof JigNumberingAcListVpnHub
     */
    netmask: string
    /**
     * 仮想インターフェイスの持つ IP アドレス. e.g. 10.0.1.94
     * @type {string}
     * @memberof JigNumberingAcListVpnHub
     */
    routerIpAddress: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListVpnHub
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingAcListVpnHubKindEnum {
    G = 'G',
    G20 = 'G20',
    Setup = 'SETUP',
    Ftest = 'FTEST',
    Ptest = 'PTEST',
    Factory = 'FACTORY',
    Devel = 'DEVEL',
    Server = 'SERVER',
}

/**
 *
 * @export
 * @interface JigNumberingAcListVpnServer
 */
export interface JigNumberingAcListVpnServer {
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof JigNumberingAcListVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof JigNumberingAcListVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof JigNumberingAcListVpnServer
     */
    hostname?: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListVpnServer
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingAcListVpnUser
 */
export interface JigNumberingAcListVpnUser {
    /**
     *
     * @type {JigNumberingAcListVpnHub}
     * @memberof JigNumberingAcListVpnUser
     */
    vpnHub: JigNumberingAcListVpnHub
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListVpnUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListVpnUser
     */
    password: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof JigNumberingAcListVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListVpnUser
     */
    kind?: JigNumberingAcListVpnUserKindEnum
    /**
     *
     * @type {string}
     * @memberof JigNumberingAcListVpnUser
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingAcListVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface JigNumberingBsCreate
 */
export interface JigNumberingBsCreate {
    /**
     * 基地局で使用するSIMの電話番号. e.g. 07012345678
     * @type {string}
     * @memberof JigNumberingBsCreate
     */
    tel: string
    /**
     * 基地局のLTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingBsCreate
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingBsCreate
     */
    imsi: string
}
/**
 *
 * @export
 * @interface JigNumberingBsList
 */
export interface JigNumberingBsList {
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsList
     */
    url?: string
    /**
     *
     * @type {JigNumberingBsListOrganization}
     * @memberof JigNumberingBsList
     */
    organization: JigNumberingBsListOrganization
    /**
     *
     * @type {JigNumberingBsListVpnUser}
     * @memberof JigNumberingBsList
     */
    vpnUser?: JigNumberingBsListVpnUser
    /**
     *
     * @type {JigNumberingBsListSimPairing}
     * @memberof JigNumberingBsList
     */
    simPairing?: JigNumberingBsListSimPairing
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof JigNumberingBsList
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingBsList
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingBsList
     */
    imsi: string
    /**
     * e.g. nwss2019-0001. T19で使用。T20以降は使用しない。
     * @type {string}
     * @memberof JigNumberingBsList
     */
    wifiSsid: string
    /**
     * ランダム生成された10桁 英小文字・数字. T19で使用。T20以降は使用しない。
     * @type {string}
     * @memberof JigNumberingBsList
     */
    wifiPassword?: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsList
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsList
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigNumberingBsList
     */
    version: number
}
/**
 *
 * @export
 * @interface JigNumberingBsListOrganization
 */
export interface JigNumberingBsListOrganization {
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListOrganization
     */
    code?: JigNumberingBsListOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListOrganization
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListOrganization
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingBsListOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface JigNumberingBsListSimPairing
 */
export interface JigNumberingBsListSimPairing {
    /**
     * e.g. 09012345678
     * @type {string}
     * @memberof JigNumberingBsListSimPairing
     */
    tel: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListSimPairing
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingBsListVpnHub
 */
export interface JigNumberingBsListVpnHub {
    /**
     *
     * @type {JigNumberingBsListVpnServer}
     * @memberof JigNumberingBsListVpnHub
     */
    vpnServer: JigNumberingBsListVpnServer
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListVpnHub
     */
    kind?: JigNumberingBsListVpnHubKindEnum
    /**
     * e.g. G00001
     * @type {string}
     * @memberof JigNumberingBsListVpnHub
     */
    name: string
    /**
     * 仮想インターフェイスに割り当てるサブネットマスク.e.g. 255.255.255.224
     * @type {string}
     * @memberof JigNumberingBsListVpnHub
     */
    netmask: string
    /**
     * 仮想インターフェイスの持つ IP アドレス. e.g. 10.0.1.94
     * @type {string}
     * @memberof JigNumberingBsListVpnHub
     */
    routerIpAddress: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListVpnHub
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingBsListVpnHubKindEnum {
    G = 'G',
    G20 = 'G20',
    Setup = 'SETUP',
    Ftest = 'FTEST',
    Ptest = 'PTEST',
    Factory = 'FACTORY',
    Devel = 'DEVEL',
    Server = 'SERVER',
}

/**
 *
 * @export
 * @interface JigNumberingBsListVpnServer
 */
export interface JigNumberingBsListVpnServer {
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof JigNumberingBsListVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof JigNumberingBsListVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof JigNumberingBsListVpnServer
     */
    hostname?: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListVpnServer
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingBsListVpnUser
 */
export interface JigNumberingBsListVpnUser {
    /**
     *
     * @type {JigNumberingBsListVpnHub}
     * @memberof JigNumberingBsListVpnUser
     */
    vpnHub: JigNumberingBsListVpnHub
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListVpnUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListVpnUser
     */
    password: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof JigNumberingBsListVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListVpnUser
     */
    kind?: JigNumberingBsListVpnUserKindEnum
    /**
     *
     * @type {string}
     * @memberof JigNumberingBsListVpnUser
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingBsListVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface JigNumberingCaList
 */
export interface JigNumberingCaList {
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaList
     */
    url?: string
    /**
     *
     * @type {JigNumberingCaListDroneAc}
     * @memberof JigNumberingCaList
     */
    droneAc?: JigNumberingCaListDroneAc
    /**
     *
     * @type {JigNumberingCaListVpnUser}
     * @memberof JigNumberingCaList
     */
    vpnUser?: JigNumberingCaListVpnUser
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof JigNumberingCaList
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaList
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaList
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigNumberingCaList
     */
    version: number
}
/**
 *
 * @export
 * @interface JigNumberingCaListDroneAc
 */
export interface JigNumberingCaListDroneAc {
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof JigNumberingCaListDroneAc
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaListDroneAc
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingCaListVpnHub
 */
export interface JigNumberingCaListVpnHub {
    /**
     *
     * @type {JigNumberingCaListVpnServer}
     * @memberof JigNumberingCaListVpnHub
     */
    vpnServer: JigNumberingCaListVpnServer
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaListVpnHub
     */
    kind?: JigNumberingCaListVpnHubKindEnum
    /**
     * e.g. G00001
     * @type {string}
     * @memberof JigNumberingCaListVpnHub
     */
    name: string
    /**
     * 仮想インターフェイスに割り当てるサブネットマスク.e.g. 255.255.255.224
     * @type {string}
     * @memberof JigNumberingCaListVpnHub
     */
    netmask: string
    /**
     * 仮想インターフェイスの持つ IP アドレス. e.g. 10.0.1.94
     * @type {string}
     * @memberof JigNumberingCaListVpnHub
     */
    routerIpAddress: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaListVpnHub
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingCaListVpnHubKindEnum {
    G = 'G',
    G20 = 'G20',
    Setup = 'SETUP',
    Ftest = 'FTEST',
    Ptest = 'PTEST',
    Factory = 'FACTORY',
    Devel = 'DEVEL',
    Server = 'SERVER',
}

/**
 *
 * @export
 * @interface JigNumberingCaListVpnServer
 */
export interface JigNumberingCaListVpnServer {
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof JigNumberingCaListVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof JigNumberingCaListVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof JigNumberingCaListVpnServer
     */
    hostname?: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaListVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaListVpnServer
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingCaListVpnUser
 */
export interface JigNumberingCaListVpnUser {
    /**
     *
     * @type {JigNumberingCaListVpnHub}
     * @memberof JigNumberingCaListVpnUser
     */
    vpnHub: JigNumberingCaListVpnHub
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaListVpnUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaListVpnUser
     */
    password: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof JigNumberingCaListVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaListVpnUser
     */
    kind?: JigNumberingCaListVpnUserKindEnum
    /**
     *
     * @type {string}
     * @memberof JigNumberingCaListVpnUser
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingCaListVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface JigNumberingSyCreate
 */
export interface JigNumberingSyCreate {
    /**
     * 基地局で使用するSIMの電話番号. e.g. 07012345678
     * @type {string}
     * @memberof JigNumberingSyCreate
     */
    tel: string
    /**
     * 基地局のLTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingSyCreate
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingSyCreate
     */
    imsi: string
}
/**
 *
 * @export
 * @interface JigNumberingSyList
 */
export interface JigNumberingSyList {
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyList
     */
    url?: string
    /**
     *
     * @type {JigNumberingSyListDroneBs}
     * @memberof JigNumberingSyList
     */
    droneBs: JigNumberingSyListDroneBs
    /**
     *
     * @type {JigNumberingSyListDroneTb}
     * @memberof JigNumberingSyList
     */
    droneTb: JigNumberingSyListDroneTb
    /**
     *
     * @type {JigNumberingSyListVpnUser}
     * @memberof JigNumberingSyList
     */
    vpnUser?: JigNumberingSyListVpnUser
    /**
     *
     * @type {JigNumberingSyListSimPairing}
     * @memberof JigNumberingSyList
     */
    simPairing?: JigNumberingSyListSimPairing
    /**
     * e.g. SY190001
     * @type {string}
     * @memberof JigNumberingSyList
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingSyList
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingSyList
     */
    imsi: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyList
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyList
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigNumberingSyList
     */
    version: number
}
/**
 *
 * @export
 * @interface JigNumberingSyListDroneBs
 */
export interface JigNumberingSyListDroneBs {
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof JigNumberingSyListDroneBs
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListDroneBs
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingSyListDroneTb
 */
export interface JigNumberingSyListDroneTb {
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof JigNumberingSyListDroneTb
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListDroneTb
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingSyListSimPairing
 */
export interface JigNumberingSyListSimPairing {
    /**
     * e.g. 09012345678
     * @type {string}
     * @memberof JigNumberingSyListSimPairing
     */
    tel: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListSimPairing
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingSyListVpnHub
 */
export interface JigNumberingSyListVpnHub {
    /**
     *
     * @type {JigNumberingSyListVpnServer}
     * @memberof JigNumberingSyListVpnHub
     */
    vpnServer: JigNumberingSyListVpnServer
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListVpnHub
     */
    kind?: JigNumberingSyListVpnHubKindEnum
    /**
     * e.g. G00001
     * @type {string}
     * @memberof JigNumberingSyListVpnHub
     */
    name: string
    /**
     * 仮想インターフェイスに割り当てるサブネットマスク.e.g. 255.255.255.224
     * @type {string}
     * @memberof JigNumberingSyListVpnHub
     */
    netmask: string
    /**
     * 仮想インターフェイスの持つ IP アドレス. e.g. 10.0.1.94
     * @type {string}
     * @memberof JigNumberingSyListVpnHub
     */
    routerIpAddress: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListVpnHub
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingSyListVpnHubKindEnum {
    G = 'G',
    G20 = 'G20',
    Setup = 'SETUP',
    Ftest = 'FTEST',
    Ptest = 'PTEST',
    Factory = 'FACTORY',
    Devel = 'DEVEL',
    Server = 'SERVER',
}

/**
 *
 * @export
 * @interface JigNumberingSyListVpnServer
 */
export interface JigNumberingSyListVpnServer {
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof JigNumberingSyListVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof JigNumberingSyListVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof JigNumberingSyListVpnServer
     */
    hostname?: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListVpnServer
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingSyListVpnUser
 */
export interface JigNumberingSyListVpnUser {
    /**
     *
     * @type {JigNumberingSyListVpnHub}
     * @memberof JigNumberingSyListVpnUser
     */
    vpnHub: JigNumberingSyListVpnHub
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListVpnUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListVpnUser
     */
    password: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof JigNumberingSyListVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListVpnUser
     */
    kind?: JigNumberingSyListVpnUserKindEnum
    /**
     *
     * @type {string}
     * @memberof JigNumberingSyListVpnUser
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingSyListVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface JigNumberingTbCreate
 */
export interface JigNumberingTbCreate {
    /**
     * 基地局で使用するSIMの電話番号. e.g. 07012345678
     * @type {string}
     * @memberof JigNumberingTbCreate
     */
    tel: string
    /**
     * 基地局のLTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingTbCreate
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingTbCreate
     */
    imsi: string
    /**
     * 1: 飛行用, 2: 販売店・社内配布用
     * @type {number}
     * @memberof JigNumberingTbCreate
     */
    kind?: number
}
/**
 *
 * @export
 * @interface JigNumberingTbList
 */
export interface JigNumberingTbList {
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbList
     */
    url?: string
    /**
     *
     * @type {JigNumberingTbListDroneBs}
     * @memberof JigNumberingTbList
     */
    droneBs?: JigNumberingTbListDroneBs
    /**
     *
     * @type {JigNumberingTbListVpnUser}
     * @memberof JigNumberingTbList
     */
    vpnUser?: JigNumberingTbListVpnUser
    /**
     *
     * @type {JigNumberingTbListSimPairing}
     * @memberof JigNumberingTbList
     */
    simPairing?: JigNumberingTbListSimPairing
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof JigNumberingTbList
     */
    serialNumber: string
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingTbList
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof JigNumberingTbList
     */
    imsi: string
    /**
     * タブレットロック解除に使用する4桁の数字。         レコードCREATE時に自動生成。         2019-05 以降、使用しなくなった。互換性のために残している。
     * @type {string}
     * @memberof JigNumberingTbList
     */
    pinCode?: string
    /**
     *
     * @type {number}
     * @memberof JigNumberingTbList
     */
    kind?: number
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbList
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbList
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof JigNumberingTbList
     */
    version: number
}
/**
 *
 * @export
 * @interface JigNumberingTbListDroneBs
 */
export interface JigNumberingTbListDroneBs {
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof JigNumberingTbListDroneBs
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListDroneBs
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingTbListSimPairing
 */
export interface JigNumberingTbListSimPairing {
    /**
     * e.g. 09012345678
     * @type {string}
     * @memberof JigNumberingTbListSimPairing
     */
    tel: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListSimPairing
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingTbListVpnHub
 */
export interface JigNumberingTbListVpnHub {
    /**
     *
     * @type {JigNumberingTbListVpnServer}
     * @memberof JigNumberingTbListVpnHub
     */
    vpnServer: JigNumberingTbListVpnServer
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListVpnHub
     */
    kind?: JigNumberingTbListVpnHubKindEnum
    /**
     * e.g. G00001
     * @type {string}
     * @memberof JigNumberingTbListVpnHub
     */
    name: string
    /**
     * 仮想インターフェイスに割り当てるサブネットマスク.e.g. 255.255.255.224
     * @type {string}
     * @memberof JigNumberingTbListVpnHub
     */
    netmask: string
    /**
     * 仮想インターフェイスの持つ IP アドレス. e.g. 10.0.1.94
     * @type {string}
     * @memberof JigNumberingTbListVpnHub
     */
    routerIpAddress: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListVpnHub
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingTbListVpnHubKindEnum {
    G = 'G',
    G20 = 'G20',
    Setup = 'SETUP',
    Ftest = 'FTEST',
    Ptest = 'PTEST',
    Factory = 'FACTORY',
    Devel = 'DEVEL',
    Server = 'SERVER',
}

/**
 *
 * @export
 * @interface JigNumberingTbListVpnServer
 */
export interface JigNumberingTbListVpnServer {
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof JigNumberingTbListVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof JigNumberingTbListVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof JigNumberingTbListVpnServer
     */
    hostname?: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListVpnServer
     */
    created: string
}
/**
 *
 * @export
 * @interface JigNumberingTbListVpnUser
 */
export interface JigNumberingTbListVpnUser {
    /**
     *
     * @type {JigNumberingTbListVpnHub}
     * @memberof JigNumberingTbListVpnUser
     */
    vpnHub: JigNumberingTbListVpnHub
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListVpnUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListVpnUser
     */
    password: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof JigNumberingTbListVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListVpnUser
     */
    kind?: JigNumberingTbListVpnUserKindEnum
    /**
     *
     * @type {string}
     * @memberof JigNumberingTbListVpnUser
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigNumberingTbListVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface JigVpn
 */
export interface JigVpn {
    /**
     *
     * @type {string}
     * @memberof JigVpn
     */
    url?: string
    /**
     *
     * @type {JigVpnVpnServer}
     * @memberof JigVpn
     */
    vpnServer: JigVpnVpnServer
    /**
     *
     * @type {JigVpnRsyncServer}
     * @memberof JigVpn
     */
    rsyncServer: JigVpnRsyncServer
    /**
     *
     * @type {JigVpnMavLinkServer}
     * @memberof JigVpn
     */
    mavlinkServer: JigVpnMavLinkServer
    /**
     *
     * @type {JigVpnFirmwareUpdateServer}
     * @memberof JigVpn
     */
    firmwareUpdateServer: JigVpnFirmwareUpdateServer
    /**
     *
     * @type {string}
     * @memberof JigVpn
     */
    kind?: JigVpnKindEnum
    /**
     * e.g. G00001
     * @type {string}
     * @memberof JigVpn
     */
    name: string
    /**
     * 仮想インターフェイスに割り当てるサブネットマスク.e.g. 255.255.255.224
     * @type {string}
     * @memberof JigVpn
     */
    netmask: string
    /**
     * 仮想インターフェイスの持つ IP アドレス. e.g. 10.0.1.94
     * @type {string}
     * @memberof JigVpn
     */
    routerIpAddress: string
    /**
     *
     * @type {Array<JigVpnVpnUser>}
     * @memberof JigVpn
     */
    vpnUsers: Array<JigVpnVpnUser>
    /**
     *
     * @type {string}
     * @memberof JigVpn
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigVpnKindEnum {
    G = 'G',
    G20 = 'G20',
    Setup = 'SETUP',
    Ftest = 'FTEST',
    Ptest = 'PTEST',
    Factory = 'FACTORY',
    Devel = 'DEVEL',
    Server = 'SERVER',
}

/**
 *
 * @export
 * @interface JigVpnFirmwareUpdateServer
 */
export interface JigVpnFirmwareUpdateServer {
    /**
     *
     * @type {string}
     * @memberof JigVpnFirmwareUpdateServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface JigVpnMavLinkServer
 */
export interface JigVpnMavLinkServer {
    /**
     *
     * @type {string}
     * @memberof JigVpnMavLinkServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface JigVpnRsyncServer
 */
export interface JigVpnRsyncServer {
    /**
     *
     * @type {string}
     * @memberof JigVpnRsyncServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface JigVpnServer
 */
export interface JigVpnServer {
    /**
     *
     * @type {string}
     * @memberof JigVpnServer
     */
    url?: string
    /**
     *
     * @type {string}
     * @memberof JigVpnServer
     */
    id?: string
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof JigVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof JigVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof JigVpnServer
     */
    hostname?: string
    /**
     *
     * @type {string}
     * @memberof JigVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof JigVpnServer
     */
    openVpnConfigTemplate?: string
    /**
     *
     * @type {string}
     * @memberof JigVpnServer
     */
    created?: string
}
/**
 *
 * @export
 * @interface JigVpnVpnServer
 */
export interface JigVpnVpnServer {
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof JigVpnVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof JigVpnVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof JigVpnVpnServer
     */
    hostname?: string
    /**
     *
     * @type {string}
     * @memberof JigVpnVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof JigVpnVpnServer
     */
    created: string
}
/**
 *
 * @export
 * @interface JigVpnVpnUser
 */
export interface JigVpnVpnUser {
    /**
     *
     * @type {string}
     * @memberof JigVpnVpnUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof JigVpnVpnUser
     */
    password: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof JigVpnVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof JigVpnVpnUser
     */
    kind?: JigVpnVpnUserKindEnum
    /**
     *
     * @type {Array<DroneBs>}
     * @memberof JigVpnVpnUser
     */
    dronebsSet: Array<DroneBs>
    /**
     *
     * @type {Array<DroneAc>}
     * @memberof JigVpnVpnUser
     */
    droneacSet: Array<DroneAc>
    /**
     *
     * @type {Array<DroneCa>}
     * @memberof JigVpnVpnUser
     */
    dronecaSet: Array<DroneCa>
    /**
     *
     * @type {Array<DroneSy>}
     * @memberof JigVpnVpnUser
     */
    dronesySet: Array<DroneSy>
    /**
     *
     * @type {Array<DroneTb>}
     * @memberof JigVpnVpnUser
     */
    dronetbSet: Array<DroneTb>
    /**
     *
     * @type {string}
     * @memberof JigVpnVpnUser
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum JigVpnVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface MachineApiV2ConnectionAcCommonResponse
 */
export interface MachineApiV2ConnectionAcCommonResponse {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    id: string
    /**
     *
     * @type {MachineApiV2ConnectionAcCommonResponseDroneAc}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    droneAc?: MachineApiV2ConnectionAcCommonResponseDroneAc
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    imei: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    url?: string
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    vpnUser: MachineApiV2ConnectionCommonAllVpnUser
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponse
     */
    firmwareVersions?: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionAcCommonResponseDroneAc
 */
export interface MachineApiV2ConnectionAcCommonResponseDroneAc {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    id?: string
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    version?: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    vpnUser?: MachineApiV2ConnectionCommonAllVpnUser
    /**
     *
     * @type {MachineApiV2ConnectionAcCommonResponseDroneAcModelNumber}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    droneAcModelNumber: MachineApiV2ConnectionAcCommonResponseDroneAcModelNumber
    /**
     *
     * @type {MachineApiV2ConnectionCommonSimPairing}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    simPairing: MachineApiV2ConnectionCommonSimPairing
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    imsi: string
    /**
     *
     * @type {Array<MachineApiV2ConnectionCommonDroneCa>}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    dronecaSet: Array<MachineApiV2ConnectionCommonDroneCa>
    /**
     *
     * @type {MachineApiV2ConnectionCommonDroneBs}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    droneBs?: MachineApiV2ConnectionCommonDroneBs
    /**
     *
     * @type {MachineApiV2ConnectionCommonDroneTb}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAc
     */
    droneTb?: MachineApiV2ConnectionCommonDroneTb
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionAcCommonResponseDroneAcModelNumber
 */
export interface MachineApiV2ConnectionAcCommonResponseDroneAcModelNumber {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAcModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAcModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCommonResponseDroneAcModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionAcCreateRequest
 */
export interface MachineApiV2ConnectionAcCreateRequest {
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCreateRequest
     */
    imei: string
    /**
     * 採番時に生成されたトークン もしくは 製造時の初期値トークン。
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCreateRequest
     */
    token: string
    /**
     * connect: 接続, disconnect: 切断, status: 状態更新
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCreateRequest
     */
    kind: MachineApiV2ConnectionAcCreateRequestKindEnum
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。kind=\"connect\" のときに指定する。
     * @type {string}
     * @memberof MachineApiV2ConnectionAcCreateRequest
     */
    privateIpAddress?: string
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionAcCreateRequest
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionAcCreateRequest
     */
    jigStatus?: object | null
}

/**
 * @export
 * @enum {string}
 */
export enum MachineApiV2ConnectionAcCreateRequestKindEnum {
    Connect = 'connect',
    Disconnect = 'disconnect',
    Status = 'status',
}

/**
 *
 * @export
 * @interface MachineApiV2ConnectionBsCommonResponse
 */
export interface MachineApiV2ConnectionBsCommonResponse {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    id: string
    /**
     *
     * @type {MachineApiV2ConnectionBsCommonResponseDroneBs}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    droneBs?: MachineApiV2ConnectionBsCommonResponseDroneBs
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    imei: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    url?: string
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    vpnUser: MachineApiV2ConnectionCommonAllVpnUser
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponse
     */
    firmwareVersions?: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionBsCommonResponseDroneBs
 */
export interface MachineApiV2ConnectionBsCommonResponseDroneBs {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    id?: string
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    version?: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    vpnUser?: MachineApiV2ConnectionCommonAllVpnUser
    /**
     *
     * @type {MachineApiV2ConnectionBsCommonResponseDroneBsModelNumber}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    droneBsModelNumber: MachineApiV2ConnectionBsCommonResponseDroneBsModelNumber
    /**
     *
     * @type {MachineApiV2ConnectionCommonSimPairing}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    simPairing: MachineApiV2ConnectionCommonSimPairing
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    imsi: string
    /**
     *
     * @type {Array<MachineApiV2ConnectionCommonDroneAc>}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    droneacSet: Array<MachineApiV2ConnectionCommonDroneAc>
    /**
     *
     * @type {Array<MachineApiV2ConnectionCommonDroneSy>}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    dronesySet: Array<MachineApiV2ConnectionCommonDroneSy>
    /**
     *
     * @type {Array<MachineApiV2ConnectionCommonDroneTb>}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    dronetbSet: Array<MachineApiV2ConnectionCommonDroneTb>
    /**
     *
     * @type {MachineApiV2ConnectionBsCommonResponseOrganization}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBs
     */
    organization: MachineApiV2ConnectionBsCommonResponseOrganization
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionBsCommonResponseDroneBsModelNumber
 */
export interface MachineApiV2ConnectionBsCommonResponseDroneBsModelNumber {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBsModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBsModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseDroneBsModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionBsCommonResponseOrganization
 */
export interface MachineApiV2ConnectionBsCommonResponseOrganization {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseOrganization
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseOrganization
     */
    code?: MachineApiV2ConnectionBsCommonResponseOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseOrganization
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCommonResponseOrganization
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum MachineApiV2ConnectionBsCommonResponseOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MachineApiV2ConnectionBsCreateRequest
 */
export interface MachineApiV2ConnectionBsCreateRequest {
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCreateRequest
     */
    imei: string
    /**
     * 採番時に生成されたトークン もしくは 製造時の初期値トークン。
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCreateRequest
     */
    token: string
    /**
     * connect: 接続, disconnect: 切断, status: 状態更新
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCreateRequest
     */
    kind: MachineApiV2ConnectionBsCreateRequestKindEnum
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。kind=\"connect\" のときに指定する。
     * @type {string}
     * @memberof MachineApiV2ConnectionBsCreateRequest
     */
    privateIpAddress?: string
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionBsCreateRequest
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionBsCreateRequest
     */
    jigStatus?: object | null
}

/**
 * @export
 * @enum {string}
 */
export enum MachineApiV2ConnectionBsCreateRequestKindEnum {
    Connect = 'connect',
    Disconnect = 'disconnect',
    Status = 'status',
}

/**
 *
 * @export
 * @interface MachineApiV2ConnectionCaCommonResponse
 */
export interface MachineApiV2ConnectionCaCommonResponse {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    id: string
    /**
     *
     * @type {MachineApiV2ConnectionCaCommonResponseDroneCa}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    droneCa?: MachineApiV2ConnectionCaCommonResponseDroneCa
    /**
     * カメラの識別子。T20から追加。カメラ毎にユニークだが、T19カメラコード未登録のため、unique=Falseにしている。
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    code: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    url?: string
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    vpnUser: MachineApiV2ConnectionCommonAllVpnUser
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponse
     */
    firmwareVersions?: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCaCommonResponseDroneCa
 */
export interface MachineApiV2ConnectionCaCommonResponseDroneCa {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCa
     */
    id?: string
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCa
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCa
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCa
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCa
     */
    version?: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCa
     */
    vpnUser?: MachineApiV2ConnectionCommonAllVpnUser
    /**
     *
     * @type {MachineApiV2ConnectionCaCommonResponseDroneCaModelNumber}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCa
     */
    droneCaModelNumber: MachineApiV2ConnectionCaCommonResponseDroneCaModelNumber
    /**
     * カメラの識別子。T20から追加。カメラ毎にユニークだが、T19カメラコード未取得のため、unique=Falseにしている。
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCa
     */
    code?: string
    /**
     *
     * @type {MachineApiV2ConnectionCommonDroneAc}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCa
     */
    droneAc?: MachineApiV2ConnectionCommonDroneAc
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCaCommonResponseDroneCaModelNumber
 */
export interface MachineApiV2ConnectionCaCommonResponseDroneCaModelNumber {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCaModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCaModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCommonResponseDroneCaModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCaCreateRequest
 */
export interface MachineApiV2ConnectionCaCreateRequest {
    /**
     * カメラの識別子。
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCreateRequest
     */
    code: string
    /**
     * 採番時に生成されたトークン もしくは 製造時の初期値トークン。
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCreateRequest
     */
    token: string
    /**
     * connect: 接続, disconnect: 切断, status: 状態更新
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCreateRequest
     */
    kind: MachineApiV2ConnectionCaCreateRequestKindEnum
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。kind=\"connect\" のときに指定する。
     * @type {string}
     * @memberof MachineApiV2ConnectionCaCreateRequest
     */
    privateIpAddress?: string
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionCaCreateRequest
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionCaCreateRequest
     */
    jigStatus?: object | null
}

/**
 * @export
 * @enum {string}
 */
export enum MachineApiV2ConnectionCaCreateRequestKindEnum {
    Connect = 'connect',
    Disconnect = 'disconnect',
    Status = 'status',
}

/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonAllVpnUser
 */
export interface MachineApiV2ConnectionCommonAllVpnUser {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonAllVpnUser
     */
    id: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonAllVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonAllVpnUser
     */
    kind?: MachineApiV2ConnectionCommonAllVpnUserKindEnum
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonAllVpnUser
     */
    created: string
    /**
     *
     * @type {MachineApiV2ConnectionCommonVpnHub}
     * @memberof MachineApiV2ConnectionCommonAllVpnUser
     */
    vpnHub: MachineApiV2ConnectionCommonVpnHub
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonAllVpnUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonAllVpnUser
     */
    password: string
}

/**
 * @export
 * @enum {string}
 */
export enum MachineApiV2ConnectionCommonAllVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonDroneAc
 */
export interface MachineApiV2ConnectionCommonDroneAc {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneAc
     */
    id: string
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneAc
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneAc
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneAc
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionCommonDroneAc
     */
    version: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonMinimumVpnUser}
     * @memberof MachineApiV2ConnectionCommonDroneAc
     */
    vpnUser: MachineApiV2ConnectionCommonMinimumVpnUser
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonDroneBs
 */
export interface MachineApiV2ConnectionCommonDroneBs {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneBs
     */
    id: string
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneBs
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneBs
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneBs
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionCommonDroneBs
     */
    version: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonMinimumVpnUser}
     * @memberof MachineApiV2ConnectionCommonDroneBs
     */
    vpnUser: MachineApiV2ConnectionCommonMinimumVpnUser
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonDroneCa
 */
export interface MachineApiV2ConnectionCommonDroneCa {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneCa
     */
    id: string
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneCa
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneCa
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneCa
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionCommonDroneCa
     */
    version: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonMinimumVpnUser}
     * @memberof MachineApiV2ConnectionCommonDroneCa
     */
    vpnUser: MachineApiV2ConnectionCommonMinimumVpnUser
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonDroneSy
 */
export interface MachineApiV2ConnectionCommonDroneSy {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneSy
     */
    id: string
    /**
     * e.g. SY190001
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneSy
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneSy
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneSy
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionCommonDroneSy
     */
    version: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonMinimumVpnUser}
     * @memberof MachineApiV2ConnectionCommonDroneSy
     */
    vpnUser: MachineApiV2ConnectionCommonMinimumVpnUser
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonDroneTb
 */
export interface MachineApiV2ConnectionCommonDroneTb {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneTb
     */
    id: string
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneTb
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneTb
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonDroneTb
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionCommonDroneTb
     */
    version: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonMinimumVpnUser}
     * @memberof MachineApiV2ConnectionCommonDroneTb
     */
    vpnUser: MachineApiV2ConnectionCommonMinimumVpnUser
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonFirmwareUpdateServer
 */
export interface MachineApiV2ConnectionCommonFirmwareUpdateServer {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonFirmwareUpdateServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonMavLinkServer
 */
export interface MachineApiV2ConnectionCommonMavLinkServer {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonMavLinkServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonMinimumVpnUser
 */
export interface MachineApiV2ConnectionCommonMinimumVpnUser {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonMinimumVpnUser
     */
    id: string
    /**
     * ログインユーザーが使用する固定IPアドレス。未割り当てもしくはDHCP使用時はNULL. e.g. 10.0.1.66
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonMinimumVpnUser
     */
    privateIpAddress?: string | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonMinimumVpnUser
     */
    kind?: MachineApiV2ConnectionCommonMinimumVpnUserKindEnum
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonMinimumVpnUser
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum MachineApiV2ConnectionCommonMinimumVpnUserKindEnum {
    DroneBs = 'DRONE_BS',
    DroneAc = 'DRONE_AC',
    DroneCa = 'DRONE_CA',
    DroneSy = 'DRONE_SY',
    DroneTb = 'DRONE_TB',
    Etc = 'ETC',
}

/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonRsyncServer
 */
export interface MachineApiV2ConnectionCommonRsyncServer {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonRsyncServer
     */
    privateIpAddress: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonSimPairing
 */
export interface MachineApiV2ConnectionCommonSimPairing {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonSimPairing
     */
    id: string
    /**
     * e.g. 09012345678
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonSimPairing
     */
    tel: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonSimPairing
     */
    created: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonVpnHub
 */
export interface MachineApiV2ConnectionCommonVpnHub {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    id: string
    /**
     *
     * @type {MachineApiV2ConnectionCommonVpnServer}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    vpnServer: MachineApiV2ConnectionCommonVpnServer
    /**
     *
     * @type {MachineApiV2ConnectionCommonRsyncServer}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    rsyncServer: MachineApiV2ConnectionCommonRsyncServer
    /**
     *
     * @type {MachineApiV2ConnectionCommonMavLinkServer}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    mavlinkServer: MachineApiV2ConnectionCommonMavLinkServer
    /**
     *
     * @type {MachineApiV2ConnectionCommonFirmwareUpdateServer}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    firmwareUpdateServer: MachineApiV2ConnectionCommonFirmwareUpdateServer
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    kind?: MachineApiV2ConnectionCommonVpnHubKindEnum
    /**
     * e.g. G00001
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    name: string
    /**
     * 仮想インターフェイスに割り当てるサブネットマスク.e.g. 255.255.255.224
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    netmask: string
    /**
     * 仮想インターフェイスの持つ IP アドレス. e.g. 10.0.1.94
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    routerIpAddress: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnHub
     */
    created: string
}

/**
 * @export
 * @enum {string}
 */
export enum MachineApiV2ConnectionCommonVpnHubKindEnum {
    G = 'G',
    G20 = 'G20',
    Setup = 'SETUP',
    Ftest = 'FTEST',
    Ptest = 'PTEST',
    Factory = 'FACTORY',
    Devel = 'DEVEL',
    Server = 'SERVER',
}

/**
 *
 * @export
 * @interface MachineApiV2ConnectionCommonVpnServer
 */
export interface MachineApiV2ConnectionCommonVpnServer {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnServer
     */
    id: string
    /**
     * VPNサーバーのパブリックIPアドレス。e.g. 13.115.95.111
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnServer
     */
    publicIpAddress: string
    /**
     * VPNサーバーのプライベートIPアドレス。e.g. 10.0.0.2
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnServer
     */
    privateIpAddress: string
    /**
     * e.g. vpn001.nileworks.io
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnServer
     */
    hostname?: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionCommonVpnServer
     */
    portNumber?: number
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnServer
     */
    ipSecPsk: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionCommonVpnServer
     */
    created: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionSyCommonResponse
 */
export interface MachineApiV2ConnectionSyCommonResponse {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    id: string
    /**
     *
     * @type {MachineApiV2ConnectionSyCommonResponseDroneSy}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    droneSy?: MachineApiV2ConnectionSyCommonResponseDroneSy
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    imei: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    url?: string
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    vpnUser: MachineApiV2ConnectionCommonAllVpnUser
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponse
     */
    firmwareVersions?: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionSyCommonResponseDroneSy
 */
export interface MachineApiV2ConnectionSyCommonResponseDroneSy {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    id?: string
    /**
     * e.g. SY190001
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    version?: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    vpnUser?: MachineApiV2ConnectionCommonAllVpnUser
    /**
     *
     * @type {MachineApiV2ConnectionSyCommonResponseDroneSyModelNumber}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    droneSyModelNumber: MachineApiV2ConnectionSyCommonResponseDroneSyModelNumber
    /**
     *
     * @type {MachineApiV2ConnectionCommonSimPairing}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    simPairing: MachineApiV2ConnectionCommonSimPairing
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    imsi: string
    /**
     *
     * @type {MachineApiV2ConnectionCommonDroneBs}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    droneBs?: MachineApiV2ConnectionCommonDroneBs
    /**
     *
     * @type {MachineApiV2ConnectionCommonDroneTb}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSy
     */
    droneTb?: MachineApiV2ConnectionCommonDroneTb
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionSyCommonResponseDroneSyModelNumber
 */
export interface MachineApiV2ConnectionSyCommonResponseDroneSyModelNumber {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSyModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSyModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCommonResponseDroneSyModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionSyCreateRequest
 */
export interface MachineApiV2ConnectionSyCreateRequest {
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCreateRequest
     */
    imei: string
    /**
     * 採番時に生成されたトークン もしくは 製造時の初期値トークン。
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCreateRequest
     */
    token: string
    /**
     * connect: 接続, disconnect: 切断, status: 状態更新
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCreateRequest
     */
    kind: MachineApiV2ConnectionSyCreateRequestKindEnum
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。kind=\"connect\" のときに指定する。
     * @type {string}
     * @memberof MachineApiV2ConnectionSyCreateRequest
     */
    privateIpAddress?: string
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionSyCreateRequest
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionSyCreateRequest
     */
    jigStatus?: object | null
}

/**
 * @export
 * @enum {string}
 */
export enum MachineApiV2ConnectionSyCreateRequestKindEnum {
    Connect = 'connect',
    Disconnect = 'disconnect',
    Status = 'status',
}

/**
 *
 * @export
 * @interface MachineApiV2ConnectionTbCommonResponse
 */
export interface MachineApiV2ConnectionTbCommonResponse {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    id: string
    /**
     *
     * @type {MachineApiV2ConnectionTbCommonResponseDroneTb}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    droneTb?: MachineApiV2ConnectionTbCommonResponseDroneTb
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    imei: string
    /**
     * ランダム生成されたトークン。機材の識別に使用する。
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    token: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    connected?: string | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    disconnected?: string | null
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    privateIpAddress?: string | null
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    jigStatus?: object | null
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    url?: string
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionTbCommonResponse
     */
    vpnUser: MachineApiV2ConnectionCommonAllVpnUser
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionTbCommonResponseDroneTb
 */
export interface MachineApiV2ConnectionTbCommonResponseDroneTb {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    id?: string
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    serialNumber: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    version?: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonAllVpnUser}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    vpnUser?: MachineApiV2ConnectionCommonAllVpnUser
    /**
     *
     * @type {MachineApiV2ConnectionTbCommonResponseDroneTbModelNumber}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    droneTbModelNumber: MachineApiV2ConnectionTbCommonResponseDroneTbModelNumber
    /**
     *
     * @type {MachineApiV2ConnectionCommonSimPairing}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    simPairing: MachineApiV2ConnectionCommonSimPairing
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    imei: string
    /**
     * 携帯電話加入者に対して発行される識別番号. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    imsi: string
    /**
     *
     * @type {number}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    kind?: number
    /**
     *
     * @type {MachineApiV2ConnectionCommonDroneBs}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    droneBs?: MachineApiV2ConnectionCommonDroneBs
    /**
     *
     * @type {Array<MachineApiV2ConnectionCommonDroneAc>}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    droneacSet: Array<MachineApiV2ConnectionCommonDroneAc>
    /**
     *
     * @type {Array<MachineApiV2ConnectionCommonDroneSy>}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTb
     */
    dronesySet: Array<MachineApiV2ConnectionCommonDroneSy>
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionTbCommonResponseDroneTbModelNumber
 */
export interface MachineApiV2ConnectionTbCommonResponseDroneTbModelNumber {
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTbModelNumber
     */
    id: string
    /**
     * e.g. T19
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTbModelNumber
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCommonResponseDroneTbModelNumber
     */
    created: string
}
/**
 *
 * @export
 * @interface MachineApiV2ConnectionTbCreateRequest
 */
export interface MachineApiV2ConnectionTbCreateRequest {
    /**
     * LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCreateRequest
     */
    imei: string
    /**
     * 採番時に生成されたトークン もしくは 製造時の初期値トークン。
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCreateRequest
     */
    token: string
    /**
     * connect: 接続, disconnect: 切断, status: 状態更新
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCreateRequest
     */
    kind: MachineApiV2ConnectionTbCreateRequestKindEnum
    /**
     * 機材(基地局・測量機・機体)接続時にVPNから割り当てられたIPアドレス。kind=\"connect\" のときに指定する。
     * @type {string}
     * @memberof MachineApiV2ConnectionTbCreateRequest
     */
    privateIpAddress?: string
    /**
     * 機材(基地局・測量機・機体)ごとに独自の状態を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionTbCreateRequest
     */
    status?: object | null
    /**
     * 治具進捗状況を表すJSONデータ。
     * @type {object}
     * @memberof MachineApiV2ConnectionTbCreateRequest
     */
    jigStatus?: object | null
}

/**
 * @export
 * @enum {string}
 */
export enum MachineApiV2ConnectionTbCreateRequestKindEnum {
    Connect = 'connect',
    Disconnect = 'disconnect',
    Status = 'status',
}

/**
 *
 * @export
 * @interface MobileAgriGrowthsList
 */
export interface MobileAgriGrowthsList {
    /**
     *
     * @type {string}
     * @memberof MobileAgriGrowthsList
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileAgriGrowthsList
     */
    s3ObjectKey: string
    /**
     *
     * @type {object}
     * @memberof MobileAgriGrowthsList
     */
    meshGeoJson: object
    /**
     *
     * @type {string}
     * @memberof MobileAgriGrowthsList
     */
    meshType?: string
    /**
     *
     * @type {string}
     * @memberof MobileAgriGrowthsList
     */
    meshUnit?: string
    /**
     *
     * @type {string}
     * @memberof MobileAgriGrowthsList
     */
    recordedAt: string
    /**
     *
     * @type {string}
     * @memberof MobileAgriGrowthsList
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileAgriGrowthsList
     */
    modified?: string
    /**
     *
     * @type {MobileAgriGrowthsListField}
     * @memberof MobileAgriGrowthsList
     */
    field: MobileAgriGrowthsListField
}
/**
 *
 * @export
 * @interface MobileAgriGrowthsListField
 */
export interface MobileAgriGrowthsListField {
    /**
     *
     * @type {string}
     * @memberof MobileAgriGrowthsListField
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileAgriGrowthsListField
     */
    name: string
    /**
     *
     * @type {GeometryPoint}
     * @memberof MobileAgriGrowthsListField
     */
    areaCenterPoint: GeometryPoint
}
/**
 *
 * @export
 * @interface MobileApiV20OperationMinistryReportGroupMemberSet
 */
export interface MobileApiV20OperationMinistryReportGroupMemberSet {
    /**
     *
     * @type {MobileApiV20OperationMinistryReportGroupMemberSetUserOrganization}
     * @memberof MobileApiV20OperationMinistryReportGroupMemberSet
     */
    userOrganization: MobileApiV20OperationMinistryReportGroupMemberSetUserOrganization
}
/**
 *
 * @export
 * @interface MobileApiV20OperationMinistryReportGroupMemberSetUserOrganization
 */
export interface MobileApiV20OperationMinistryReportGroupMemberSetUserOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileApiV20OperationMinistryReportGroupMemberSetUserOrganization
     */
    username?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV20OperationMinistryReportGroupMemberSetUserOrganization
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV20OperationMinistryReportGroupMemberSetUserOrganization
     */
    lastName?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV20OperationMinistryReportGroupMemberSetUserOrganization
     */
    parentList?: string
}
/**
 *
 * @export
 * @interface MobileApiV2AppNsAndroidKioskApkFile
 */
export interface MobileApiV2AppNsAndroidKioskApkFile {
    /**
     * ファイル
     * @type {string}
     * @memberof MobileApiV2AppNsAndroidKioskApkFile
     */
    file?: string | null
}
/**
 *
 * @export
 * @interface MobileApiV2AppNsAndroidKioskApkList
 */
export interface MobileApiV2AppNsAndroidKioskApkList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2AppNsAndroidKioskApkList
     */
    id?: string
    /**
     * ユーザーに表示するバージョン番号として使用される文字列。 e.g. 1.0.2
     * @type {string}
     * @memberof MobileApiV2AppNsAndroidKioskApkList
     */
    versionName: string
    /**
     * 内部バージョン番号として使用する整数。 e.g. 201902211
     * @type {number}
     * @memberof MobileApiV2AppNsAndroidKioskApkList
     */
    versionCode: number
    /**
     * ファイル
     * @type {string}
     * @memberof MobileApiV2AppNsAndroidKioskApkList
     */
    file?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2AppNsAndroidKioskApkList
     */
    releaseNote?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2AppNsAndroidKioskApkList
     */
    created?: string
}
/**
 *
 * @export
 * @interface MobileApiV2FarmFieldList
 */
export interface MobileApiV2FarmFieldList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldList
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldList
     */
    name: string
    /**
     * 初期値: GPS座標からジオコーディング
     * @type {string}
     * @memberof MobileApiV2FarmFieldList
     */
    address?: string
    /**
     *
     * @type {GeometryPoint}
     * @memberof MobileApiV2FarmFieldList
     */
    areaCenterPoint: GeometryPoint
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileApiV2FarmFieldList
     */
    memo?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldList
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldList
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MobileApiV2FarmFieldList
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldList
     */
    validStart: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldList
     */
    validEnd: string
    /**
     *
     * @type {Array<MobileApiV2FarmFieldListFieldSurvey>}
     * @memberof MobileApiV2FarmFieldList
     */
    fieldsurveySet: Array<MobileApiV2FarmFieldListFieldSurvey>
    /**
     *
     * @type {MobileApiV2FarmFieldListLatestFlight}
     * @memberof MobileApiV2FarmFieldList
     */
    latestFlight?: MobileApiV2FarmFieldListLatestFlight
    /**
     *
     * @type {MobileApiV2FarmFieldListFarm}
     * @memberof MobileApiV2FarmFieldList
     */
    farm: MobileApiV2FarmFieldListFarm
}
/**
 *
 * @export
 * @interface MobileApiV2FarmFieldListFarm
 */
export interface MobileApiV2FarmFieldListFarm {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFarm
     */
    id: string
    /**
     *
     * @type {MobileApiV2FarmFieldListOrganization}
     * @memberof MobileApiV2FarmFieldListFarm
     */
    organization: MobileApiV2FarmFieldListOrganization
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFarm
     */
    areaUnit?: MobileApiV2FarmFieldListFarmAreaUnitEnum
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFarm
     */
    memo?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileApiV2FarmFieldListFarmAreaUnitEnum {
    SquareMeter = 'squareMeter',
    Hectare = 'hectare',
    Cho = 'cho',
}

/**
 *
 * @export
 * @interface MobileApiV2FarmFieldListFieldSurvey
 */
export interface MobileApiV2FarmFieldListFieldSurvey {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    name?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    survey: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    content: string
    /**
     *
     * @type {GeometryPolygon}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    contentPolygon: GeometryPolygon
    /**
     * 単位: ㎡(平方メートル)。
     * @type {number}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    areaSize: number
    /**
     *
     * @type {GeometryPoint}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    droneHomePoint?: GeometryPoint | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    nileStationAppVersion?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    validStart: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    validEnd: string
    /**
     *
     * @type {Array<MobileApiV2FarmFieldListMission>}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    missionSet: Array<MobileApiV2FarmFieldListMission>
    /**
     *
     * @type {MobileApiV2FarmFieldListMissionGenerated}
     * @memberof MobileApiV2FarmFieldListFieldSurvey
     */
    missiongenerated?: MobileApiV2FarmFieldListMissionGenerated
}
/**
 * 操縦者。
 * @export
 * @interface MobileApiV2FarmFieldListFlightFlightUser
 */
export interface MobileApiV2FarmFieldListFlightFlightUser {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFlightFlightUser
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFlightFlightUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFlightFlightUser
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListFlightFlightUser
     */
    lastName?: string
}
/**
 *
 * @export
 * @interface MobileApiV2FarmFieldListLatestFlight
 */
export interface MobileApiV2FarmFieldListLatestFlight {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListLatestFlight
     */
    id: string
    /**
     * 飛行開始日時
     * @type {string}
     * @memberof MobileApiV2FarmFieldListLatestFlight
     */
    flightStarted: string
    /**
     * 飛行終了日時
     * @type {string}
     * @memberof MobileApiV2FarmFieldListLatestFlight
     */
    flightEnded?: string
    /**
     *
     * @type {MobileApiV2FarmFieldListFlightFlightUser}
     * @memberof MobileApiV2FarmFieldListLatestFlight
     */
    flightUser: MobileApiV2FarmFieldListFlightFlightUser
}
/**
 *
 * @export
 * @interface MobileApiV2FarmFieldListMission
 */
export interface MobileApiV2FarmFieldListMission {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    mission: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    content: string
    /**
     *
     * @type {number}
     * @memberof MobileApiV2FarmFieldListMission
     */
    orderNumber: number
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    generatedAppVersion?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    generatedAppParameters?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MobileApiV2FarmFieldListMission
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    validStart: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMission
     */
    validEnd: string
}
/**
 *
 * @export
 * @interface MobileApiV2FarmFieldListMissionGenerated
 */
export interface MobileApiV2FarmFieldListMissionGenerated {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMissionGenerated
     */
    id: string
    /**
     * 200: 成功,300: 失敗,301: 失敗(農地バンク)
     * @type {number}
     * @memberof MobileApiV2FarmFieldListMissionGenerated
     */
    resultCode: number
    /**
     * 生成失敗原因などを記入。ユーザーがわかりやすい日本語を入力。
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMissionGenerated
     */
    memo?: string
    /**
     * デバッグ用の情報を入力。
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMissionGenerated
     */
    internalMemo?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMissionGenerated
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListMissionGenerated
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MobileApiV2FarmFieldListMissionGenerated
     */
    version: number
}
/**
 *
 * @export
 * @interface MobileApiV2FarmFieldListOrganization
 */
export interface MobileApiV2FarmFieldListOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListOrganization
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListOrganization
     */
    code?: MobileApiV2FarmFieldListOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmFieldListOrganization
     */
    name: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileApiV2FarmFieldListOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MobileApiV2FarmList
 */
export interface MobileApiV2FarmList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmList
     */
    id?: string
    /**
     *
     * @type {MobileApiV2FarmListOrganization}
     * @memberof MobileApiV2FarmList
     */
    organization: MobileApiV2FarmListOrganization
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmList
     */
    areaUnit?: MobileApiV2FarmListAreaUnitEnum
    /**
     *
     * @type {Array<MobileApiV2FarmListField>}
     * @memberof MobileApiV2FarmList
     */
    fieldSet: Array<MobileApiV2FarmListField>
    /**
     *
     * @type {Array<MobileApiV2FarmListObstacleSurvey>}
     * @memberof MobileApiV2FarmList
     */
    obstaclesurveySet: Array<MobileApiV2FarmListObstacleSurvey>
}

/**
 * @export
 * @enum {string}
 */
export enum MobileApiV2FarmListAreaUnitEnum {
    SquareMeter = 'squareMeter',
    Hectare = 'hectare',
    Cho = 'cho',
}

/**
 *
 * @export
 * @interface MobileApiV2FarmListField
 */
export interface MobileApiV2FarmListField {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListField
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListField
     */
    name: string
    /**
     *
     * @type {GeometryPoint}
     * @memberof MobileApiV2FarmListField
     */
    areaCenterPoint: GeometryPoint
    /**
     *
     * @type {Array<MobileApiV2FarmListFieldSurvey>}
     * @memberof MobileApiV2FarmListField
     */
    fieldsurveySet: Array<MobileApiV2FarmListFieldSurvey>
    /**
     *
     * @type {MobileApiV2FarmListLatestFlight}
     * @memberof MobileApiV2FarmListField
     */
    latestFlight?: MobileApiV2FarmListLatestFlight
    /**
     * memoから改行取り除いた先頭140文字。
     * @type {string}
     * @memberof MobileApiV2FarmListField
     */
    memoSummary?: string
}
/**
 *
 * @export
 * @interface MobileApiV2FarmListFieldSurvey
 */
export interface MobileApiV2FarmListFieldSurvey {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListFieldSurvey
     */
    id?: string
    /**
     *
     * @type {GeometryPolygon}
     * @memberof MobileApiV2FarmListFieldSurvey
     */
    contentPolygon: GeometryPolygon
    /**
     *
     * @type {GeometryPoint}
     * @memberof MobileApiV2FarmListFieldSurvey
     */
    droneHomePoint?: GeometryPoint | null
    /**
     *
     * @type {Array<MobileApiV2FarmListMission>}
     * @memberof MobileApiV2FarmListFieldSurvey
     */
    missionSet: Array<MobileApiV2FarmListMission>
    /**
     * 単位: ㎡(平方メートル)。
     * @type {number}
     * @memberof MobileApiV2FarmListFieldSurvey
     */
    areaSize: number
}
/**
 * 操縦者。
 * @export
 * @interface MobileApiV2FarmListFlightFlightUser
 */
export interface MobileApiV2FarmListFlightFlightUser {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListFlightFlightUser
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListFlightFlightUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListFlightFlightUser
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListFlightFlightUser
     */
    lastName?: string
}
/**
 *
 * @export
 * @interface MobileApiV2FarmListLatestFlight
 */
export interface MobileApiV2FarmListLatestFlight {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListLatestFlight
     */
    id?: string
    /**
     * 飛行開始日時
     * @type {string}
     * @memberof MobileApiV2FarmListLatestFlight
     */
    flightStarted: string
    /**
     * 飛行終了日時
     * @type {string}
     * @memberof MobileApiV2FarmListLatestFlight
     */
    flightEnded?: string
    /**
     *
     * @type {MobileApiV2FarmListFlightFlightUser}
     * @memberof MobileApiV2FarmListLatestFlight
     */
    flightUser: MobileApiV2FarmListFlightFlightUser
}
/**
 *
 * @export
 * @interface MobileApiV2FarmListMission
 */
export interface MobileApiV2FarmListMission {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListMission
     */
    id?: string
}
/**
 *
 * @export
 * @interface MobileApiV2FarmListObstacleKind
 */
export interface MobileApiV2FarmListObstacleKind {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListObstacleKind
     */
    id?: string
    /**
     * 例 - 100: 障害物(その他), 101: フェンス, .....
     * @type {string}
     * @memberof MobileApiV2FarmListObstacleKind
     */
    code?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListObstacleKind
     */
    name: string
}
/**
 *
 * @export
 * @interface MobileApiV2FarmListObstacleSurvey
 */
export interface MobileApiV2FarmListObstacleSurvey {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListObstacleSurvey
     */
    id?: string
    /**
     *
     * @type {MobileApiV2FarmListObstacleKind}
     * @memberof MobileApiV2FarmListObstacleSurvey
     */
    obstacleKind: MobileApiV2FarmListObstacleKind
    /**
     *
     * @type {GeometryPolygon}
     * @memberof MobileApiV2FarmListObstacleSurvey
     */
    contentPolygon: GeometryPolygon
}
/**
 *
 * @export
 * @interface MobileApiV2FarmListOrganization
 */
export interface MobileApiV2FarmListOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListOrganization
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListOrganization
     */
    code?: MobileApiV2FarmListOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmListOrganization
     */
    name: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileApiV2FarmListOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MobileApiV2FarmObstacleSurveyList
 */
export interface MobileApiV2FarmObstacleSurveyList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    id: string
    /**
     *
     * @type {MobileApiV2FarmObstacleSurveyListObstacleKind}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    obstacleKind: MobileApiV2FarmObstacleSurveyListObstacleKind
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    name?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    survey: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    content: string
    /**
     *
     * @type {GeometryPolygon}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    contentPolygon: GeometryPolygon
    /**
     * 単位: ㎡(平方メートル)。
     * @type {number}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    areaSize: number
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    memo?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    nileStationAppVersion?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    created: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    modified: string
    /**
     *
     * @type {number}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    version: number
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    validStart: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    validEnd: string
    /**
     *
     * @type {MobileApiV2FarmObstacleSurveyListFarm}
     * @memberof MobileApiV2FarmObstacleSurveyList
     */
    farm: MobileApiV2FarmObstacleSurveyListFarm
}
/**
 *
 * @export
 * @interface MobileApiV2FarmObstacleSurveyListFarm
 */
export interface MobileApiV2FarmObstacleSurveyListFarm {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListFarm
     */
    id: string
    /**
     *
     * @type {MobileApiV2FarmObstacleSurveyListOrganization}
     * @memberof MobileApiV2FarmObstacleSurveyListFarm
     */
    organization: MobileApiV2FarmObstacleSurveyListOrganization
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListFarm
     */
    areaUnit?: MobileApiV2FarmObstacleSurveyListFarmAreaUnitEnum
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListFarm
     */
    memo?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileApiV2FarmObstacleSurveyListFarmAreaUnitEnum {
    SquareMeter = 'squareMeter',
    Hectare = 'hectare',
    Cho = 'cho',
}

/**
 *
 * @export
 * @interface MobileApiV2FarmObstacleSurveyListObstacleKind
 */
export interface MobileApiV2FarmObstacleSurveyListObstacleKind {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListObstacleKind
     */
    id: string
    /**
     * 例 - 100: 障害物(その他), 101: フェンス, .....
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListObstacleKind
     */
    code?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListObstacleKind
     */
    name: string
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListObstacleKind
     */
    memo?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListObstacleKind
     */
    created: string
}
/**
 *
 * @export
 * @interface MobileApiV2FarmObstacleSurveyListOrganization
 */
export interface MobileApiV2FarmObstacleSurveyListOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListOrganization
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListOrganization
     */
    code?: MobileApiV2FarmObstacleSurveyListOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileApiV2FarmObstacleSurveyListOrganization
     */
    name: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileApiV2FarmObstacleSurveyListOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MobileApiV2MachineAcFlightSummaryList
 */
export interface MobileApiV2MachineAcFlightSummaryList {
    /**
     *
     * @type {number}
     * @memberof MobileApiV2MachineAcFlightSummaryList
     */
    totalFlightTimeSeconds: number
    /**
     *
     * @type {number}
     * @memberof MobileApiV2MachineAcFlightSummaryList
     */
    totalFlightCount: number
}
/**
 *
 * @export
 * @interface MobileApiV2OperationFlightList
 */
export interface MobileApiV2OperationFlightList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    flightStarted: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    flightEnded?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    flightGroupId?: string
    /**
     *
     * @type {MobileApiV2OperationFlightListFlightUser}
     * @memberof MobileApiV2OperationFlightList
     */
    flightUser: MobileApiV2OperationFlightListFlightUser
    /**
     *
     * @type {MobileApiV2OperationFlightListMission}
     * @memberof MobileApiV2OperationFlightList
     */
    mission: MobileApiV2OperationFlightListMission
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    droneTbSerialNumber: string
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    droneBsSerialNumber: string
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    droneAcSerialNumber: string
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    droneCaSerialNumber: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    nwSerialNumber?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightList
     */
    modified?: string
}
/**
 *
 * @export
 * @interface MobileApiV2OperationFlightListFarm
 */
export interface MobileApiV2OperationFlightListFarm {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListFarm
     */
    id?: string
    /**
     *
     * @type {MobileApiV2OperationFlightListOrganization}
     * @memberof MobileApiV2OperationFlightListFarm
     */
    organization: MobileApiV2OperationFlightListOrganization
}
/**
 *
 * @export
 * @interface MobileApiV2OperationFlightListField
 */
export interface MobileApiV2OperationFlightListField {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListField
     */
    id?: string
    /**
     *
     * @type {MobileApiV2OperationFlightListFarm}
     * @memberof MobileApiV2OperationFlightListField
     */
    farm: MobileApiV2OperationFlightListFarm
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListField
     */
    name: string
}
/**
 *
 * @export
 * @interface MobileApiV2OperationFlightListFieldSurvey
 */
export interface MobileApiV2OperationFlightListFieldSurvey {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListFieldSurvey
     */
    id?: string
    /**
     *
     * @type {MobileApiV2OperationFlightListField}
     * @memberof MobileApiV2OperationFlightListFieldSurvey
     */
    field: MobileApiV2OperationFlightListField
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListFieldSurvey
     */
    name?: string
}
/**
 *
 * @export
 * @interface MobileApiV2OperationFlightListFlightUser
 */
export interface MobileApiV2OperationFlightListFlightUser {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListFlightUser
     */
    id?: string
    /**
     * 150文字以内。一般ユーザー用は、7桁の数字をシステム側で自動生成
     * @type {string}
     * @memberof MobileApiV2OperationFlightListFlightUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListFlightUser
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListFlightUser
     */
    lastName?: string
}
/**
 *
 * @export
 * @interface MobileApiV2OperationFlightListMission
 */
export interface MobileApiV2OperationFlightListMission {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListMission
     */
    id?: string
    /**
     *
     * @type {MobileApiV2OperationFlightListFieldSurvey}
     * @memberof MobileApiV2OperationFlightListMission
     */
    fieldSurvey: MobileApiV2OperationFlightListFieldSurvey
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListMission
     */
    mission: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListMission
     */
    name: string
}
/**
 *
 * @export
 * @interface MobileApiV2OperationFlightListOrganization
 */
export interface MobileApiV2OperationFlightListOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListOrganization
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationFlightListOrganization
     */
    name: string
}
/**
 *
 * @export
 * @interface MobileApiV2OperationMinistryReportList
 */
export interface MobileApiV2OperationMinistryReportList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    numbering?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    modified?: string
    /**
     *
     * @type {number}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    nwSerialNumber: number
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    memo?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    status?: MobileApiV2OperationMinistryReportListStatusEnum
    /**
     *
     * @type {number}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    applyingPeopleNum?: number | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    trained?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    submitted?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    approved?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    areaDepartment?: MobileApiV2OperationMinistryReportListAreaDepartmentEnum
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    referenceNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    touKuuUn?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    touKuuKen?: string | null
    /**
     *
     * @type {boolean}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    reported3month?: boolean
    /**
     *
     * @type {boolean}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    reported6month?: boolean
    /**
     *
     * @type {boolean}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    reported9month?: boolean
    /**
     *
     * @type {boolean}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    reported12month?: boolean
    /**
     *
     * @type {Array<MobileApiV20OperationMinistryReportGroupMemberSet>}
     * @memberof MobileApiV2OperationMinistryReportList
     */
    ministryreportgroupmemberSet: Array<MobileApiV20OperationMinistryReportGroupMemberSet>
}

/**
 * @export
 * @enum {string}
 */
export enum MobileApiV2OperationMinistryReportListStatusEnum {
    CreateStarted = 'create_started',
    CreateInProgress = 'create_in_progress',
    CreateDone = 'create_done',
    SendDone = 'send_done',
    FixDone = 'fix_done',
    Approved = 'approved',
    Obtained = 'obtained',
}
/**
 * @export
 * @enum {string}
 */
export enum MobileApiV2OperationMinistryReportListAreaDepartmentEnum {
    Tokyo = 'tokyo',
    Osaka = 'osaka',
}

/**
 *
 * @export
 * @interface MobileApiV3AppLog
 */
export interface MobileApiV3AppLog {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3AppLog
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3AppLog
     */
    appName: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3AppLog
     */
    level: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3AppLog
     */
    message: string
    /**
     *
     * @type {object}
     * @memberof MobileApiV3AppLog
     */
    detail?: object | null
    /**
     *
     * @type {string}
     * @memberof MobileApiV3AppLog
     */
    userOrganization: string
}
/**
 *
 * @export
 * @interface MobileApiV3OrganizationFarmSetList
 */
export interface MobileApiV3OrganizationFarmSetList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3OrganizationFarmSetList
     */
    id?: string
}
/**
 *
 * @export
 * @interface MobileApiV3OrganizationList
 */
export interface MobileApiV3OrganizationList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3OrganizationList
     */
    id?: string
    /**
     *
     * @type {MobileApiV3OrganizationListParent}
     * @memberof MobileApiV3OrganizationList
     */
    parent?: MobileApiV3OrganizationListParent
    /**
     *
     * @type {string}
     * @memberof MobileApiV3OrganizationList
     */
    code?: MobileApiV3OrganizationListCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileApiV3OrganizationList
     */
    name: string
    /**
     *
     * @type {Array<MobileApiV3OrganizationSetList>}
     * @memberof MobileApiV3OrganizationList
     */
    organizationSet?: Array<MobileApiV3OrganizationSetList>
    /**
     *
     * @type {Array<MobileApiV3OrganizationFarmSetList>}
     * @memberof MobileApiV3OrganizationList
     */
    farmSet?: Array<MobileApiV3OrganizationFarmSetList>
}

/**
 * @export
 * @enum {string}
 */
export enum MobileApiV3OrganizationListCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MobileApiV3OrganizationListParent
 */
export interface MobileApiV3OrganizationListParent {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3OrganizationListParent
     */
    id?: string
}
/**
 *
 * @export
 * @interface MobileApiV3OrganizationSetList
 */
export interface MobileApiV3OrganizationSetList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3OrganizationSetList
     */
    id?: string
    /**
     *
     * @type {MobileApiV3OrganizationListParent}
     * @memberof MobileApiV3OrganizationSetList
     */
    parent?: MobileApiV3OrganizationListParent
    /**
     *
     * @type {string}
     * @memberof MobileApiV3OrganizationSetList
     */
    code?: MobileApiV3OrganizationSetListCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileApiV3OrganizationSetList
     */
    name: string
    /**
     *
     * @type {Array<MobileApiV3OrganizationSetList>}
     * @memberof MobileApiV3OrganizationSetList
     */
    organizationSet: Array<MobileApiV3OrganizationSetList>
    /**
     *
     * @type {Array<MobileApiV3OrganizationFarmSetList>}
     * @memberof MobileApiV3OrganizationSetList
     */
    farmSet?: Array<MobileApiV3OrganizationFarmSetList>
}

/**
 * @export
 * @enum {string}
 */
export enum MobileApiV3OrganizationSetListCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MobileApiV3TeamList
 */
export interface MobileApiV3TeamList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamList
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamList
     */
    name: string
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileApiV3TeamList
     */
    memo?: string
    /**
     * 単位: ㎡(平方メートル)。
     * @type {number}
     * @memberof MobileApiV3TeamList
     */
    totalAreaSize: number
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamList
     */
    createdUserOrganization: string
    /**
     *
     * @type {Array<MobileApiV3TeamTeamFieldList>}
     * @memberof MobileApiV3TeamList
     */
    teamfieldSet: Array<MobileApiV3TeamTeamFieldList>
    /**
     *
     * @type {Array<MobileApiV3TeamTeamUserList>}
     * @memberof MobileApiV3TeamList
     */
    teamuserSet: Array<MobileApiV3TeamTeamUserList>
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamList
     */
    created?: string
}
/**
 *
 * @export
 * @interface MobileApiV3TeamTeamField
 */
export interface MobileApiV3TeamTeamField {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamField
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamField
     */
    name: string
    /**
     * 初期値: GPS座標からジオコーディング
     * @type {string}
     * @memberof MobileApiV3TeamTeamField
     */
    address?: string
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileApiV3TeamTeamField
     */
    memo?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamField
     */
    farm: string
}
/**
 *
 * @export
 * @interface MobileApiV3TeamTeamFieldList
 */
export interface MobileApiV3TeamTeamFieldList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamFieldList
     */
    id?: string
    /**
     *
     * @type {MobileApiV3TeamTeamField}
     * @memberof MobileApiV3TeamTeamFieldList
     */
    field?: MobileApiV3TeamTeamField
}
/**
 *
 * @export
 * @interface MobileApiV3TeamTeamFieldListUpdate
 */
export interface MobileApiV3TeamTeamFieldListUpdate {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamFieldListUpdate
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamFieldListUpdate
     */
    field: string
}
/**
 *
 * @export
 * @interface MobileApiV3TeamTeamUser
 */
export interface MobileApiV3TeamTeamUser {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamUser
     */
    id?: string
    /**
     * 150文字以内。一般ユーザー用は、7桁の数字をシステム側で自動生成
     * @type {string}
     * @memberof MobileApiV3TeamTeamUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamUser
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamUser
     */
    lastName?: string
}
/**
 *
 * @export
 * @interface MobileApiV3TeamTeamUserList
 */
export interface MobileApiV3TeamTeamUserList {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamUserList
     */
    id?: string
    /**
     *
     * @type {MobileApiV3TeamTeamUser}
     * @memberof MobileApiV3TeamTeamUserList
     */
    authUser?: MobileApiV3TeamTeamUser
}
/**
 *
 * @export
 * @interface MobileApiV3TeamTeamUserListUpdate
 */
export interface MobileApiV3TeamTeamUserListUpdate {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamUserListUpdate
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamTeamUserListUpdate
     */
    authUser: string
}
/**
 *
 * @export
 * @interface MobileApiV3TeamUpdate
 */
export interface MobileApiV3TeamUpdate {
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamUpdate
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamUpdate
     */
    name: string
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileApiV3TeamUpdate
     */
    memo?: string
    /**
     * 単位: ㎡(平方メートル)。
     * @type {number}
     * @memberof MobileApiV3TeamUpdate
     */
    totalAreaSize: number
    /**
     *
     * @type {string}
     * @memberof MobileApiV3TeamUpdate
     */
    createdUserOrganization: string
    /**
     *
     * @type {Array<MobileApiV3TeamTeamFieldListUpdate>}
     * @memberof MobileApiV3TeamUpdate
     */
    teamfieldSet?: Array<MobileApiV3TeamTeamFieldListUpdate>
    /**
     *
     * @type {Array<MobileApiV3TeamTeamUserListUpdate>}
     * @memberof MobileApiV3TeamUpdate
     */
    teamuserSet?: Array<MobileApiV3TeamTeamUserListUpdate>
}
/**
 *
 * @export
 * @interface MobileAppNsAndroidKioskApkFile
 */
export interface MobileAppNsAndroidKioskApkFile {
    /**
     * ファイル
     * @type {string}
     * @memberof MobileAppNsAndroidKioskApkFile
     */
    file?: string | null
}
/**
 *
 * @export
 * @interface MobileAppNsAndroidKioskApkList
 */
export interface MobileAppNsAndroidKioskApkList {
    /**
     *
     * @type {string}
     * @memberof MobileAppNsAndroidKioskApkList
     */
    id?: string
    /**
     * ユーザーに表示するバージョン番号として使用される文字列。 e.g. 1.0.2
     * @type {string}
     * @memberof MobileAppNsAndroidKioskApkList
     */
    versionName: string
    /**
     * 内部バージョン番号として使用する整数。 e.g. 201902211
     * @type {number}
     * @memberof MobileAppNsAndroidKioskApkList
     */
    versionCode: number
    /**
     * ファイル
     * @type {string}
     * @memberof MobileAppNsAndroidKioskApkList
     */
    file?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileAppNsAndroidKioskApkList
     */
    releaseNote?: string
    /**
     *
     * @type {string}
     * @memberof MobileAppNsAndroidKioskApkList
     */
    created?: string
}
/**
 *
 * @export
 * @interface MobileFarmList
 */
export interface MobileFarmList {
    /**
     *
     * @type {string}
     * @memberof MobileFarmList
     */
    id?: string
    /**
     *
     * @type {MobileFarmListOrganization}
     * @memberof MobileFarmList
     */
    organization: MobileFarmListOrganization
    /**
     *
     * @type {string}
     * @memberof MobileFarmList
     */
    areaUnit?: MobileFarmListAreaUnitEnum
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileFarmList
     */
    memo?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmList
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmList
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileFarmList
     */
    version?: number
    /**
     *
     * @type {string}
     * @memberof MobileFarmList
     */
    validStart: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmList
     */
    validEnd?: string
    /**
     *
     * @type {Array<MobileFarmListField>}
     * @memberof MobileFarmList
     */
    fieldSet: Array<MobileFarmListField>
    /**
     *
     * @type {Array<MobileFarmListObstacleSurvey>}
     * @memberof MobileFarmList
     */
    obstaclesurveySet: Array<MobileFarmListObstacleSurvey>
}

/**
 * @export
 * @enum {string}
 */
export enum MobileFarmListAreaUnitEnum {
    SquareMeter = 'squareMeter',
    Hectare = 'hectare',
    Cho = 'cho',
}

/**
 *
 * @export
 * @interface MobileFarmListField
 */
export interface MobileFarmListField {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListField
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListField
     */
    name: string
    /**
     * 初期値: GPS座標からジオコーディング
     * @type {string}
     * @memberof MobileFarmListField
     */
    address?: string
    /**
     *
     * @type {GeometryPoint}
     * @memberof MobileFarmListField
     */
    areaCenterPoint: GeometryPoint
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileFarmListField
     */
    memo?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListField
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListField
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileFarmListField
     */
    version?: number
    /**
     *
     * @type {string}
     * @memberof MobileFarmListField
     */
    validStart: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListField
     */
    validEnd?: string
    /**
     *
     * @type {Array<MobileFarmListFieldSurvey>}
     * @memberof MobileFarmListField
     */
    fieldsurveySet: Array<MobileFarmListFieldSurvey>
    /**
     *
     * @type {MobileFarmListLatestFlight}
     * @memberof MobileFarmListField
     */
    latestFlight?: MobileFarmListLatestFlight
}
/**
 *
 * @export
 * @interface MobileFarmListFieldSurvey
 */
export interface MobileFarmListFieldSurvey {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFieldSurvey
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFieldSurvey
     */
    name?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFieldSurvey
     */
    survey: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFieldSurvey
     */
    content: string
    /**
     *
     * @type {GeometryPolygon}
     * @memberof MobileFarmListFieldSurvey
     */
    contentPolygon: GeometryPolygon
    /**
     * 単位: ㎡(平方メートル)。
     * @type {number}
     * @memberof MobileFarmListFieldSurvey
     */
    areaSize: number
    /**
     *
     * @type {GeometryPoint}
     * @memberof MobileFarmListFieldSurvey
     */
    droneHomePoint?: GeometryPoint | null
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFieldSurvey
     */
    nileStationAppVersion?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFieldSurvey
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFieldSurvey
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileFarmListFieldSurvey
     */
    version?: number
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFieldSurvey
     */
    validStart: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFieldSurvey
     */
    validEnd?: string
    /**
     *
     * @type {Array<MobileFarmListMission>}
     * @memberof MobileFarmListFieldSurvey
     */
    missionSet: Array<MobileFarmListMission>
    /**
     *
     * @type {MobileFarmListMissionGenerated}
     * @memberof MobileFarmListFieldSurvey
     */
    missiongenerated: MobileFarmListMissionGenerated
}
/**
 * 操縦者。
 * @export
 * @interface MobileFarmListFlightFlightUser
 */
export interface MobileFarmListFlightFlightUser {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFlightFlightUser
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFlightFlightUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFlightFlightUser
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListFlightFlightUser
     */
    lastName?: string
}
/**
 *
 * @export
 * @interface MobileFarmListLatestFlight
 */
export interface MobileFarmListLatestFlight {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListLatestFlight
     */
    id?: string
    /**
     * 飛行開始日時
     * @type {string}
     * @memberof MobileFarmListLatestFlight
     */
    flightStarted: string
    /**
     * 飛行終了日時
     * @type {string}
     * @memberof MobileFarmListLatestFlight
     */
    flightEnded?: string
    /**
     *
     * @type {MobileFarmListFlightFlightUser}
     * @memberof MobileFarmListLatestFlight
     */
    flightUser: MobileFarmListFlightFlightUser
}
/**
 *
 * @export
 * @interface MobileFarmListMission
 */
export interface MobileFarmListMission {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    mission: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    content: string
    /**
     *
     * @type {number}
     * @memberof MobileFarmListMission
     */
    orderNumber?: number
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    generatedAppVersion?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    generatedAppParameters?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileFarmListMission
     */
    version?: number
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    validStart: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMission
     */
    validEnd?: string
}
/**
 *
 * @export
 * @interface MobileFarmListMissionGenerated
 */
export interface MobileFarmListMissionGenerated {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMissionGenerated
     */
    id?: string
    /**
     * 200: 成功,300: 失敗,301: 失敗(農地バンク)
     * @type {number}
     * @memberof MobileFarmListMissionGenerated
     */
    resultCode: number
    /**
     * 生成失敗原因などを記入。ユーザーがわかりやすい日本語を入力。
     * @type {string}
     * @memberof MobileFarmListMissionGenerated
     */
    memo?: string
    /**
     * デバッグ用の情報を入力。
     * @type {string}
     * @memberof MobileFarmListMissionGenerated
     */
    internalMemo?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMissionGenerated
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListMissionGenerated
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileFarmListMissionGenerated
     */
    version?: number
}
/**
 *
 * @export
 * @interface MobileFarmListObstacleKind
 */
export interface MobileFarmListObstacleKind {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleKind
     */
    id?: string
    /**
     * 例 - 100: 障害物(その他), 101: フェンス, .....
     * @type {string}
     * @memberof MobileFarmListObstacleKind
     */
    code?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleKind
     */
    name: string
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileFarmListObstacleKind
     */
    memo?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleKind
     */
    created?: string
}
/**
 *
 * @export
 * @interface MobileFarmListObstacleSurvey
 */
export interface MobileFarmListObstacleSurvey {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    id?: string
    /**
     *
     * @type {MobileFarmListObstacleKind}
     * @memberof MobileFarmListObstacleSurvey
     */
    obstacleKind: MobileFarmListObstacleKind
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    name?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    survey: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    content: string
    /**
     *
     * @type {GeometryPolygon}
     * @memberof MobileFarmListObstacleSurvey
     */
    contentPolygon: GeometryPolygon
    /**
     * 単位: ㎡(平方メートル)。
     * @type {number}
     * @memberof MobileFarmListObstacleSurvey
     */
    areaSize: number
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    memo?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    nileStationAppVersion?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileFarmListObstacleSurvey
     */
    version?: number
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    validStart: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListObstacleSurvey
     */
    validEnd?: string
}
/**
 *
 * @export
 * @interface MobileFarmListOrganization
 */
export interface MobileFarmListOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListOrganization
     */
    id?: string
    /**
     *
     * @type {MobileFarmListOrganizationParent}
     * @memberof MobileFarmListOrganization
     */
    parent: MobileFarmListOrganizationParent
    /**
     *
     * @type {string}
     * @memberof MobileFarmListOrganization
     */
    code?: MobileFarmListOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileFarmListOrganization
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmListOrganization
     */
    created?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileFarmListOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MobileFarmListOrganizationParent
 */
export interface MobileFarmListOrganizationParent {
    /**
     *
     * @type {string}
     * @memberof MobileFarmListOrganizationParent
     */
    id: string
}
/**
 *
 * @export
 * @interface MobileFarmMissionRequestCreate
 */
export interface MobileFarmMissionRequestCreate {
    /**
     *
     * @type {string}
     * @memberof MobileFarmMissionRequestCreate
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmMissionRequestCreate
     */
    requested?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmMissionRequestCreate
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileFarmMissionRequestCreate
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileFarmMissionRequestCreate
     */
    version?: number
}
/**
 *
 * @export
 * @interface MobileFieldSurveyUpdate
 */
export interface MobileFieldSurveyUpdate {
    /**
     *
     * @type {string}
     * @memberof MobileFieldSurveyUpdate
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileFieldSurveyUpdate
     */
    name?: string
    /**
     *
     * @type {string}
     * @memberof MobileFieldSurveyUpdate
     */
    survey: string
    /**
     *
     * @type {string}
     * @memberof MobileFieldSurveyUpdate
     */
    content: string
    /**
     *
     * @type {GeometryPolygon}
     * @memberof MobileFieldSurveyUpdate
     */
    contentPolygon: GeometryPolygon
    /**
     * 単位: ㎡(平方メートル)。
     * @type {number}
     * @memberof MobileFieldSurveyUpdate
     */
    areaSize: number
    /**
     *
     * @type {GeometryPoint}
     * @memberof MobileFieldSurveyUpdate
     */
    droneHomePoint?: GeometryPoint | null
    /**
     *
     * @type {string}
     * @memberof MobileFieldSurveyUpdate
     */
    nileStationAppVersion?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileFieldSurveyUpdate
     */
    version?: number
}
/**
 *
 * @export
 * @interface MobileFieldUpdate
 */
export interface MobileFieldUpdate {
    /**
     *
     * @type {string}
     * @memberof MobileFieldUpdate
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileFieldUpdate
     */
    name: string
    /**
     * 初期値: GPS座標からジオコーディング
     * @type {string}
     * @memberof MobileFieldUpdate
     */
    address?: string
    /**
     *
     * @type {GeometryPoint}
     * @memberof MobileFieldUpdate
     */
    areaCenterPoint: GeometryPoint
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileFieldUpdate
     */
    memo?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileFieldUpdate
     */
    version?: number
}
/**
 *
 * @export
 * @interface MobileObstacleSurveyUpdate
 */
export interface MobileObstacleSurveyUpdate {
    /**
     *
     * @type {string}
     * @memberof MobileObstacleSurveyUpdate
     */
    id?: string
    /**
     *
     * @type {MobileObstacleSurveyUpdateObstacleKind}
     * @memberof MobileObstacleSurveyUpdate
     */
    obstacleKind: MobileObstacleSurveyUpdateObstacleKind
    /**
     *
     * @type {string}
     * @memberof MobileObstacleSurveyUpdate
     */
    name?: string
    /**
     *
     * @type {string}
     * @memberof MobileObstacleSurveyUpdate
     */
    survey: string
    /**
     *
     * @type {string}
     * @memberof MobileObstacleSurveyUpdate
     */
    content: string
    /**
     *
     * @type {GeometryPolygon}
     * @memberof MobileObstacleSurveyUpdate
     */
    contentPolygon: GeometryPolygon
    /**
     * 単位: ㎡(平方メートル)。
     * @type {number}
     * @memberof MobileObstacleSurveyUpdate
     */
    areaSize: number
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileObstacleSurveyUpdate
     */
    memo?: string
    /**
     *
     * @type {string}
     * @memberof MobileObstacleSurveyUpdate
     */
    nileStationAppVersion?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileObstacleSurveyUpdate
     */
    version?: number
}
/**
 *
 * @export
 * @interface MobileObstacleSurveyUpdateObstacleKind
 */
export interface MobileObstacleSurveyUpdateObstacleKind {
    /**
     *
     * @type {string}
     * @memberof MobileObstacleSurveyUpdateObstacleKind
     */
    id?: string
}
/**
 *
 * @export
 * @interface MobileOperationFlightTbList
 */
export interface MobileOperationFlightTbList {
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    flightStarted: string
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    flightEnded?: string | null
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    flightGroupId?: string
    /**
     *
     * @type {MobileOperationFlightTbListFlightUser}
     * @memberof MobileOperationFlightTbList
     */
    flightUser: MobileOperationFlightTbListFlightUser
    /**
     *
     * @type {MobileOperationFlightTbListMission}
     * @memberof MobileOperationFlightTbList
     */
    mission: MobileOperationFlightTbListMission
    /**
     * e.g. TB190001
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    droneTbSerialNumber: string
    /**
     * e.g. BS190001
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    droneBsSerialNumber: string
    /**
     * e.g. AC190001
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    droneAcSerialNumber: string
    /**
     * e.g. CA190001
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    droneCaSerialNumber: string
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbList
     */
    modified?: string
}
/**
 *
 * @export
 * @interface MobileOperationFlightTbListFarm
 */
export interface MobileOperationFlightTbListFarm {
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListFarm
     */
    id?: string
    /**
     *
     * @type {MobileOperationFlightTbListOrganization}
     * @memberof MobileOperationFlightTbListFarm
     */
    organization: MobileOperationFlightTbListOrganization
}
/**
 *
 * @export
 * @interface MobileOperationFlightTbListField
 */
export interface MobileOperationFlightTbListField {
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListField
     */
    id?: string
    /**
     *
     * @type {MobileOperationFlightTbListFarm}
     * @memberof MobileOperationFlightTbListField
     */
    farm: MobileOperationFlightTbListFarm
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListField
     */
    name: string
}
/**
 *
 * @export
 * @interface MobileOperationFlightTbListFieldSurvey
 */
export interface MobileOperationFlightTbListFieldSurvey {
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListFieldSurvey
     */
    id?: string
    /**
     *
     * @type {MobileOperationFlightTbListField}
     * @memberof MobileOperationFlightTbListFieldSurvey
     */
    field: MobileOperationFlightTbListField
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListFieldSurvey
     */
    name?: string
}
/**
 *
 * @export
 * @interface MobileOperationFlightTbListFlightUser
 */
export interface MobileOperationFlightTbListFlightUser {
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListFlightUser
     */
    id?: string
    /**
     * 150文字以内。一般ユーザー用は、7桁の数字をシステム側で自動生成
     * @type {string}
     * @memberof MobileOperationFlightTbListFlightUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListFlightUser
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListFlightUser
     */
    lastName?: string
}
/**
 *
 * @export
 * @interface MobileOperationFlightTbListMission
 */
export interface MobileOperationFlightTbListMission {
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListMission
     */
    id?: string
    /**
     *
     * @type {MobileOperationFlightTbListFieldSurvey}
     * @memberof MobileOperationFlightTbListMission
     */
    fieldSurvey: MobileOperationFlightTbListFieldSurvey
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListMission
     */
    mission: string
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListMission
     */
    name: string
}
/**
 *
 * @export
 * @interface MobileOperationFlightTbListOrganization
 */
export interface MobileOperationFlightTbListOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListOrganization
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MobileOperationFlightTbListOrganization
     */
    name: string
}
/**
 *
 * @export
 * @interface MobileOrganizationCreate
 */
export interface MobileOrganizationCreate {
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationCreate
     */
    id?: string
    /**
     *
     * @type {MobileOrganizationCreateParent}
     * @memberof MobileOrganizationCreate
     */
    parent: MobileOrganizationCreateParent
    /**
     * 組織種別。選択値: farm: 農場, agent: 販売店
     * @type {string}
     * @memberof MobileOrganizationCreate
     */
    code: MobileOrganizationCreateCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationCreate
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationCreate
     */
    created?: string
    /**
     *
     * @type {Array<MobileOrganizationCreateFarm>}
     * @memberof MobileOrganizationCreate
     */
    farmSet?: Array<MobileOrganizationCreateFarm>
}

/**
 * @export
 * @enum {string}
 */
export enum MobileOrganizationCreateCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
}

/**
 *
 * @export
 * @interface MobileOrganizationCreateFarm
 */
export interface MobileOrganizationCreateFarm {
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationCreateFarm
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationCreateFarm
     */
    areaUnit?: MobileOrganizationCreateFarmAreaUnitEnum
    /**
     * 自由入力欄
     * @type {string}
     * @memberof MobileOrganizationCreateFarm
     */
    memo?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileOrganizationCreateFarmAreaUnitEnum {
    SquareMeter = 'squareMeter',
    Hectare = 'hectare',
    Cho = 'cho',
}

/**
 *
 * @export
 * @interface MobileOrganizationCreateParent
 */
export interface MobileOrganizationCreateParent {
    /**
     * 親組織の販売店IDを指定。販売店が無い場合は、株式会社ナイルワークスのID(075c6b6f-66f2-47d9-ba53-cba1e138a980)を指定。
     * @type {string}
     * @memberof MobileOrganizationCreateParent
     */
    id: string
}
/**
 *
 * @export
 * @interface MobileOrganizationList
 */
export interface MobileOrganizationList {
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationList
     */
    id?: string
    /**
     *
     * @type {MobileOrganizationListParent}
     * @memberof MobileOrganizationList
     */
    parent?: MobileOrganizationListParent
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationList
     */
    code?: MobileOrganizationListCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationList
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationList
     */
    created?: string
    /**
     *
     * @type {Array<object>}
     * @memberof MobileOrganizationList
     */
    organizationSet: Array<object>
    /**
     *
     * @type {Array<MobileOrganizationListFarm>}
     * @memberof MobileOrganizationList
     */
    farmSet: Array<MobileOrganizationListFarm>
}

/**
 * @export
 * @enum {string}
 */
export enum MobileOrganizationListCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MobileOrganizationListFarm
 */
export interface MobileOrganizationListFarm {
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationListFarm
     */
    id?: string
}
/**
 *
 * @export
 * @interface MobileOrganizationListParent
 */
export interface MobileOrganizationListParent {
    /**
     *
     * @type {string}
     * @memberof MobileOrganizationListParent
     */
    id?: string
}
/**
 *
 * @export
 * @interface MobileUserCreate
 */
export interface MobileUserCreate {
    /**
     *
     * @type {string}
     * @memberof MobileUserCreate
     */
    id?: string
    /**
     *
     * @type {MobileUserCreateOrganization}
     * @memberof MobileUserCreate
     */
    organization: MobileUserCreateOrganization
    /**
     *
     * @type {MobileUserCreateAuthUser}
     * @memberof MobileUserCreate
     */
    authUser: MobileUserCreateAuthUser
    /**
     *
     * @type {string}
     * @memberof MobileUserCreate
     */
    tel?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreate
     */
    address?: string
    /**
     *
     * @type {boolean}
     * @memberof MobileUserCreate
     */
    isControl?: boolean
    /**
     *
     * @type {boolean}
     * @memberof MobileUserCreate
     */
    isInstructor?: boolean
    /**
     *
     * @type {string}
     * @memberof MobileUserCreate
     */
    created?: string
}
/**
 *
 * @export
 * @interface MobileUserCreateAuthUser
 */
export interface MobileUserCreateAuthUser {
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateAuthUser
     */
    id?: string
    /**
     * 150文字以内。一般ユーザー用は、7桁の数字をシステム側で自動生成
     * @type {string}
     * @memberof MobileUserCreateAuthUser
     */
    username?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateAuthUser
     */
    email?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateAuthUser
     */
    password?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateAuthUser
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateAuthUser
     */
    firstNameKana?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateAuthUser
     */
    lastName?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateAuthUser
     */
    lastNameKana?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateAuthUser
     */
    lastLogin?: string
    /**
     * グループ。選択値: farm_administrator: 農場店管理者(所属する組織コードが farm の場合), nileworks_administrator: ナイルワークス管理者, nileworks_staff: ナイルワークス一般社員
     * @type {Set<string>}
     * @memberof MobileUserCreateAuthUser
     */
    groups: Set<string>
}
/**
 *
 * @export
 * @interface MobileUserCreateOrganization
 */
export interface MobileUserCreateOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateOrganization
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateOrganization
     */
    code?: MobileUserCreateOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateOrganization
     */
    name?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserCreateOrganization
     */
    created?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileUserCreateOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MobileUserList
 */
export interface MobileUserList {
    /**
     *
     * @type {string}
     * @memberof MobileUserList
     */
    id?: string
    /**
     *
     * @type {MobileUserListOrganization}
     * @memberof MobileUserList
     */
    organization: MobileUserListOrganization
    /**
     *
     * @type {MobileUserListAuthUser}
     * @memberof MobileUserList
     */
    authUser: MobileUserListAuthUser
    /**
     *
     * @type {string}
     * @memberof MobileUserList
     */
    tel?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserList
     */
    address?: string
    /**
     *
     * @type {boolean}
     * @memberof MobileUserList
     */
    isControl?: boolean
    /**
     *
     * @type {boolean}
     * @memberof MobileUserList
     */
    isInstructor?: boolean
    /**
     *
     * @type {string}
     * @memberof MobileUserList
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserList
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof MobileUserList
     */
    version?: number
}
/**
 *
 * @export
 * @interface MobileUserListAuthUser
 */
export interface MobileUserListAuthUser {
    /**
     *
     * @type {string}
     * @memberof MobileUserListAuthUser
     */
    id?: string
    /**
     * 150文字以内。一般ユーザー用は、7桁の数字をシステム側で自動生成
     * @type {string}
     * @memberof MobileUserListAuthUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof MobileUserListAuthUser
     */
    email?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserListAuthUser
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserListAuthUser
     */
    firstNameKana?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserListAuthUser
     */
    lastName?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserListAuthUser
     */
    lastNameKana?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserListAuthUser
     */
    lastLogin?: string | null
    /**
     * ユーザーがアクティブかどうかを示します。アカウントを削除する代わりに選択を解除してください。
     * @type {boolean}
     * @memberof MobileUserListAuthUser
     */
    isActive?: boolean
    /**
     *
     * @type {Set<string>}
     * @memberof MobileUserListAuthUser
     */
    groups: Set<string>
}
/**
 *
 * @export
 * @interface MobileUserListOrganization
 */
export interface MobileUserListOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileUserListOrganization
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileUserListOrganization
     */
    code?: MobileUserListOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileUserListOrganization
     */
    name?: string
    /**
     *
     * @type {string}
     * @memberof MobileUserListOrganization
     */
    created?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileUserListOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface MobileV3UserList
 */
export interface MobileV3UserList {
    /**
     *
     * @type {string}
     * @memberof MobileV3UserList
     */
    id?: string
    /**
     *
     * @type {MobileV3UserListOrganization}
     * @memberof MobileV3UserList
     */
    organization: MobileV3UserListOrganization
    /**
     *
     * @type {MobileV3UserListAuthUser}
     * @memberof MobileV3UserList
     */
    authUser: MobileV3UserListAuthUser
    /**
     *
     * @type {boolean}
     * @memberof MobileV3UserList
     */
    isControl?: boolean
    /**
     *
     * @type {boolean}
     * @memberof MobileV3UserList
     */
    isInstructor?: boolean
}
/**
 *
 * @export
 * @interface MobileV3UserListAuthUser
 */
export interface MobileV3UserListAuthUser {
    /**
     *
     * @type {string}
     * @memberof MobileV3UserListAuthUser
     */
    id?: string
    /**
     * 150文字以内。一般ユーザー用は、7桁の数字をシステム側で自動生成
     * @type {string}
     * @memberof MobileV3UserListAuthUser
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof MobileV3UserListAuthUser
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof MobileV3UserListAuthUser
     */
    firstNameKana?: string
    /**
     *
     * @type {string}
     * @memberof MobileV3UserListAuthUser
     */
    lastName?: string
    /**
     *
     * @type {string}
     * @memberof MobileV3UserListAuthUser
     */
    lastNameKana?: string
}
/**
 *
 * @export
 * @interface MobileV3UserListOrganization
 */
export interface MobileV3UserListOrganization {
    /**
     *
     * @type {string}
     * @memberof MobileV3UserListOrganization
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof MobileV3UserListOrganization
     */
    code?: MobileV3UserListOrganizationCodeEnum
    /**
     *
     * @type {string}
     * @memberof MobileV3UserListOrganization
     */
    name?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MobileV3UserListOrganizationCodeEnum {
    Farm = 'farm',
    Agent = 'agent',
    Nileworks = 'nileworks',
    CallCenter = 'call_center',
    Factory = 'factory',
}

/**
 *
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
    /**
     *
     * @type {string}
     * @memberof PasswordChange
     */
    oldPassword: string
    /**
     *
     * @type {string}
     * @memberof PasswordChange
     */
    newPassword1: string
    /**
     *
     * @type {string}
     * @memberof PasswordChange
     */
    newPassword2: string
}
/**
 *
 * @export
 * @interface PasswordChangeResponse
 */
export interface PasswordChangeResponse {
    /**
     *
     * @type {string}
     * @memberof PasswordChangeResponse
     */
    detail: string
}
/**
 *
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     *
     * @type {string}
     * @memberof Permission
     */
    codename: string
}
/**
 *
 * @export
 * @interface UserOrganization
 */
export interface UserOrganization {
    /**
     *
     * @type {string}
     * @memberof UserOrganization
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof UserOrganization
     */
    tel?: string
    /**
     *
     * @type {string}
     * @memberof UserOrganization
     */
    address?: string
    /**
     *
     * @type {boolean}
     * @memberof UserOrganization
     */
    isControl?: boolean
    /**
     *
     * @type {boolean}
     * @memberof UserOrganization
     */
    isInstructor?: boolean
    /**
     *
     * @type {string}
     * @memberof UserOrganization
     */
    created?: string
    /**
     *
     * @type {string}
     * @memberof UserOrganization
     */
    modified?: string
    /**
     * record revision number
     * @type {number}
     * @memberof UserOrganization
     */
    version?: number
}
/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     *
     * @type {boolean}
     * @memberof UserProfile
     */
    isActive: boolean
    /**
     * 150文字以内。一般ユーザー用は、7桁の数字をシステム側で自動生成
     * @type {string}
     * @memberof UserProfile
     */
    username: string
    /**
     *
     * @type {string}
     * @memberof UserProfile
     */
    email?: string
    /**
     *
     * @type {string}
     * @memberof UserProfile
     */
    firstName?: string
    /**
     *
     * @type {string}
     * @memberof UserProfile
     */
    firstNameKana?: string
    /**
     *
     * @type {string}
     * @memberof UserProfile
     */
    lastName?: string
    /**
     *
     * @type {string}
     * @memberof UserProfile
     */
    lastNameKana?: string
    /**
     *
     * @type {string}
     * @memberof UserProfile
     */
    lastLogin?: string | null
    /**
     *
     * @type {Array<UserOrganization>}
     * @memberof UserProfile
     */
    userorganizationSet?: Array<UserOrganization>
    /**
     *
     * @type {Array<Group>}
     * @memberof UserProfile
     */
    groups: Array<Group>
    /**
     *
     * @type {string}
     * @memberof UserProfile
     */
    id?: string
}

/**
 * V1Api - axios parameter creator
 * @export
 */
export const V1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @summary Calls Django Auth SetPasswordForm save method.
         * @param {PasswordChange} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountsPasswordChangeCreate: async (
            data: PasswordChange,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1AccountsPasswordChangeCreate.'
                )
            }
            const localVarPath = `/v1/accounts/password/change/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ログインユーザーの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountsProfileList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/accounts/profile/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局、機体、カメラ、測量機、タブレットと VPN HUBを紐付ける。
         * @summary アクティベーション
         * @param {JigActivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigActivationCreate: async (
            data: JigActivationCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1JigActivationCreate.'
                )
            }
            const localVarPath = `/v1/jig/activation/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体の新規シリアル番号を発番する。
         * @summary 機体 - 発番
         * @param {JigNumberingAcCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingAcCreate: async (
            data: JigNumberingAcCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1JigNumberingAcCreate.'
                )
            }
            const localVarPath = `/v1/jig/numbering/ac/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体の詳細情報を返す。
         * @summary 機体 - 詳細情報
         * @param {string} serialNumber e.g. AC190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingAcRead: async (
            serialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            if (serialNumber === null || serialNumber === undefined) {
                throw new RequiredError(
                    'serialNumber',
                    'Required parameter serialNumber was null or undefined when calling v1JigNumberingAcRead.'
                )
            }
            const localVarPath = `/v1/jig/numbering/ac/{serial_number}/`.replace(
                `{${'serial_number'}}`,
                encodeURIComponent(String(serialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局の新規シリアル番号を発番する。
         * @summary 基地局 - 発番
         * @param {JigNumberingBsCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingBsCreate: async (
            data: JigNumberingBsCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1JigNumberingBsCreate.'
                )
            }
            const localVarPath = `/v1/jig/numbering/bs/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局一覧を取得する。
         * @summary 基地局一覧の取得
         * @param {string} [ordering] \&quot;serial_number\&quot;: シリアル番号(昇順), \&quot;-serial_number\&quot;: シリアル番号(降順), \&quot;vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(昇順), \&quot;-vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationBs] アクティベーション済み基地局のデータのみ取得する場合、true
         * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingBsList: async (
            ordering?: string,
            limit?: number,
            offset?: number,
            activationBs?: boolean,
            activationTb?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/jig/numbering/bs/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (activationBs !== undefined) {
                localVarQueryParameter['activation_bs'] = activationBs
            }

            if (activationTb !== undefined) {
                localVarQueryParameter['activation_tb'] = activationTb
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局の詳細情報を返す。
         * @summary 基地局 - 詳細情報
         * @param {string} serialNumber e.g. BS190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingBsRead: async (
            serialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            if (serialNumber === null || serialNumber === undefined) {
                throw new RequiredError(
                    'serialNumber',
                    'Required parameter serialNumber was null or undefined when calling v1JigNumberingBsRead.'
                )
            }
            const localVarPath = `/v1/jig/numbering/bs/{serial_number}/`.replace(
                `{${'serial_number'}}`,
                encodeURIComponent(String(serialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * カメラの新規シリアル番号を発番する。
         * @summary カメラ - 発番
         * @param {object} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingCaCreate: async (data: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1JigNumberingCaCreate.'
                )
            }
            const localVarPath = `/v1/jig/numbering/ca/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * カメラの詳細情報を返す。
         * @summary カメラ - 詳細情報
         * @param {string} serialNumber e.g. CA190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingCaRead: async (
            serialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            if (serialNumber === null || serialNumber === undefined) {
                throw new RequiredError(
                    'serialNumber',
                    'Required parameter serialNumber was null or undefined when calling v1JigNumberingCaRead.'
                )
            }
            const localVarPath = `/v1/jig/numbering/ca/{serial_number}/`.replace(
                `{${'serial_number'}}`,
                encodeURIComponent(String(serialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 測量機の新規シリアル番号を発番する。
         * @summary 測量機 - 発番
         * @param {JigNumberingSyCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingSyCreate: async (
            data: JigNumberingSyCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1JigNumberingSyCreate.'
                )
            }
            const localVarPath = `/v1/jig/numbering/sy/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 測量機の詳細情報を返す。
         * @summary 測量機 - 詳細情報
         * @param {string} serialNumber e.g. SY190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingSyRead: async (
            serialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            if (serialNumber === null || serialNumber === undefined) {
                throw new RequiredError(
                    'serialNumber',
                    'Required parameter serialNumber was null or undefined when calling v1JigNumberingSyRead.'
                )
            }
            const localVarPath = `/v1/jig/numbering/sy/{serial_number}/`.replace(
                `{${'serial_number'}}`,
                encodeURIComponent(String(serialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * タブレットの新規シリアル番号を発番する。
         * @summary タブレット - 発番
         * @param {JigNumberingTbCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingTbCreate: async (
            data: JigNumberingTbCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1JigNumberingTbCreate.'
                )
            }
            const localVarPath = `/v1/jig/numbering/tb/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * タブレットの詳細情報を返す。
         * @summary タブレット - 詳細情報
         * @param {string} serialNumber e.g. TB190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingTbRead: async (
            serialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            if (serialNumber === null || serialNumber === undefined) {
                throw new RequiredError(
                    'serialNumber',
                    'Required parameter serialNumber was null or undefined when calling v1JigNumberingTbRead.'
                )
            }
            const localVarPath = `/v1/jig/numbering/tb/{serial_number}/`.replace(
                `{${'serial_number'}}`,
                encodeURIComponent(String(serialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * VPN一覧を取得する。
         * @summary VPN一覧の取得
         * @param {string} [kind] VPN HUB種別
         * @param {string} [ordering] \&quot;name\&quot;: GID(VPN HUB名)(昇順), \&quot;-name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigVpnsList: async (
            kind?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/jig/vpns/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * VPNを取得する。
         * @summary VPNの取得
         * @param {string} name e.g. G00001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigVpnsRead: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError(
                    'name',
                    'Required parameter name was null or undefined when calling v1JigVpnsRead.'
                )
            }
            const localVarPath = `/v1/jig/vpns/{name}/`.replace(
                `{${'name'}}`,
                encodeURIComponent(String(name))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * VPN Server一覧を取得する。
         * @summary VPN Server一覧の取得
         * @param {string} [publicIpAddress] パブリックIPアドレスの完全一致
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigVpnserversList: async (
            publicIpAddress?: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/jig/vpnservers/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (publicIpAddress !== undefined) {
                localVarQueryParameter['public_ip_address'] = publicIpAddress
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * VPN Serverを取得する。
         * @summary VPN Serverの取得
         * @param {string} id A UUID string identifying this VPN Server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigVpnserversRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1JigVpnserversRead.'
                )
            }
            const localVarPath = `/v1/jig/vpnservers/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ログインユーザーが参照できる生育一覧を取得する。
         * @summary 生育一覧の取得
         * @param {string} [farmId] 農場ID
         * @param {string} [fieldId] 圃場ID
         * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
         * @param {string} [toDatetime] 取得終端日時(iso8601形式)
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAgriGrowthsList: async (
            farmId?: string,
            fieldId?: string,
            fromDatetime?: string,
            toDatetime?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/mobile/agri/growths/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (farmId !== undefined) {
                localVarQueryParameter['farm__id'] = farmId
            }

            if (fieldId !== undefined) {
                localVarQueryParameter['field__id'] = fieldId
            }

            if (fromDatetime !== undefined) {
                localVarQueryParameter['from_datetime'] = fromDatetime
            }

            if (toDatetime !== undefined) {
                localVarQueryParameter['to_datetime'] = toDatetime
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 生育を取得する。
         * @summary 生育の取得
         * @param {string} id A UUID string identifying this 生育.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAgriGrowthsRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileAgriGrowthsRead.'
                )
            }
            const localVarPath = `/v1/mobile/agri/growths/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * NileStation for Android apk ファイルダウンロード
         * @summary NileStation for Android apk ファイルダウンロード
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAppsNsAndroidKioskApkFileRead: async (
            id: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileAppsNsAndroidKioskApkFileRead.'
                )
            }
            const localVarPath = `/v1/mobile/apps/ns/android/kiosk/apk/{id}/file/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * NileStation for Android apk 最新版ファイルダウンロード
         * @summary NileStation for Android apk 最新版ファイルダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAppsNsAndroidKioskApkLatestFileRead: async (
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/mobile/apps/ns/android/kiosk/apk/latest/file/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * NileStation for Android バージョン情報を取得する。
         * @summary NileStation for Android バージョン情報
         * @param {string} [ordering] \&quot;version_code\&quot;: バージョンコード(昇順), \&quot;-version_code\&quot;: バージョンコード(降順),
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAppsNsAndroidKioskApkList: async (
            ordering?: string,
            limit?: number,
            offset?: number,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/mobile/apps/ns/android/kiosk/apk/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * NileStation for Android バージョン情報の詳細情報を返す。
         * @summary NileStation for Android バージョン情報 - 詳細情報
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAppsNsAndroidKioskApkRead: async (
            id: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileAppsNsAndroidKioskApkRead.'
                )
            }
            const localVarPath = `/v1/mobile/apps/ns/android/kiosk/apk/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 圃場を新規登録する。
         * @summary 圃場の新規登録
         * @param {string} farmPk
         * @param {MobileFieldUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsCreate: async (
            farmPk: string,
            data: MobileFieldUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsFieldsCreate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsFieldsCreate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/fields/`.replace(
                `{${'farm_pk'}}`,
                encodeURIComponent(String(farmPk))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 圃場を無効化する。
         * @summary 圃場の無効化
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsDelete: async (
            farmPk: string,
            id: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsFieldsDelete.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsFieldsDelete.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/fields/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 圃場測量結果を新規登録する。
         * @summary 圃場測量結果の新規登録
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {MobileFieldSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsFieldsurveysCreate: async (
            farmPk: string,
            fieldPk: string,
            data: MobileFieldSurveyUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsFieldsFieldsurveysCreate.'
                )
            }
            // verify required parameter 'fieldPk' is not null or undefined
            if (fieldPk === null || fieldPk === undefined) {
                throw new RequiredError(
                    'fieldPk',
                    'Required parameter fieldPk was null or undefined when calling v1MobileFarmsFieldsFieldsurveysCreate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsFieldsFieldsurveysCreate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/fields/{field_pk}/fieldsurveys/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'field_pk'}}`, encodeURIComponent(String(fieldPk)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 圃場測量結果を無効化する。
         * @summary 圃場測量結果の無効化
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsFieldsurveysDelete: async (
            farmPk: string,
            fieldPk: string,
            id: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsFieldsFieldsurveysDelete.'
                )
            }
            // verify required parameter 'fieldPk' is not null or undefined
            if (fieldPk === null || fieldPk === undefined) {
                throw new RequiredError(
                    'fieldPk',
                    'Required parameter fieldPk was null or undefined when calling v1MobileFarmsFieldsFieldsurveysDelete.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsFieldsFieldsurveysDelete.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/fields/{field_pk}/fieldsurveys/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'field_pk'}}`, encodeURIComponent(String(fieldPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 圃場測量結果を部分更新する。
         * @summary 圃場測量結果の部分更新
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
         * @param {MobileFieldSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsFieldsurveysPartialUpdate: async (
            farmPk: string,
            fieldPk: string,
            id: string,
            data: MobileFieldSurveyUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsFieldsFieldsurveysPartialUpdate.'
                )
            }
            // verify required parameter 'fieldPk' is not null or undefined
            if (fieldPk === null || fieldPk === undefined) {
                throw new RequiredError(
                    'fieldPk',
                    'Required parameter fieldPk was null or undefined when calling v1MobileFarmsFieldsFieldsurveysPartialUpdate.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsFieldsFieldsurveysPartialUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsFieldsFieldsurveysPartialUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/fields/{field_pk}/fieldsurveys/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'field_pk'}}`, encodeURIComponent(String(fieldPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 圃場測量結果を更新する。
         * @summary 圃場測量結果の更新
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
         * @param {MobileFieldSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsFieldsurveysUpdate: async (
            farmPk: string,
            fieldPk: string,
            id: string,
            data: MobileFieldSurveyUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsFieldsFieldsurveysUpdate.'
                )
            }
            // verify required parameter 'fieldPk' is not null or undefined
            if (fieldPk === null || fieldPk === undefined) {
                throw new RequiredError(
                    'fieldPk',
                    'Required parameter fieldPk was null or undefined when calling v1MobileFarmsFieldsFieldsurveysUpdate.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsFieldsFieldsurveysUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsFieldsFieldsurveysUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/fields/{field_pk}/fieldsurveys/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'field_pk'}}`, encodeURIComponent(String(fieldPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 圃場を部分更新する。
         * @summary 圃場の部分更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {MobileFieldUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsPartialUpdate: async (
            farmPk: string,
            id: string,
            data: MobileFieldUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsFieldsPartialUpdate.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsFieldsPartialUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsFieldsPartialUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/fields/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 圃場を更新する。
         * @summary 圃場の更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {MobileFieldUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsUpdate: async (
            farmPk: string,
            id: string,
            data: MobileFieldUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsFieldsUpdate.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsFieldsUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsFieldsUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/fields/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 飛行経路生成を依頼する。
         * @summary 飛行経路生成を依頼
         * @param {string} farmPk
         * @param {MobileFarmMissionRequestCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsMissionsRequestsCreate: async (
            farmPk: string,
            data: MobileFarmMissionRequestCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsMissionsRequestsCreate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsMissionsRequestsCreate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/missions-requests/`.replace(
                `{${'farm_pk'}}`,
                encodeURIComponent(String(farmPk))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 障害物測量結果を新規登録する。
         * @summary 障害物測量結果の新規登録
         * @param {string} farmPk
         * @param {MobileObstacleSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsObstaclesurveysCreate: async (
            farmPk: string,
            data: MobileObstacleSurveyUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsObstaclesurveysCreate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsObstaclesurveysCreate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/obstaclesurveys/`.replace(
                `{${'farm_pk'}}`,
                encodeURIComponent(String(farmPk))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 障害物測量結果を無効化する。
         * @summary 障害物測量結果の無効化
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsObstaclesurveysDelete: async (
            farmPk: string,
            id: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsObstaclesurveysDelete.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsObstaclesurveysDelete.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/obstaclesurveys/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 障害物測量結果を部分更新する。
         * @summary 障害物測量結果の部分更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {MobileObstacleSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsObstaclesurveysPartialUpdate: async (
            farmPk: string,
            id: string,
            data: MobileObstacleSurveyUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsObstaclesurveysPartialUpdate.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsObstaclesurveysPartialUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsObstaclesurveysPartialUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/obstaclesurveys/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 障害物測量結果を更新する。
         * @summary 障害物測量結果の更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {MobileObstacleSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsObstaclesurveysUpdate: async (
            farmPk: string,
            id: string,
            data: MobileObstacleSurveyUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v1MobileFarmsObstaclesurveysUpdate.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsObstaclesurveysUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileFarmsObstaclesurveysUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{farm_pk}/obstaclesurveys/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 農場情報詳細を取得する。
         * @summary 農場情報詳細の取得
         * @param {string} id A UUID string identifying this 農場マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsRead: async (
            id: string,
            valid?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileFarmsRead.'
                )
            }
            const localVarPath = `/v1/mobile/farms/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (valid !== undefined) {
                localVarQueryParameter['valid'] = valid
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 飛行履歴一覧を取得する。
         * @summary 飛行履歴一覧の取得
         * @param {string} [fieldId] 圃場IDの完全一致
         * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
         * @param {string} [toDatetime] 取得終端日時(iso8601形式)
         * @param {string} [flightUsername] 飛行ユーザー名(ログインID)の完全一致
         * @param {string} [droneBsVpnUserVpnHubName] GID(VPN HUB名)の完全一致
         * @param {string} [droneTbSerialNumber] タブレットシリアル番号の完全一致
         * @param {string} [droneBsSerialNumber] 基地局シリアル番号の完全一致
         * @param {string} [droneAcSerialNumber] 機体シリアル番号の完全一致
         * @param {string} [droneCaSerialNumber] カメラシリアル番号の完全一致
         * @param {string} [ordering] \&quot;flight_started\&quot;: 飛行開始日時(昇順), \&quot;-flight_started\&quot;: 飛行開始日時(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOperationFlightsTbList: async (
            fieldId?: string,
            fromDatetime?: string,
            toDatetime?: string,
            flightUsername?: string,
            droneBsVpnUserVpnHubName?: string,
            droneTbSerialNumber?: string,
            droneBsSerialNumber?: string,
            droneAcSerialNumber?: string,
            droneCaSerialNumber?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/mobile/operation/flights/tb/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (fieldId !== undefined) {
                localVarQueryParameter['field__id'] = fieldId
            }

            if (fromDatetime !== undefined) {
                localVarQueryParameter['from_datetime'] = fromDatetime
            }

            if (toDatetime !== undefined) {
                localVarQueryParameter['to_datetime'] = toDatetime
            }

            if (flightUsername !== undefined) {
                localVarQueryParameter['flight_username'] = flightUsername
            }

            if (droneBsVpnUserVpnHubName !== undefined) {
                localVarQueryParameter['drone_bs__vpn_user__vpn_hub__name'] =
                    droneBsVpnUserVpnHubName
            }

            if (droneTbSerialNumber !== undefined) {
                localVarQueryParameter['drone_tb_serial_number'] = droneTbSerialNumber
            }

            if (droneBsSerialNumber !== undefined) {
                localVarQueryParameter['drone_bs_serial_number'] = droneBsSerialNumber
            }

            if (droneAcSerialNumber !== undefined) {
                localVarQueryParameter['drone_ac_serial_number'] = droneAcSerialNumber
            }

            if (droneCaSerialNumber !== undefined) {
                localVarQueryParameter['drone_ca_serial_number'] = droneCaSerialNumber
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 組織を新規登録する。
         * @summary 組織の新規登録
         * @param {MobileOrganizationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsCreate: async (
            data: MobileOrganizationCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileOrganizationsCreate.'
                )
            }
            const localVarPath = `/v1/mobile/organizations/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ログインユーザーが参照できる組織一覧を取得する。
         * @summary 組織一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mobile/organizations/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 組織を部分更新する。
         * @summary 組織の部分更新
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {MobileOrganizationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsPartialUpdate: async (
            id: string,
            data: MobileOrganizationCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileOrganizationsPartialUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileOrganizationsPartialUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/organizations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 組織を取得する。
         * @summary 組織の取得
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileOrganizationsRead.'
                )
            }
            const localVarPath = `/v1/mobile/organizations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 組織を更新する。
         * @summary 組織の更新
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {MobileOrganizationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsUpdate: async (
            id: string,
            data: MobileOrganizationCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileOrganizationsUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileOrganizationsUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/organizations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ユーザーを新規登録する。
         * @summary ユーザーの新規登録
         * @param {MobileUserCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersCreate: async (
            data: MobileUserCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileUsersCreate.'
                )
            }
            const localVarPath = `/v1/mobile/users/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ユーザーを無効化する。
         * @summary ユーザーの無効化
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileUsersDelete.'
                )
            }
            const localVarPath = `/v1/mobile/users/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ユーザー一覧を取得する。
         * @summary ユーザー一覧の取得
         * @param {string} [organizationId] 組織ID
         * @param {string} [ordering] \&quot;auth_user__username\&quot;: ログインID(昇順), \&quot;-auth_user__username\&quot;: ログインID(降順)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersList: async (
            organizationId?: string,
            ordering?: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/mobile/users/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (organizationId !== undefined) {
                localVarQueryParameter['organization__id'] = organizationId
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ユーザーを部分更新する。
         * @summary ユーザーの部分更新
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {MobileUserCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersPartialUpdate: async (
            id: string,
            data: MobileUserCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileUsersPartialUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileUsersPartialUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/users/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ユーザーを取得する。
         * @summary ユーザーの取得
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileUsersRead.'
                )
            }
            const localVarPath = `/v1/mobile/users/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ユーザーを更新する。
         * @summary ユーザーの更新
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {MobileUserCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersUpdate: async (
            id: string,
            data: MobileUserCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v1MobileUsersUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v1MobileUsersUpdate.'
                )
            }
            const localVarPath = `/v1/mobile/users/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * V1Api - functional programming interface
 * @export
 */
export const V1ApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @summary Calls Django Auth SetPasswordForm save method.
         * @param {PasswordChange} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AccountsPasswordChangeCreate(
            data: PasswordChange,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChangeResponse>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1AccountsPasswordChangeCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ログインユーザーの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AccountsProfileList(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1AccountsProfileList(options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局、機体、カメラ、測量機、タブレットと VPN HUBを紐付ける。
         * @summary アクティベーション
         * @param {JigActivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigActivationCreate(
            data: JigActivationCreate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigActivationCreateResponse>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigActivationCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体の新規シリアル番号を発番する。
         * @summary 機体 - 発番
         * @param {JigNumberingAcCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingAcCreate(
            data: JigNumberingAcCreate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingAcList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingAcCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体の詳細情報を返す。
         * @summary 機体 - 詳細情報
         * @param {string} serialNumber e.g. AC190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingAcRead(
            serialNumber: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingAcList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingAcRead(serialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局の新規シリアル番号を発番する。
         * @summary 基地局 - 発番
         * @param {JigNumberingBsCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingBsCreate(
            data: JigNumberingBsCreate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingBsList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingBsCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局一覧を取得する。
         * @summary 基地局一覧の取得
         * @param {string} [ordering] \&quot;serial_number\&quot;: シリアル番号(昇順), \&quot;-serial_number\&quot;: シリアル番号(降順), \&quot;vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(昇順), \&quot;-vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationBs] アクティベーション済み基地局のデータのみ取得する場合、true
         * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingBsList(
            ordering?: string,
            limit?: number,
            offset?: number,
            activationBs?: boolean,
            activationTb?: boolean,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingBsList(ordering, limit, offset, activationBs, activationTb, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局の詳細情報を返す。
         * @summary 基地局 - 詳細情報
         * @param {string} serialNumber e.g. BS190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingBsRead(
            serialNumber: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingBsList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingBsRead(serialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * カメラの新規シリアル番号を発番する。
         * @summary カメラ - 発番
         * @param {object} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingCaCreate(
            data: object,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingCaList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingCaCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * カメラの詳細情報を返す。
         * @summary カメラ - 詳細情報
         * @param {string} serialNumber e.g. CA190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingCaRead(
            serialNumber: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingCaList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingCaRead(serialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 測量機の新規シリアル番号を発番する。
         * @summary 測量機 - 発番
         * @param {JigNumberingSyCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingSyCreate(
            data: JigNumberingSyCreate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingSyList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingSyCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 測量機の詳細情報を返す。
         * @summary 測量機 - 詳細情報
         * @param {string} serialNumber e.g. SY190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingSyRead(
            serialNumber: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingSyList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingSyRead(serialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * タブレットの新規シリアル番号を発番する。
         * @summary タブレット - 発番
         * @param {JigNumberingTbCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingTbCreate(
            data: JigNumberingTbCreate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingTbList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingTbCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * タブレットの詳細情報を返す。
         * @summary タブレット - 詳細情報
         * @param {string} serialNumber e.g. TB190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigNumberingTbRead(
            serialNumber: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigNumberingTbList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigNumberingTbRead(serialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * VPN一覧を取得する。
         * @summary VPN一覧の取得
         * @param {string} [kind] VPN HUB種別
         * @param {string} [ordering] \&quot;name\&quot;: GID(VPN HUB名)(昇順), \&quot;-name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigVpnsList(
            kind?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(configuration).v1JigVpnsList(
                kind,
                ordering,
                limit,
                offset,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * VPNを取得する。
         * @summary VPNの取得
         * @param {string} name e.g. G00001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigVpnsRead(
            name: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigVpn>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(configuration).v1JigVpnsRead(
                name,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * VPN Server一覧を取得する。
         * @summary VPN Server一覧の取得
         * @param {string} [publicIpAddress] パブリックIPアドレスの完全一致
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigVpnserversList(
            publicIpAddress?: string,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JigVpnServer>>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigVpnserversList(publicIpAddress, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * VPN Serverを取得する。
         * @summary VPN Serverの取得
         * @param {string} id A UUID string identifying this VPN Server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JigVpnserversRead(
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigVpnServer>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1JigVpnserversRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ログインユーザーが参照できる生育一覧を取得する。
         * @summary 生育一覧の取得
         * @param {string} [farmId] 農場ID
         * @param {string} [fieldId] 圃場ID
         * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
         * @param {string} [toDatetime] 取得終端日時(iso8601形式)
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileAgriGrowthsList(
            farmId?: string,
            fieldId?: string,
            fromDatetime?: string,
            toDatetime?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileAgriGrowthsList(
                farmId,
                fieldId,
                fromDatetime,
                toDatetime,
                ordering,
                limit,
                offset,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 生育を取得する。
         * @summary 生育の取得
         * @param {string} id A UUID string identifying this 生育.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileAgriGrowthsRead(
            id: string,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileAgriGrowthsList>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileAgriGrowthsRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * NileStation for Android apk ファイルダウンロード
         * @summary NileStation for Android apk ファイルダウンロード
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileAppsNsAndroidKioskApkFileRead(
            id: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MobileAppNsAndroidKioskApkFile>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileAppsNsAndroidKioskApkFileRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * NileStation for Android apk 最新版ファイルダウンロード
         * @summary NileStation for Android apk 最新版ファイルダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileAppsNsAndroidKioskApkLatestFileRead(
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MobileAppNsAndroidKioskApkFile>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileAppsNsAndroidKioskApkLatestFileRead(options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * NileStation for Android バージョン情報を取得する。
         * @summary NileStation for Android バージョン情報
         * @param {string} [ordering] \&quot;version_code\&quot;: バージョンコード(昇順), \&quot;-version_code\&quot;: バージョンコード(降順),
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileAppsNsAndroidKioskApkList(
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileAppsNsAndroidKioskApkList(ordering, limit, offset, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * NileStation for Android バージョン情報の詳細情報を返す。
         * @summary NileStation for Android バージョン情報 - 詳細情報
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileAppsNsAndroidKioskApkRead(
            id: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MobileAppNsAndroidKioskApkList>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileAppsNsAndroidKioskApkRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 圃場を新規登録する。
         * @summary 圃場の新規登録
         * @param {string} farmPk
         * @param {MobileFieldUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsFieldsCreate(
            farmPk: string,
            data: MobileFieldUpdate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileFieldUpdate>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsFieldsCreate(farmPk, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 圃場を無効化する。
         * @summary 圃場の無効化
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsFieldsDelete(
            farmPk: string,
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsFieldsDelete(farmPk, id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 圃場測量結果を新規登録する。
         * @summary 圃場測量結果の新規登録
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {MobileFieldSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsFieldsFieldsurveysCreate(
            farmPk: string,
            fieldPk: string,
            data: MobileFieldSurveyUpdate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileFieldSurveyUpdate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsFieldsFieldsurveysCreate(farmPk, fieldPk, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 圃場測量結果を無効化する。
         * @summary 圃場測量結果の無効化
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsFieldsFieldsurveysDelete(
            farmPk: string,
            fieldPk: string,
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsFieldsFieldsurveysDelete(farmPk, fieldPk, id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 圃場測量結果を部分更新する。
         * @summary 圃場測量結果の部分更新
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
         * @param {MobileFieldSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsFieldsFieldsurveysPartialUpdate(
            farmPk: string,
            fieldPk: string,
            id: string,
            data: MobileFieldSurveyUpdate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileFieldSurveyUpdate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsFieldsFieldsurveysPartialUpdate(farmPk, fieldPk, id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 圃場測量結果を更新する。
         * @summary 圃場測量結果の更新
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
         * @param {MobileFieldSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsFieldsFieldsurveysUpdate(
            farmPk: string,
            fieldPk: string,
            id: string,
            data: MobileFieldSurveyUpdate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileFieldSurveyUpdate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsFieldsFieldsurveysUpdate(farmPk, fieldPk, id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 圃場を部分更新する。
         * @summary 圃場の部分更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {MobileFieldUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsFieldsPartialUpdate(
            farmPk: string,
            id: string,
            data: MobileFieldUpdate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileFieldUpdate>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsFieldsPartialUpdate(farmPk, id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 圃場を更新する。
         * @summary 圃場の更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {MobileFieldUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsFieldsUpdate(
            farmPk: string,
            id: string,
            data: MobileFieldUpdate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileFieldUpdate>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsFieldsUpdate(farmPk, id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 飛行経路生成を依頼する。
         * @summary 飛行経路生成を依頼
         * @param {string} farmPk
         * @param {MobileFarmMissionRequestCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsMissionsRequestsCreate(
            farmPk: string,
            data: MobileFarmMissionRequestCreate,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MobileFarmMissionRequestCreate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsMissionsRequestsCreate(farmPk, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 障害物測量結果を新規登録する。
         * @summary 障害物測量結果の新規登録
         * @param {string} farmPk
         * @param {MobileObstacleSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsObstaclesurveysCreate(
            farmPk: string,
            data: MobileObstacleSurveyUpdate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileObstacleSurveyUpdate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsObstaclesurveysCreate(farmPk, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 障害物測量結果を無効化する。
         * @summary 障害物測量結果の無効化
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsObstaclesurveysDelete(
            farmPk: string,
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsObstaclesurveysDelete(farmPk, id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 障害物測量結果を部分更新する。
         * @summary 障害物測量結果の部分更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {MobileObstacleSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsObstaclesurveysPartialUpdate(
            farmPk: string,
            id: string,
            data: MobileObstacleSurveyUpdate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileObstacleSurveyUpdate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsObstaclesurveysPartialUpdate(farmPk, id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 障害物測量結果を更新する。
         * @summary 障害物測量結果の更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {MobileObstacleSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsObstaclesurveysUpdate(
            farmPk: string,
            id: string,
            data: MobileObstacleSurveyUpdate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileObstacleSurveyUpdate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileFarmsObstaclesurveysUpdate(farmPk, id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 農場情報詳細を取得する。
         * @summary 農場情報詳細の取得
         * @param {string} id A UUID string identifying this 農場マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileFarmsRead(
            id: string,
            valid?: boolean,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileFarmList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(configuration).v1MobileFarmsRead(
                id,
                valid,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 飛行履歴一覧を取得する。
         * @summary 飛行履歴一覧の取得
         * @param {string} [fieldId] 圃場IDの完全一致
         * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
         * @param {string} [toDatetime] 取得終端日時(iso8601形式)
         * @param {string} [flightUsername] 飛行ユーザー名(ログインID)の完全一致
         * @param {string} [droneBsVpnUserVpnHubName] GID(VPN HUB名)の完全一致
         * @param {string} [droneTbSerialNumber] タブレットシリアル番号の完全一致
         * @param {string} [droneBsSerialNumber] 基地局シリアル番号の完全一致
         * @param {string} [droneAcSerialNumber] 機体シリアル番号の完全一致
         * @param {string} [droneCaSerialNumber] カメラシリアル番号の完全一致
         * @param {string} [ordering] \&quot;flight_started\&quot;: 飛行開始日時(昇順), \&quot;-flight_started\&quot;: 飛行開始日時(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileOperationFlightsTbList(
            fieldId?: string,
            fromDatetime?: string,
            toDatetime?: string,
            flightUsername?: string,
            droneBsVpnUserVpnHubName?: string,
            droneTbSerialNumber?: string,
            droneBsSerialNumber?: string,
            droneAcSerialNumber?: string,
            droneCaSerialNumber?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileOperationFlightsTbList(
                fieldId,
                fromDatetime,
                toDatetime,
                flightUsername,
                droneBsVpnUserVpnHubName,
                droneTbSerialNumber,
                droneBsSerialNumber,
                droneAcSerialNumber,
                droneCaSerialNumber,
                ordering,
                limit,
                offset,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 組織を新規登録する。
         * @summary 組織の新規登録
         * @param {MobileOrganizationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileOrganizationsCreate(
            data: MobileOrganizationCreate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileOrganizationCreate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileOrganizationsCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ログインユーザーが参照できる組織一覧を取得する。
         * @summary 組織一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileOrganizationsList(
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<Array<MobileOrganizationList>>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileOrganizationsList(options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 組織を部分更新する。
         * @summary 組織の部分更新
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {MobileOrganizationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileOrganizationsPartialUpdate(
            id: string,
            data: MobileOrganizationCreate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileOrganizationCreate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileOrganizationsPartialUpdate(id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 組織を取得する。
         * @summary 組織の取得
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileOrganizationsRead(
            id: string,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileOrganizationList>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileOrganizationsRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 組織を更新する。
         * @summary 組織の更新
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {MobileOrganizationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileOrganizationsUpdate(
            id: string,
            data: MobileOrganizationCreate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileOrganizationCreate>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileOrganizationsUpdate(id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ユーザーを新規登録する。
         * @summary ユーザーの新規登録
         * @param {MobileUserCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileUsersCreate(
            data: MobileUserCreate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileUserCreate>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileUsersCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ユーザーを無効化する。
         * @summary ユーザーの無効化
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileUsersDelete(
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileUsersDelete(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ユーザー一覧を取得する。
         * @summary ユーザー一覧の取得
         * @param {string} [organizationId] 組織ID
         * @param {string} [ordering] \&quot;auth_user__username\&quot;: ログインID(昇順), \&quot;-auth_user__username\&quot;: ログインID(降順)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileUsersList(
            organizationId?: string,
            ordering?: string,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MobileUserList>>
        > {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(configuration).v1MobileUsersList(
                organizationId,
                ordering,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ユーザーを部分更新する。
         * @summary ユーザーの部分更新
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {MobileUserCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileUsersPartialUpdate(
            id: string,
            data: MobileUserCreate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileUserCreate>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileUsersPartialUpdate(id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ユーザーを取得する。
         * @summary ユーザーの取得
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileUsersRead(
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileUserList>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(configuration).v1MobileUsersRead(
                id,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ユーザーを更新する。
         * @summary ユーザーの更新
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {MobileUserCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MobileUsersUpdate(
            id: string,
            data: MobileUserCreate,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileUserCreate>> {
            const localVarAxiosArgs = await V1ApiAxiosParamCreator(
                configuration
            ).v1MobileUsersUpdate(id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
    }
}

/**
 * V1Api - factory interface
 * @export
 */
export const V1ApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    return {
        /**
         * Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @summary Calls Django Auth SetPasswordForm save method.
         * @param {PasswordChange} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountsPasswordChangeCreate(
            data: PasswordChange,
            options?: any
        ): AxiosPromise<PasswordChangeResponse> {
            return V1ApiFp(configuration)
                .v1AccountsPasswordChangeCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ログインユーザーの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountsProfileList(options?: any): AxiosPromise<UserProfile> {
            return V1ApiFp(configuration)
                .v1AccountsProfileList(options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局、機体、カメラ、測量機、タブレットと VPN HUBを紐付ける。
         * @summary アクティベーション
         * @param {JigActivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigActivationCreate(
            data: JigActivationCreate,
            options?: any
        ): AxiosPromise<JigActivationCreateResponse> {
            return V1ApiFp(configuration)
                .v1JigActivationCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体の新規シリアル番号を発番する。
         * @summary 機体 - 発番
         * @param {JigNumberingAcCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingAcCreate(
            data: JigNumberingAcCreate,
            options?: any
        ): AxiosPromise<JigNumberingAcList> {
            return V1ApiFp(configuration)
                .v1JigNumberingAcCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体の詳細情報を返す。
         * @summary 機体 - 詳細情報
         * @param {string} serialNumber e.g. AC190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingAcRead(
            serialNumber: string,
            options?: any
        ): AxiosPromise<JigNumberingAcList> {
            return V1ApiFp(configuration)
                .v1JigNumberingAcRead(serialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局の新規シリアル番号を発番する。
         * @summary 基地局 - 発番
         * @param {JigNumberingBsCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingBsCreate(
            data: JigNumberingBsCreate,
            options?: any
        ): AxiosPromise<JigNumberingBsList> {
            return V1ApiFp(configuration)
                .v1JigNumberingBsCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局一覧を取得する。
         * @summary 基地局一覧の取得
         * @param {string} [ordering] \&quot;serial_number\&quot;: シリアル番号(昇順), \&quot;-serial_number\&quot;: シリアル番号(降順), \&quot;vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(昇順), \&quot;-vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationBs] アクティベーション済み基地局のデータのみ取得する場合、true
         * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingBsList(
            ordering?: string,
            limit?: number,
            offset?: number,
            activationBs?: boolean,
            activationTb?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse200> {
            return V1ApiFp(configuration)
                .v1JigNumberingBsList(ordering, limit, offset, activationBs, activationTb, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局の詳細情報を返す。
         * @summary 基地局 - 詳細情報
         * @param {string} serialNumber e.g. BS190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingBsRead(
            serialNumber: string,
            options?: any
        ): AxiosPromise<JigNumberingBsList> {
            return V1ApiFp(configuration)
                .v1JigNumberingBsRead(serialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * カメラの新規シリアル番号を発番する。
         * @summary カメラ - 発番
         * @param {object} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingCaCreate(data: object, options?: any): AxiosPromise<JigNumberingCaList> {
            return V1ApiFp(configuration)
                .v1JigNumberingCaCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * カメラの詳細情報を返す。
         * @summary カメラ - 詳細情報
         * @param {string} serialNumber e.g. CA190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingCaRead(
            serialNumber: string,
            options?: any
        ): AxiosPromise<JigNumberingCaList> {
            return V1ApiFp(configuration)
                .v1JigNumberingCaRead(serialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 測量機の新規シリアル番号を発番する。
         * @summary 測量機 - 発番
         * @param {JigNumberingSyCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingSyCreate(
            data: JigNumberingSyCreate,
            options?: any
        ): AxiosPromise<JigNumberingSyList> {
            return V1ApiFp(configuration)
                .v1JigNumberingSyCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 測量機の詳細情報を返す。
         * @summary 測量機 - 詳細情報
         * @param {string} serialNumber e.g. SY190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingSyRead(
            serialNumber: string,
            options?: any
        ): AxiosPromise<JigNumberingSyList> {
            return V1ApiFp(configuration)
                .v1JigNumberingSyRead(serialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * タブレットの新規シリアル番号を発番する。
         * @summary タブレット - 発番
         * @param {JigNumberingTbCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingTbCreate(
            data: JigNumberingTbCreate,
            options?: any
        ): AxiosPromise<JigNumberingTbList> {
            return V1ApiFp(configuration)
                .v1JigNumberingTbCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * タブレットの詳細情報を返す。
         * @summary タブレット - 詳細情報
         * @param {string} serialNumber e.g. TB190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigNumberingTbRead(
            serialNumber: string,
            options?: any
        ): AxiosPromise<JigNumberingTbList> {
            return V1ApiFp(configuration)
                .v1JigNumberingTbRead(serialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * VPN一覧を取得する。
         * @summary VPN一覧の取得
         * @param {string} [kind] VPN HUB種別
         * @param {string} [ordering] \&quot;name\&quot;: GID(VPN HUB名)(昇順), \&quot;-name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigVpnsList(
            kind?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): AxiosPromise<InlineResponse2001> {
            return V1ApiFp(configuration)
                .v1JigVpnsList(kind, ordering, limit, offset, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * VPNを取得する。
         * @summary VPNの取得
         * @param {string} name e.g. G00001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigVpnsRead(name: string, options?: any): AxiosPromise<JigVpn> {
            return V1ApiFp(configuration)
                .v1JigVpnsRead(name, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * VPN Server一覧を取得する。
         * @summary VPN Server一覧の取得
         * @param {string} [publicIpAddress] パブリックIPアドレスの完全一致
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigVpnserversList(
            publicIpAddress?: string,
            options?: any
        ): AxiosPromise<Array<JigVpnServer>> {
            return V1ApiFp(configuration)
                .v1JigVpnserversList(publicIpAddress, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * VPN Serverを取得する。
         * @summary VPN Serverの取得
         * @param {string} id A UUID string identifying this VPN Server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JigVpnserversRead(id: string, options?: any): AxiosPromise<JigVpnServer> {
            return V1ApiFp(configuration)
                .v1JigVpnserversRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ログインユーザーが参照できる生育一覧を取得する。
         * @summary 生育一覧の取得
         * @param {string} [farmId] 農場ID
         * @param {string} [fieldId] 圃場ID
         * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
         * @param {string} [toDatetime] 取得終端日時(iso8601形式)
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAgriGrowthsList(
            farmId?: string,
            fieldId?: string,
            fromDatetime?: string,
            toDatetime?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): AxiosPromise<InlineResponse2002> {
            return V1ApiFp(configuration)
                .v1MobileAgriGrowthsList(
                    farmId,
                    fieldId,
                    fromDatetime,
                    toDatetime,
                    ordering,
                    limit,
                    offset,
                    options
                )
                .then((request) => request(axios, basePath))
        },
        /**
         * 生育を取得する。
         * @summary 生育の取得
         * @param {string} id A UUID string identifying this 生育.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAgriGrowthsRead(id: string, options?: any): AxiosPromise<MobileAgriGrowthsList> {
            return V1ApiFp(configuration)
                .v1MobileAgriGrowthsRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * NileStation for Android apk ファイルダウンロード
         * @summary NileStation for Android apk ファイルダウンロード
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAppsNsAndroidKioskApkFileRead(
            id: string,
            options?: any
        ): AxiosPromise<MobileAppNsAndroidKioskApkFile> {
            return V1ApiFp(configuration)
                .v1MobileAppsNsAndroidKioskApkFileRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * NileStation for Android apk 最新版ファイルダウンロード
         * @summary NileStation for Android apk 最新版ファイルダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAppsNsAndroidKioskApkLatestFileRead(
            options?: any
        ): AxiosPromise<MobileAppNsAndroidKioskApkFile> {
            return V1ApiFp(configuration)
                .v1MobileAppsNsAndroidKioskApkLatestFileRead(options)
                .then((request) => request(axios, basePath))
        },
        /**
         * NileStation for Android バージョン情報を取得する。
         * @summary NileStation for Android バージョン情報
         * @param {string} [ordering] \&quot;version_code\&quot;: バージョンコード(昇順), \&quot;-version_code\&quot;: バージョンコード(降順),
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAppsNsAndroidKioskApkList(
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): AxiosPromise<InlineResponse2003> {
            return V1ApiFp(configuration)
                .v1MobileAppsNsAndroidKioskApkList(ordering, limit, offset, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * NileStation for Android バージョン情報の詳細情報を返す。
         * @summary NileStation for Android バージョン情報 - 詳細情報
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileAppsNsAndroidKioskApkRead(
            id: string,
            options?: any
        ): AxiosPromise<MobileAppNsAndroidKioskApkList> {
            return V1ApiFp(configuration)
                .v1MobileAppsNsAndroidKioskApkRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 圃場を新規登録する。
         * @summary 圃場の新規登録
         * @param {string} farmPk
         * @param {MobileFieldUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsCreate(
            farmPk: string,
            data: MobileFieldUpdate,
            options?: any
        ): AxiosPromise<MobileFieldUpdate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsFieldsCreate(farmPk, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 圃場を無効化する。
         * @summary 圃場の無効化
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsDelete(farmPk: string, id: string, options?: any): AxiosPromise<void> {
            return V1ApiFp(configuration)
                .v1MobileFarmsFieldsDelete(farmPk, id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 圃場測量結果を新規登録する。
         * @summary 圃場測量結果の新規登録
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {MobileFieldSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsFieldsurveysCreate(
            farmPk: string,
            fieldPk: string,
            data: MobileFieldSurveyUpdate,
            options?: any
        ): AxiosPromise<MobileFieldSurveyUpdate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsFieldsFieldsurveysCreate(farmPk, fieldPk, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 圃場測量結果を無効化する。
         * @summary 圃場測量結果の無効化
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsFieldsurveysDelete(
            farmPk: string,
            fieldPk: string,
            id: string,
            options?: any
        ): AxiosPromise<void> {
            return V1ApiFp(configuration)
                .v1MobileFarmsFieldsFieldsurveysDelete(farmPk, fieldPk, id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 圃場測量結果を部分更新する。
         * @summary 圃場測量結果の部分更新
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
         * @param {MobileFieldSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsFieldsurveysPartialUpdate(
            farmPk: string,
            fieldPk: string,
            id: string,
            data: MobileFieldSurveyUpdate,
            options?: any
        ): AxiosPromise<MobileFieldSurveyUpdate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsFieldsFieldsurveysPartialUpdate(farmPk, fieldPk, id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 圃場測量結果を更新する。
         * @summary 圃場測量結果の更新
         * @param {string} farmPk
         * @param {string} fieldPk
         * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
         * @param {MobileFieldSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsFieldsurveysUpdate(
            farmPk: string,
            fieldPk: string,
            id: string,
            data: MobileFieldSurveyUpdate,
            options?: any
        ): AxiosPromise<MobileFieldSurveyUpdate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsFieldsFieldsurveysUpdate(farmPk, fieldPk, id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 圃場を部分更新する。
         * @summary 圃場の部分更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {MobileFieldUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsPartialUpdate(
            farmPk: string,
            id: string,
            data: MobileFieldUpdate,
            options?: any
        ): AxiosPromise<MobileFieldUpdate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsFieldsPartialUpdate(farmPk, id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 圃場を更新する。
         * @summary 圃場の更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {MobileFieldUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsFieldsUpdate(
            farmPk: string,
            id: string,
            data: MobileFieldUpdate,
            options?: any
        ): AxiosPromise<MobileFieldUpdate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsFieldsUpdate(farmPk, id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 飛行経路生成を依頼する。
         * @summary 飛行経路生成を依頼
         * @param {string} farmPk
         * @param {MobileFarmMissionRequestCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsMissionsRequestsCreate(
            farmPk: string,
            data: MobileFarmMissionRequestCreate,
            options?: any
        ): AxiosPromise<MobileFarmMissionRequestCreate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsMissionsRequestsCreate(farmPk, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 障害物測量結果を新規登録する。
         * @summary 障害物測量結果の新規登録
         * @param {string} farmPk
         * @param {MobileObstacleSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsObstaclesurveysCreate(
            farmPk: string,
            data: MobileObstacleSurveyUpdate,
            options?: any
        ): AxiosPromise<MobileObstacleSurveyUpdate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsObstaclesurveysCreate(farmPk, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 障害物測量結果を無効化する。
         * @summary 障害物測量結果の無効化
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsObstaclesurveysDelete(
            farmPk: string,
            id: string,
            options?: any
        ): AxiosPromise<void> {
            return V1ApiFp(configuration)
                .v1MobileFarmsObstaclesurveysDelete(farmPk, id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 障害物測量結果を部分更新する。
         * @summary 障害物測量結果の部分更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {MobileObstacleSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsObstaclesurveysPartialUpdate(
            farmPk: string,
            id: string,
            data: MobileObstacleSurveyUpdate,
            options?: any
        ): AxiosPromise<MobileObstacleSurveyUpdate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsObstaclesurveysPartialUpdate(farmPk, id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 障害物測量結果を更新する。
         * @summary 障害物測量結果の更新
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {MobileObstacleSurveyUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsObstaclesurveysUpdate(
            farmPk: string,
            id: string,
            data: MobileObstacleSurveyUpdate,
            options?: any
        ): AxiosPromise<MobileObstacleSurveyUpdate> {
            return V1ApiFp(configuration)
                .v1MobileFarmsObstaclesurveysUpdate(farmPk, id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 農場情報詳細を取得する。
         * @summary 農場情報詳細の取得
         * @param {string} id A UUID string identifying this 農場マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileFarmsRead(
            id: string,
            valid?: boolean,
            options?: any
        ): AxiosPromise<MobileFarmList> {
            return V1ApiFp(configuration)
                .v1MobileFarmsRead(id, valid, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 飛行履歴一覧を取得する。
         * @summary 飛行履歴一覧の取得
         * @param {string} [fieldId] 圃場IDの完全一致
         * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
         * @param {string} [toDatetime] 取得終端日時(iso8601形式)
         * @param {string} [flightUsername] 飛行ユーザー名(ログインID)の完全一致
         * @param {string} [droneBsVpnUserVpnHubName] GID(VPN HUB名)の完全一致
         * @param {string} [droneTbSerialNumber] タブレットシリアル番号の完全一致
         * @param {string} [droneBsSerialNumber] 基地局シリアル番号の完全一致
         * @param {string} [droneAcSerialNumber] 機体シリアル番号の完全一致
         * @param {string} [droneCaSerialNumber] カメラシリアル番号の完全一致
         * @param {string} [ordering] \&quot;flight_started\&quot;: 飛行開始日時(昇順), \&quot;-flight_started\&quot;: 飛行開始日時(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOperationFlightsTbList(
            fieldId?: string,
            fromDatetime?: string,
            toDatetime?: string,
            flightUsername?: string,
            droneBsVpnUserVpnHubName?: string,
            droneTbSerialNumber?: string,
            droneBsSerialNumber?: string,
            droneAcSerialNumber?: string,
            droneCaSerialNumber?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): AxiosPromise<InlineResponse2004> {
            return V1ApiFp(configuration)
                .v1MobileOperationFlightsTbList(
                    fieldId,
                    fromDatetime,
                    toDatetime,
                    flightUsername,
                    droneBsVpnUserVpnHubName,
                    droneTbSerialNumber,
                    droneBsSerialNumber,
                    droneAcSerialNumber,
                    droneCaSerialNumber,
                    ordering,
                    limit,
                    offset,
                    options
                )
                .then((request) => request(axios, basePath))
        },
        /**
         * 組織を新規登録する。
         * @summary 組織の新規登録
         * @param {MobileOrganizationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsCreate(
            data: MobileOrganizationCreate,
            options?: any
        ): AxiosPromise<MobileOrganizationCreate> {
            return V1ApiFp(configuration)
                .v1MobileOrganizationsCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ログインユーザーが参照できる組織一覧を取得する。
         * @summary 組織一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsList(options?: any): AxiosPromise<Array<MobileOrganizationList>> {
            return V1ApiFp(configuration)
                .v1MobileOrganizationsList(options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 組織を部分更新する。
         * @summary 組織の部分更新
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {MobileOrganizationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsPartialUpdate(
            id: string,
            data: MobileOrganizationCreate,
            options?: any
        ): AxiosPromise<MobileOrganizationCreate> {
            return V1ApiFp(configuration)
                .v1MobileOrganizationsPartialUpdate(id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 組織を取得する。
         * @summary 組織の取得
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsRead(id: string, options?: any): AxiosPromise<MobileOrganizationList> {
            return V1ApiFp(configuration)
                .v1MobileOrganizationsRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 組織を更新する。
         * @summary 組織の更新
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {MobileOrganizationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileOrganizationsUpdate(
            id: string,
            data: MobileOrganizationCreate,
            options?: any
        ): AxiosPromise<MobileOrganizationCreate> {
            return V1ApiFp(configuration)
                .v1MobileOrganizationsUpdate(id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ユーザーを新規登録する。
         * @summary ユーザーの新規登録
         * @param {MobileUserCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersCreate(data: MobileUserCreate, options?: any): AxiosPromise<MobileUserCreate> {
            return V1ApiFp(configuration)
                .v1MobileUsersCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ユーザーを無効化する。
         * @summary ユーザーの無効化
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersDelete(id: string, options?: any): AxiosPromise<void> {
            return V1ApiFp(configuration)
                .v1MobileUsersDelete(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ユーザー一覧を取得する。
         * @summary ユーザー一覧の取得
         * @param {string} [organizationId] 組織ID
         * @param {string} [ordering] \&quot;auth_user__username\&quot;: ログインID(昇順), \&quot;-auth_user__username\&quot;: ログインID(降順)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersList(
            organizationId?: string,
            ordering?: string,
            options?: any
        ): AxiosPromise<Array<MobileUserList>> {
            return V1ApiFp(configuration)
                .v1MobileUsersList(organizationId, ordering, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ユーザーを部分更新する。
         * @summary ユーザーの部分更新
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {MobileUserCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersPartialUpdate(
            id: string,
            data: MobileUserCreate,
            options?: any
        ): AxiosPromise<MobileUserCreate> {
            return V1ApiFp(configuration)
                .v1MobileUsersPartialUpdate(id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ユーザーを取得する。
         * @summary ユーザーの取得
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersRead(id: string, options?: any): AxiosPromise<MobileUserList> {
            return V1ApiFp(configuration)
                .v1MobileUsersRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ユーザーを更新する。
         * @summary ユーザーの更新
         * @param {string} id A UUID string identifying this ユーザー組織マスタ.
         * @param {MobileUserCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MobileUsersUpdate(
            id: string,
            data: MobileUserCreate,
            options?: any
        ): AxiosPromise<MobileUserCreate> {
            return V1ApiFp(configuration)
                .v1MobileUsersUpdate(id, data, options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * V1Api - object-oriented interface
 * @export
 * @class V1Api
 * @extends {BaseAPI}
 */
export class V1Api extends BaseAPI {
    /**
     * Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @summary Calls Django Auth SetPasswordForm save method.
     * @param {PasswordChange} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1AccountsPasswordChangeCreate(data: PasswordChange, options?: any) {
        return V1ApiFp(this.configuration)
            .v1AccountsPasswordChangeCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ログインユーザーの取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1AccountsProfileList(options?: any) {
        return V1ApiFp(this.configuration)
            .v1AccountsProfileList(options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局、機体、カメラ、測量機、タブレットと VPN HUBを紐付ける。
     * @summary アクティベーション
     * @param {JigActivationCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigActivationCreate(data: JigActivationCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigActivationCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体の新規シリアル番号を発番する。
     * @summary 機体 - 発番
     * @param {JigNumberingAcCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingAcCreate(data: JigNumberingAcCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingAcCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体の詳細情報を返す。
     * @summary 機体 - 詳細情報
     * @param {string} serialNumber e.g. AC190001
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingAcRead(serialNumber: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingAcRead(serialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局の新規シリアル番号を発番する。
     * @summary 基地局 - 発番
     * @param {JigNumberingBsCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingBsCreate(data: JigNumberingBsCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingBsCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局一覧を取得する。
     * @summary 基地局一覧の取得
     * @param {string} [ordering] \&quot;serial_number\&quot;: シリアル番号(昇順), \&quot;-serial_number\&quot;: シリアル番号(降順), \&quot;vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(昇順), \&quot;-vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(降順)
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [activationBs] アクティベーション済み基地局のデータのみ取得する場合、true
     * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingBsList(
        ordering?: string,
        limit?: number,
        offset?: number,
        activationBs?: boolean,
        activationTb?: boolean,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingBsList(ordering, limit, offset, activationBs, activationTb, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局の詳細情報を返す。
     * @summary 基地局 - 詳細情報
     * @param {string} serialNumber e.g. BS190001
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingBsRead(serialNumber: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingBsRead(serialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * カメラの新規シリアル番号を発番する。
     * @summary カメラ - 発番
     * @param {object} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingCaCreate(data: object, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingCaCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * カメラの詳細情報を返す。
     * @summary カメラ - 詳細情報
     * @param {string} serialNumber e.g. CA190001
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingCaRead(serialNumber: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingCaRead(serialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 測量機の新規シリアル番号を発番する。
     * @summary 測量機 - 発番
     * @param {JigNumberingSyCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingSyCreate(data: JigNumberingSyCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingSyCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 測量機の詳細情報を返す。
     * @summary 測量機 - 詳細情報
     * @param {string} serialNumber e.g. SY190001
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingSyRead(serialNumber: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingSyRead(serialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * タブレットの新規シリアル番号を発番する。
     * @summary タブレット - 発番
     * @param {JigNumberingTbCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingTbCreate(data: JigNumberingTbCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingTbCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * タブレットの詳細情報を返す。
     * @summary タブレット - 詳細情報
     * @param {string} serialNumber e.g. TB190001
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigNumberingTbRead(serialNumber: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigNumberingTbRead(serialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * VPN一覧を取得する。
     * @summary VPN一覧の取得
     * @param {string} [kind] VPN HUB種別
     * @param {string} [ordering] \&quot;name\&quot;: GID(VPN HUB名)(昇順), \&quot;-name\&quot;: GID(VPN HUB名)(降順)
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigVpnsList(
        kind?: string,
        ordering?: string,
        limit?: number,
        offset?: number,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1JigVpnsList(kind, ordering, limit, offset, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * VPNを取得する。
     * @summary VPNの取得
     * @param {string} name e.g. G00001
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigVpnsRead(name: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigVpnsRead(name, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * VPN Server一覧を取得する。
     * @summary VPN Server一覧の取得
     * @param {string} [publicIpAddress] パブリックIPアドレスの完全一致
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigVpnserversList(publicIpAddress?: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigVpnserversList(publicIpAddress, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * VPN Serverを取得する。
     * @summary VPN Serverの取得
     * @param {string} id A UUID string identifying this VPN Server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1JigVpnserversRead(id: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1JigVpnserversRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ログインユーザーが参照できる生育一覧を取得する。
     * @summary 生育一覧の取得
     * @param {string} [farmId] 農場ID
     * @param {string} [fieldId] 圃場ID
     * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
     * @param {string} [toDatetime] 取得終端日時(iso8601形式)
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileAgriGrowthsList(
        farmId?: string,
        fieldId?: string,
        fromDatetime?: string,
        toDatetime?: string,
        ordering?: string,
        limit?: number,
        offset?: number,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileAgriGrowthsList(
                farmId,
                fieldId,
                fromDatetime,
                toDatetime,
                ordering,
                limit,
                offset,
                options
            )
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 生育を取得する。
     * @summary 生育の取得
     * @param {string} id A UUID string identifying this 生育.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileAgriGrowthsRead(id: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileAgriGrowthsRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * NileStation for Android apk ファイルダウンロード
     * @summary NileStation for Android apk ファイルダウンロード
     * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileAppsNsAndroidKioskApkFileRead(id: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileAppsNsAndroidKioskApkFileRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * NileStation for Android apk 最新版ファイルダウンロード
     * @summary NileStation for Android apk 最新版ファイルダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileAppsNsAndroidKioskApkLatestFileRead(options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileAppsNsAndroidKioskApkLatestFileRead(options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * NileStation for Android バージョン情報を取得する。
     * @summary NileStation for Android バージョン情報
     * @param {string} [ordering] \&quot;version_code\&quot;: バージョンコード(昇順), \&quot;-version_code\&quot;: バージョンコード(降順),
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileAppsNsAndroidKioskApkList(
        ordering?: string,
        limit?: number,
        offset?: number,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileAppsNsAndroidKioskApkList(ordering, limit, offset, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * NileStation for Android バージョン情報の詳細情報を返す。
     * @summary NileStation for Android バージョン情報 - 詳細情報
     * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileAppsNsAndroidKioskApkRead(id: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileAppsNsAndroidKioskApkRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 圃場を新規登録する。
     * @summary 圃場の新規登録
     * @param {string} farmPk
     * @param {MobileFieldUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsFieldsCreate(farmPk: string, data: MobileFieldUpdate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsFieldsCreate(farmPk, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 圃場を無効化する。
     * @summary 圃場の無効化
     * @param {string} farmPk
     * @param {string} id A UUID string identifying this 圃場マスタ.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsFieldsDelete(farmPk: string, id: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsFieldsDelete(farmPk, id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 圃場測量結果を新規登録する。
     * @summary 圃場測量結果の新規登録
     * @param {string} farmPk
     * @param {string} fieldPk
     * @param {MobileFieldSurveyUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsFieldsFieldsurveysCreate(
        farmPk: string,
        fieldPk: string,
        data: MobileFieldSurveyUpdate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsFieldsFieldsurveysCreate(farmPk, fieldPk, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 圃場測量結果を無効化する。
     * @summary 圃場測量結果の無効化
     * @param {string} farmPk
     * @param {string} fieldPk
     * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsFieldsFieldsurveysDelete(
        farmPk: string,
        fieldPk: string,
        id: string,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsFieldsFieldsurveysDelete(farmPk, fieldPk, id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 圃場測量結果を部分更新する。
     * @summary 圃場測量結果の部分更新
     * @param {string} farmPk
     * @param {string} fieldPk
     * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
     * @param {MobileFieldSurveyUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsFieldsFieldsurveysPartialUpdate(
        farmPk: string,
        fieldPk: string,
        id: string,
        data: MobileFieldSurveyUpdate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsFieldsFieldsurveysPartialUpdate(farmPk, fieldPk, id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 圃場測量結果を更新する。
     * @summary 圃場測量結果の更新
     * @param {string} farmPk
     * @param {string} fieldPk
     * @param {string} id A UUID string identifying this 圃場測量結果マスタ.
     * @param {MobileFieldSurveyUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsFieldsFieldsurveysUpdate(
        farmPk: string,
        fieldPk: string,
        id: string,
        data: MobileFieldSurveyUpdate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsFieldsFieldsurveysUpdate(farmPk, fieldPk, id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 圃場を部分更新する。
     * @summary 圃場の部分更新
     * @param {string} farmPk
     * @param {string} id A UUID string identifying this 圃場マスタ.
     * @param {MobileFieldUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsFieldsPartialUpdate(
        farmPk: string,
        id: string,
        data: MobileFieldUpdate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsFieldsPartialUpdate(farmPk, id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 圃場を更新する。
     * @summary 圃場の更新
     * @param {string} farmPk
     * @param {string} id A UUID string identifying this 圃場マスタ.
     * @param {MobileFieldUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsFieldsUpdate(
        farmPk: string,
        id: string,
        data: MobileFieldUpdate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsFieldsUpdate(farmPk, id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 飛行経路生成を依頼する。
     * @summary 飛行経路生成を依頼
     * @param {string} farmPk
     * @param {MobileFarmMissionRequestCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsMissionsRequestsCreate(
        farmPk: string,
        data: MobileFarmMissionRequestCreate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsMissionsRequestsCreate(farmPk, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 障害物測量結果を新規登録する。
     * @summary 障害物測量結果の新規登録
     * @param {string} farmPk
     * @param {MobileObstacleSurveyUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsObstaclesurveysCreate(
        farmPk: string,
        data: MobileObstacleSurveyUpdate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsObstaclesurveysCreate(farmPk, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 障害物測量結果を無効化する。
     * @summary 障害物測量結果の無効化
     * @param {string} farmPk
     * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsObstaclesurveysDelete(farmPk: string, id: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsObstaclesurveysDelete(farmPk, id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 障害物測量結果を部分更新する。
     * @summary 障害物測量結果の部分更新
     * @param {string} farmPk
     * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
     * @param {MobileObstacleSurveyUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsObstaclesurveysPartialUpdate(
        farmPk: string,
        id: string,
        data: MobileObstacleSurveyUpdate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsObstaclesurveysPartialUpdate(farmPk, id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 障害物測量結果を更新する。
     * @summary 障害物測量結果の更新
     * @param {string} farmPk
     * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
     * @param {MobileObstacleSurveyUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsObstaclesurveysUpdate(
        farmPk: string,
        id: string,
        data: MobileObstacleSurveyUpdate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsObstaclesurveysUpdate(farmPk, id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 農場情報詳細を取得する。
     * @summary 農場情報詳細の取得
     * @param {string} id A UUID string identifying this 農場マスタ.
     * @param {boolean} [valid] 有効なデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileFarmsRead(id: string, valid?: boolean, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileFarmsRead(id, valid, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 飛行履歴一覧を取得する。
     * @summary 飛行履歴一覧の取得
     * @param {string} [fieldId] 圃場IDの完全一致
     * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
     * @param {string} [toDatetime] 取得終端日時(iso8601形式)
     * @param {string} [flightUsername] 飛行ユーザー名(ログインID)の完全一致
     * @param {string} [droneBsVpnUserVpnHubName] GID(VPN HUB名)の完全一致
     * @param {string} [droneTbSerialNumber] タブレットシリアル番号の完全一致
     * @param {string} [droneBsSerialNumber] 基地局シリアル番号の完全一致
     * @param {string} [droneAcSerialNumber] 機体シリアル番号の完全一致
     * @param {string} [droneCaSerialNumber] カメラシリアル番号の完全一致
     * @param {string} [ordering] \&quot;flight_started\&quot;: 飛行開始日時(昇順), \&quot;-flight_started\&quot;: 飛行開始日時(降順)
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileOperationFlightsTbList(
        fieldId?: string,
        fromDatetime?: string,
        toDatetime?: string,
        flightUsername?: string,
        droneBsVpnUserVpnHubName?: string,
        droneTbSerialNumber?: string,
        droneBsSerialNumber?: string,
        droneAcSerialNumber?: string,
        droneCaSerialNumber?: string,
        ordering?: string,
        limit?: number,
        offset?: number,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileOperationFlightsTbList(
                fieldId,
                fromDatetime,
                toDatetime,
                flightUsername,
                droneBsVpnUserVpnHubName,
                droneTbSerialNumber,
                droneBsSerialNumber,
                droneAcSerialNumber,
                droneCaSerialNumber,
                ordering,
                limit,
                offset,
                options
            )
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 組織を新規登録する。
     * @summary 組織の新規登録
     * @param {MobileOrganizationCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileOrganizationsCreate(data: MobileOrganizationCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileOrganizationsCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ログインユーザーが参照できる組織一覧を取得する。
     * @summary 組織一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileOrganizationsList(options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileOrganizationsList(options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 組織を部分更新する。
     * @summary 組織の部分更新
     * @param {string} id A UUID string identifying this 組織マスタ.
     * @param {MobileOrganizationCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileOrganizationsPartialUpdate(
        id: string,
        data: MobileOrganizationCreate,
        options?: any
    ) {
        return V1ApiFp(this.configuration)
            .v1MobileOrganizationsPartialUpdate(id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 組織を取得する。
     * @summary 組織の取得
     * @param {string} id A UUID string identifying this 組織マスタ.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileOrganizationsRead(id: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileOrganizationsRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 組織を更新する。
     * @summary 組織の更新
     * @param {string} id A UUID string identifying this 組織マスタ.
     * @param {MobileOrganizationCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileOrganizationsUpdate(id: string, data: MobileOrganizationCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileOrganizationsUpdate(id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ユーザーを新規登録する。
     * @summary ユーザーの新規登録
     * @param {MobileUserCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileUsersCreate(data: MobileUserCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileUsersCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ユーザーを無効化する。
     * @summary ユーザーの無効化
     * @param {string} id A UUID string identifying this ユーザー組織マスタ.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileUsersDelete(id: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileUsersDelete(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ユーザー一覧を取得する。
     * @summary ユーザー一覧の取得
     * @param {string} [organizationId] 組織ID
     * @param {string} [ordering] \&quot;auth_user__username\&quot;: ログインID(昇順), \&quot;-auth_user__username\&quot;: ログインID(降順)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileUsersList(organizationId?: string, ordering?: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileUsersList(organizationId, ordering, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ユーザーを部分更新する。
     * @summary ユーザーの部分更新
     * @param {string} id A UUID string identifying this ユーザー組織マスタ.
     * @param {MobileUserCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileUsersPartialUpdate(id: string, data: MobileUserCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileUsersPartialUpdate(id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ユーザーを取得する。
     * @summary ユーザーの取得
     * @param {string} id A UUID string identifying this ユーザー組織マスタ.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileUsersRead(id: string, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileUsersRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ユーザーを更新する。
     * @summary ユーザーの更新
     * @param {string} id A UUID string identifying this ユーザー組織マスタ.
     * @param {MobileUserCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public v1MobileUsersUpdate(id: string, data: MobileUserCreate, options?: any) {
        return V1ApiFp(this.configuration)
            .v1MobileUsersUpdate(id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * V2Api - axios parameter creator
 * @export
 */
export const V2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 有効な全ての圃場の位置情報を三次メッシュコード(1kmメッシュ)の形式に変換してリストで返す。
         * @summary 圃場の三次メッシュコードへの変換
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AgriFieldstomeshesList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/agri/fieldstomeshes/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局、機体、カメラ、測量機、タブレットと VPN HUBを紐付ける。
         * @summary アクティベーション
         * @param {JigApiV2ActivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigActivationCreate: async (
            data: JigApiV2ActivationCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2JigActivationCreate.'
                )
            }
            const localVarPath = `/v2/jig/activation/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局、機体、カメラ、測量機、タブレットと VPN HUBのアクティベーションを解除する。
         * @summary アクティベーション解除
         * @param {JigApiV2DeactivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigDeactivationCreate: async (
            data: JigApiV2DeactivationCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2JigDeactivationCreate.'
                )
            }
            const localVarPath = `/v2/jig/deactivation/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 組織一覧を取得する。
         * @summary 組織一覧の取得
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigFarmsOrganizationsList: async (
            ordering?: string,
            limit?: number,
            offset?: number,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/jig/farms/organizations/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 組織を取得する。
         * @summary 組織の取得
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigFarmsOrganizationsRead: async (
            id: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v2JigFarmsOrganizationsRead.'
                )
            }
            const localVarPath = `/v2/jig/farms/organizations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体の新規シリアル番号を発番する。
         * @summary 機体 - 発番
         * @param {JigApiV2NumberingAcCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingAcCreate: async (
            data: JigApiV2NumberingAcCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2JigNumberingAcCreate.'
                )
            }
            const localVarPath = `/v2/jig/numbering/ac/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体一覧を取得する。
         * @summary 機体一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationAc] アクティベーション済み機体のデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingAcList: async (
            limit?: number,
            offset?: number,
            activationAc?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/jig/numbering/ac/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (activationAc !== undefined) {
                localVarQueryParameter['activation_ac'] = activationAc
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体の詳細情報を返す。
         * @summary 機体 - 詳細情報
         * @param {string} droneAcSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingAcRead: async (
            droneAcSerialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'droneAcSerialNumber' is not null or undefined
            if (droneAcSerialNumber === null || droneAcSerialNumber === undefined) {
                throw new RequiredError(
                    'droneAcSerialNumber',
                    'Required parameter droneAcSerialNumber was null or undefined when calling v2JigNumberingAcRead.'
                )
            }
            const localVarPath = `/v2/jig/numbering/ac/{drone_ac__serial_number}/`.replace(
                `{${'drone_ac__serial_number'}}`,
                encodeURIComponent(String(droneAcSerialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局の新規シリアル番号を発番する。
         * @summary 基地局 - 発番
         * @param {JigApiV2NumberingBsCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingBsCreate: async (
            data: JigApiV2NumberingBsCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2JigNumberingBsCreate.'
                )
            }
            const localVarPath = `/v2/jig/numbering/bs/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局一覧を取得する。
         * @summary 基地局一覧の取得
         * @param {string} [ordering] \&quot;serial_number\&quot;: シリアル番号(昇順), \&quot;-serial_number\&quot;: シリアル番号(降順), \&quot;vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(昇順), \&quot;-vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationBs] アクティベーション済み基地局のデータのみ取得する場合、true
         * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingBsList: async (
            ordering?: string,
            limit?: number,
            offset?: number,
            activationBs?: boolean,
            activationTb?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/jig/numbering/bs/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (activationBs !== undefined) {
                localVarQueryParameter['activation_bs'] = activationBs
            }

            if (activationTb !== undefined) {
                localVarQueryParameter['activation_tb'] = activationTb
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局の詳細情報を返す。
         * @summary 基地局 - 詳細情報
         * @param {string} droneBsSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingBsRead: async (
            droneBsSerialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'droneBsSerialNumber' is not null or undefined
            if (droneBsSerialNumber === null || droneBsSerialNumber === undefined) {
                throw new RequiredError(
                    'droneBsSerialNumber',
                    'Required parameter droneBsSerialNumber was null or undefined when calling v2JigNumberingBsRead.'
                )
            }
            const localVarPath = `/v2/jig/numbering/bs/{drone_bs__serial_number}/`.replace(
                `{${'drone_bs__serial_number'}}`,
                encodeURIComponent(String(droneBsSerialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * カメラの新規シリアル番号を発番する。
         * @summary カメラ - 発番
         * @param {JigApiV2NumberingCaCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingCaCreate: async (
            data: JigApiV2NumberingCaCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2JigNumberingCaCreate.'
                )
            }
            const localVarPath = `/v2/jig/numbering/ca/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * カメラ一覧を取得する。
         * @summary カメラ一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationCa] アクティベーション済みカメラのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingCaList: async (
            limit?: number,
            offset?: number,
            activationCa?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/jig/numbering/ca/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (activationCa !== undefined) {
                localVarQueryParameter['activation_ca'] = activationCa
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * カメラの詳細情報を返す。
         * @summary カメラ - 詳細情報
         * @param {string} droneCaSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingCaRead: async (
            droneCaSerialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'droneCaSerialNumber' is not null or undefined
            if (droneCaSerialNumber === null || droneCaSerialNumber === undefined) {
                throw new RequiredError(
                    'droneCaSerialNumber',
                    'Required parameter droneCaSerialNumber was null or undefined when calling v2JigNumberingCaRead.'
                )
            }
            const localVarPath = `/v2/jig/numbering/ca/{drone_ca__serial_number}/`.replace(
                `{${'drone_ca__serial_number'}}`,
                encodeURIComponent(String(droneCaSerialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 測量機の新規シリアル番号を発番する。
         * @summary 測量機 - 発番
         * @param {JigApiV2NumberingSyCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingSyCreate: async (
            data: JigApiV2NumberingSyCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2JigNumberingSyCreate.'
                )
            }
            const localVarPath = `/v2/jig/numbering/sy/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 測量機一覧を取得する。
         * @summary 測量機一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationSy] アクティベーション済み測量機のデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingSyList: async (
            limit?: number,
            offset?: number,
            activationSy?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/jig/numbering/sy/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (activationSy !== undefined) {
                localVarQueryParameter['activation_sy'] = activationSy
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 測量機の詳細情報を返す。
         * @summary 測量機 - 詳細情報
         * @param {string} droneSySerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingSyRead: async (
            droneSySerialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'droneSySerialNumber' is not null or undefined
            if (droneSySerialNumber === null || droneSySerialNumber === undefined) {
                throw new RequiredError(
                    'droneSySerialNumber',
                    'Required parameter droneSySerialNumber was null or undefined when calling v2JigNumberingSyRead.'
                )
            }
            const localVarPath = `/v2/jig/numbering/sy/{drone_sy__serial_number}/`.replace(
                `{${'drone_sy__serial_number'}}`,
                encodeURIComponent(String(droneSySerialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * タブレットの新規シリアル番号を発番する。
         * @summary タブレット - 発番
         * @param {JigApiV2NumberingTbCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingTbCreate: async (
            data: JigApiV2NumberingTbCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2JigNumberingTbCreate.'
                )
            }
            const localVarPath = `/v2/jig/numbering/tb/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * タブレット一覧を取得する。
         * @summary タブレット一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingTbList: async (
            limit?: number,
            offset?: number,
            activationTb?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/jig/numbering/tb/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (activationTb !== undefined) {
                localVarQueryParameter['activation_tb'] = activationTb
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * タブレットの詳細情報を返す。
         * @summary タブレット - 詳細情報
         * @param {string} droneTbSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingTbRead: async (
            droneTbSerialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'droneTbSerialNumber' is not null or undefined
            if (droneTbSerialNumber === null || droneTbSerialNumber === undefined) {
                throw new RequiredError(
                    'droneTbSerialNumber',
                    'Required parameter droneTbSerialNumber was null or undefined when calling v2JigNumberingTbRead.'
                )
            }
            const localVarPath = `/v2/jig/numbering/tb/{drone_tb__serial_number}/`.replace(
                `{${'drone_tb__serial_number'}}`,
                encodeURIComponent(String(droneTbSerialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * VPN一覧を取得する。
         * @summary VPN一覧の取得
         * @param {string} [kind] VPN HUB種別
         * @param {string} [ordering] \&quot;name\&quot;: GID(VPN HUB名)(昇順), \&quot;-name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigVpnsList: async (
            kind?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/jig/vpns/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * VPNを取得する。
         * @summary VPNの取得
         * @param {string} name e.g. G00001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigVpnsRead: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError(
                    'name',
                    'Required parameter name was null or undefined when calling v2JigVpnsRead.'
                )
            }
            const localVarPath = `/v2/jig/vpns/{name}/`.replace(
                `{${'name'}}`,
                encodeURIComponent(String(name))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体の接続を登録し接続状態を更新する。
         * @summary 機体接続情報登録
         * @param {MachineApiV2ConnectionAcCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionAcCreate: async (
            data: MachineApiV2ConnectionAcCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineConnectionAcCreate.'
                )
            }
            const localVarPath = `/v2/machine/connection/ac/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体接続情報一覧を取得する。
         * @summary 機体接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionAcList: async (
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/machine/connection/ac/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (connecting !== undefined) {
                localVarQueryParameter['connecting'] = connecting
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体接続情報を取得する。
         * @summary 機体接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionAcRead: async (
            imei: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'imei' is not null or undefined
            if (imei === null || imei === undefined) {
                throw new RequiredError(
                    'imei',
                    'Required parameter imei was null or undefined when calling v2MachineConnectionAcRead.'
                )
            }
            const localVarPath = `/v2/machine/connection/ac/{imei}/`.replace(
                `{${'imei'}}`,
                encodeURIComponent(String(imei))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局の接続を登録し接続状態を更新する。
         * @summary 基地局接続情報登録
         * @param {MachineApiV2ConnectionBsCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionBsCreate: async (
            data: MachineApiV2ConnectionBsCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineConnectionBsCreate.'
                )
            }
            const localVarPath = `/v2/machine/connection/bs/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局接続情報一覧を取得する。
         * @summary 基地局接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionBsList: async (
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/machine/connection/bs/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (connecting !== undefined) {
                localVarQueryParameter['connecting'] = connecting
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局接続情報を取得する。
         * @summary 基地局接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionBsRead: async (
            imei: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'imei' is not null or undefined
            if (imei === null || imei === undefined) {
                throw new RequiredError(
                    'imei',
                    'Required parameter imei was null or undefined when calling v2MachineConnectionBsRead.'
                )
            }
            const localVarPath = `/v2/machine/connection/bs/{imei}/`.replace(
                `{${'imei'}}`,
                encodeURIComponent(String(imei))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * カメラの接続を登録し接続状態を更新する。
         * @summary カメラ接続情報登録
         * @param {MachineApiV2ConnectionCaCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionCaCreate: async (
            data: MachineApiV2ConnectionCaCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineConnectionCaCreate.'
                )
            }
            const localVarPath = `/v2/machine/connection/ca/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * カメラ接続情報一覧を取得する。
         * @summary カメラ接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionCaList: async (
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/machine/connection/ca/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (connecting !== undefined) {
                localVarQueryParameter['connecting'] = connecting
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * カメラ接続情報を取得する。
         * @summary カメラ接続情報の取得
         * @param {string} code カメラの識別子。T20から追加。カメラ毎にユニークだが、T19カメラコード未登録のため、unique&#x3D;Falseにしている。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionCaRead: async (
            code: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError(
                    'code',
                    'Required parameter code was null or undefined when calling v2MachineConnectionCaRead.'
                )
            }
            const localVarPath = `/v2/machine/connection/ca/{code}/`.replace(
                `{${'code'}}`,
                encodeURIComponent(String(code))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 測量機の接続を登録し接続状態を更新する。
         * @summary 測量機接続情報登録
         * @param {MachineApiV2ConnectionSyCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionSyCreate: async (
            data: MachineApiV2ConnectionSyCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineConnectionSyCreate.'
                )
            }
            const localVarPath = `/v2/machine/connection/sy/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 測量機接続情報一覧を取得する。
         * @summary 測量機接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionSyList: async (
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/machine/connection/sy/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (connecting !== undefined) {
                localVarQueryParameter['connecting'] = connecting
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 測量機接続情報を取得する。
         * @summary 測量機接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionSyRead: async (
            imei: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'imei' is not null or undefined
            if (imei === null || imei === undefined) {
                throw new RequiredError(
                    'imei',
                    'Required parameter imei was null or undefined when calling v2MachineConnectionSyRead.'
                )
            }
            const localVarPath = `/v2/machine/connection/sy/{imei}/`.replace(
                `{${'imei'}}`,
                encodeURIComponent(String(imei))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * タブレットの接続を登録し接続状態を更新する。
         * @summary タブレット接続情報登録
         * @param {MachineApiV2ConnectionTbCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionTbCreate: async (
            data: MachineApiV2ConnectionTbCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineConnectionTbCreate.'
                )
            }
            const localVarPath = `/v2/machine/connection/tb/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * タブレット接続情報一覧を取得する。
         * @summary タブレット接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionTbList: async (
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/machine/connection/tb/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (connecting !== undefined) {
                localVarQueryParameter['connecting'] = connecting
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * タブレット接続情報を取得する。
         * @summary タブレット接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionTbRead: async (
            imei: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'imei' is not null or undefined
            if (imei === null || imei === undefined) {
                throw new RequiredError(
                    'imei',
                    'Required parameter imei was null or undefined when calling v2MachineConnectionTbRead.'
                )
            }
            const localVarPath = `/v2/machine/connection/tb/{imei}/`.replace(
                `{${'imei'}}`,
                encodeURIComponent(String(imei))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/ac/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary 機体接続情報登録
         * @param {MachineApiV2ConnectionAcCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusAcCreate: async (
            data: MachineApiV2ConnectionAcCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineStatusAcCreate.'
                )
            }
            const localVarPath = `/v2/machine/status/ac/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 基地局の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/bs/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary 基地局接続情報登録
         * @param {MachineApiV2ConnectionBsCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusBsCreate: async (
            data: MachineApiV2ConnectionBsCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineStatusBsCreate.'
                )
            }
            const localVarPath = `/v2/machine/status/bs/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * カメラの接続を登録し接続状態を更新する。  \'api/v2/machine/connection/ca/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary カメラ接続情報登録
         * @param {MachineApiV2ConnectionCaCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusCaCreate: async (
            data: MachineApiV2ConnectionCaCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineStatusCaCreate.'
                )
            }
            const localVarPath = `/v2/machine/status/ca/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 測量機の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/sy/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary 測量機接続情報登録
         * @param {MachineApiV2ConnectionSyCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusSyCreate: async (
            data: MachineApiV2ConnectionSyCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineStatusSyCreate.'
                )
            }
            const localVarPath = `/v2/machine/status/sy/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * タブレットの接続を登録し接続状態を更新する。  \'api/v2/machine/status/bs/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary タブレット接続情報登録
         * @param {MachineApiV2ConnectionTbCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusTbCreate: async (
            data: MachineApiV2ConnectionTbCreateRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v2MachineStatusTbCreate.'
                )
            }
            const localVarPath = `/v2/machine/status/tb/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * NileStation for Android apk ファイルダウンロード
         * @summary NileStation for Android apk ファイルダウンロード
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileAppsNsAndroidKioskApkFileRead: async (
            id: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v2MobileAppsNsAndroidKioskApkFileRead.'
                )
            }
            const localVarPath = `/v2/mobile/apps/ns/android/kiosk/apk/{id}/file/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * NileStation for Android apk 最新版ファイルダウンロード
         * @summary NileStation for Android apk 最新版ファイルダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileAppsNsAndroidKioskApkLatestFileRead: async (
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/mobile/apps/ns/android/kiosk/apk/latest/file/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * NileStation for Android バージョン情報を取得する。
         * @summary NileStation for Android バージョン情報
         * @param {string} [ordering] \&quot;version_code\&quot;: バージョンコード(昇順), \&quot;-version_code\&quot;: バージョンコード(降順),
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileAppsNsAndroidKioskApkList: async (
            ordering?: string,
            limit?: number,
            offset?: number,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/mobile/apps/ns/android/kiosk/apk/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * NileStation for Android バージョン情報の詳細情報を返す。
         * @summary NileStation for Android バージョン情報 - 詳細情報
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileAppsNsAndroidKioskApkRead: async (
            id: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v2MobileAppsNsAndroidKioskApkRead.'
                )
            }
            const localVarPath = `/v2/mobile/apps/ns/android/kiosk/apk/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 圃場情報を取得する。
         * @summary 圃場情報の取得
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileFarmsFieldsRead: async (
            farmPk: string,
            id: string,
            valid?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v2MobileFarmsFieldsRead.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v2MobileFarmsFieldsRead.'
                )
            }
            const localVarPath = `/v2/mobile/farms/{farm_pk}/fields/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (valid !== undefined) {
                localVarQueryParameter['valid'] = valid
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 農場情報を取得する。オプションでteam_idを付与すればチームでフィルタする。
         * @summary 農場情報の取得
         * @param {string} [idIn] Multiple values may be separated by commas.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {number} [lat] 中心点の緯度(latitude)
         * @param {number} [_long] 中心点の緯度(longitude)
         * @param {number} [distance] データを取得する中心点からの距離半径(単位：メートル)
         * @param {string} [teamId] チームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileFarmsList: async (
            idIn?: string,
            valid?: boolean,
            lat?: number,
            _long?: number,
            distance?: number,
            teamId?: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/mobile/farms/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (idIn !== undefined) {
                localVarQueryParameter['id__in'] = idIn
            }

            if (valid !== undefined) {
                localVarQueryParameter['valid'] = valid
            }

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat
            }

            if (_long !== undefined) {
                localVarQueryParameter['long'] = _long
            }

            if (distance !== undefined) {
                localVarQueryParameter['distance'] = distance
            }

            if (teamId !== undefined) {
                localVarQueryParameter['team_id'] = teamId
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 障害物測量結果情報を取得する。
         * @summary 障害物測量結果情報の取得
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileFarmsObstaclesurveysRead: async (
            farmPk: string,
            id: string,
            valid?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'farmPk' is not null or undefined
            if (farmPk === null || farmPk === undefined) {
                throw new RequiredError(
                    'farmPk',
                    'Required parameter farmPk was null or undefined when calling v2MobileFarmsObstaclesurveysRead.'
                )
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v2MobileFarmsObstaclesurveysRead.'
                )
            }
            const localVarPath = `/v2/mobile/farms/{farm_pk}/obstaclesurveys/{id}/`
                .replace(`{${'farm_pk'}}`, encodeURIComponent(String(farmPk)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (valid !== undefined) {
                localVarQueryParameter['valid'] = valid
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 農場情報を取得する。オプションでteam_idを付与すればチームでフィルタする。
         * @summary 農場情報の取得
         * @param {string} id A UUID string identifying this 農場マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {number} [lat] 中心点の緯度(latitude)
         * @param {number} [_long] 中心点の緯度(longitude)
         * @param {number} [distance] データを取得する中心点からの距離半径(単位：メートル)
         * @param {string} [teamId] チームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileFarmsRead: async (
            id: string,
            valid?: boolean,
            lat?: number,
            _long?: number,
            distance?: number,
            teamId?: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v2MobileFarmsRead.'
                )
            }
            const localVarPath = `/v2/mobile/farms/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (valid !== undefined) {
                localVarQueryParameter['valid'] = valid
            }

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat
            }

            if (_long !== undefined) {
                localVarQueryParameter['long'] = _long
            }

            if (distance !== undefined) {
                localVarQueryParameter['distance'] = distance
            }

            if (teamId !== undefined) {
                localVarQueryParameter['team_id'] = teamId
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 機体飛行履歴サマリーの取得
         * @summary 機体飛行履歴サマリー
         * @param {string} serialNumber 機体シリアル番号。e.g. AC190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileMachinesAcFlightSummaryRead: async (
            serialNumber: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            if (serialNumber === null || serialNumber === undefined) {
                throw new RequiredError(
                    'serialNumber',
                    'Required parameter serialNumber was null or undefined when calling v2MobileMachinesAcFlightSummaryRead.'
                )
            }
            const localVarPath = `/v2/mobile/machines/ac/{serial_number}/flight/summary/`.replace(
                `{${'serial_number'}}`,
                encodeURIComponent(String(serialNumber))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 飛行履歴一覧を取得する。
         * @summary 飛行履歴一覧の取得(v2)
         * @param {string} [fieldId] 圃場IDの完全一致
         * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
         * @param {string} [toDatetime] 取得終端日時(iso8601形式)
         * @param {string} [flightUsername] 飛行ユーザー名(ログインID)の完全一致
         * @param {string} [droneBsVpnUserVpnHubName] GID(VPN HUB名)の完全一致
         * @param {string} [droneTbSerialNumber] タブレットシリアル番号の完全一致
         * @param {string} [droneBsSerialNumber] 基地局シリアル番号の完全一致
         * @param {string} [droneAcSerialNumber] 機体シリアル番号の完全一致
         * @param {string} [droneCaSerialNumber] カメラシリアル番号の完全一致
         * @param {string} [referenceNumber] 国交省申請の整理番号の完全一致
         * @param {number} [nwSerialNumber] NW通し番号の完全一致
         * @param {string} [ordering] \&quot;flight_started\&quot;: 飛行開始日時(昇順), \&quot;-flight_started\&quot;: 飛行開始日時(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileOperationFlightsList: async (
            fieldId?: string,
            fromDatetime?: string,
            toDatetime?: string,
            flightUsername?: string,
            droneBsVpnUserVpnHubName?: string,
            droneTbSerialNumber?: string,
            droneBsSerialNumber?: string,
            droneAcSerialNumber?: string,
            droneCaSerialNumber?: string,
            referenceNumber?: string,
            nwSerialNumber?: number,
            ordering?: string,
            limit?: number,
            offset?: number,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/mobile/operation/flights/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (fieldId !== undefined) {
                localVarQueryParameter['field__id'] = fieldId
            }

            if (fromDatetime !== undefined) {
                localVarQueryParameter['from_datetime'] = fromDatetime
            }

            if (toDatetime !== undefined) {
                localVarQueryParameter['to_datetime'] = toDatetime
            }

            if (flightUsername !== undefined) {
                localVarQueryParameter['flight_username'] = flightUsername
            }

            if (droneBsVpnUserVpnHubName !== undefined) {
                localVarQueryParameter['drone_bs__vpn_user__vpn_hub__name'] =
                    droneBsVpnUserVpnHubName
            }

            if (droneTbSerialNumber !== undefined) {
                localVarQueryParameter['drone_tb_serial_number'] = droneTbSerialNumber
            }

            if (droneBsSerialNumber !== undefined) {
                localVarQueryParameter['drone_bs_serial_number'] = droneBsSerialNumber
            }

            if (droneAcSerialNumber !== undefined) {
                localVarQueryParameter['drone_ac_serial_number'] = droneAcSerialNumber
            }

            if (droneCaSerialNumber !== undefined) {
                localVarQueryParameter['drone_ca_serial_number'] = droneCaSerialNumber
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['reference_number'] = referenceNumber
            }

            if (nwSerialNumber !== undefined) {
                localVarQueryParameter['nw_serial_number'] = nwSerialNumber
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 国交省申請管理簿のレコードと、それにひもづくメンバーとその属する組織+親組織リストの一覧を返す。
         * @summary 国交省申請管理簿の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileOperationMinistryreportsList: async (
            limit?: number,
            offset?: number,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v2/mobile/operation/ministryreports/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * V2Api - functional programming interface
 * @export
 */
export const V2ApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 有効な全ての圃場の位置情報を三次メッシュコード(1kmメッシュ)の形式に変換してリストで返す。
         * @summary 圃場の三次メッシュコードへの変換
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AgriFieldstomeshesList(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2AgriFieldstomeshesList(options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局、機体、カメラ、測量機、タブレットと VPN HUBを紐付ける。
         * @summary アクティベーション
         * @param {JigApiV2ActivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigActivationCreate(
            data: JigApiV2ActivationCreate,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2ActivationCreateResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigActivationCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局、機体、カメラ、測量機、タブレットと VPN HUBのアクティベーションを解除する。
         * @summary アクティベーション解除
         * @param {JigApiV2DeactivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigDeactivationCreate(
            data: JigApiV2DeactivationCreate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigApiV2DeactivationCreate>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigDeactivationCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 組織一覧を取得する。
         * @summary 組織一覧の取得
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigFarmsOrganizationsList(
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigFarmsOrganizationsList(ordering, limit, offset, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 組織を取得する。
         * @summary 組織の取得
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigFarmsOrganizationsRead(
            id: string,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigApiV2FarmOrganizationList>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigFarmsOrganizationsRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体の新規シリアル番号を発番する。
         * @summary 機体 - 発番
         * @param {JigApiV2NumberingAcCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingAcCreate(
            data: JigApiV2NumberingAcCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingAcCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingAcCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体一覧を取得する。
         * @summary 機体一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationAc] アクティベーション済み機体のデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingAcList(
            limit?: number,
            offset?: number,
            activationAc?: boolean,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingAcList(limit, offset, activationAc, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体の詳細情報を返す。
         * @summary 機体 - 詳細情報
         * @param {string} droneAcSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingAcRead(
            droneAcSerialNumber: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingAcCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingAcRead(droneAcSerialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局の新規シリアル番号を発番する。
         * @summary 基地局 - 発番
         * @param {JigApiV2NumberingBsCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingBsCreate(
            data: JigApiV2NumberingBsCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingBsCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingBsCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局一覧を取得する。
         * @summary 基地局一覧の取得
         * @param {string} [ordering] \&quot;serial_number\&quot;: シリアル番号(昇順), \&quot;-serial_number\&quot;: シリアル番号(降順), \&quot;vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(昇順), \&quot;-vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationBs] アクティベーション済み基地局のデータのみ取得する場合、true
         * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingBsList(
            ordering?: string,
            limit?: number,
            offset?: number,
            activationBs?: boolean,
            activationTb?: boolean,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingBsList(ordering, limit, offset, activationBs, activationTb, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局の詳細情報を返す。
         * @summary 基地局 - 詳細情報
         * @param {string} droneBsSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingBsRead(
            droneBsSerialNumber: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingBsCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingBsRead(droneBsSerialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * カメラの新規シリアル番号を発番する。
         * @summary カメラ - 発番
         * @param {JigApiV2NumberingCaCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingCaCreate(
            data: JigApiV2NumberingCaCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingCaCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingCaCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * カメラ一覧を取得する。
         * @summary カメラ一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationCa] アクティベーション済みカメラのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingCaList(
            limit?: number,
            offset?: number,
            activationCa?: boolean,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingCaList(limit, offset, activationCa, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * カメラの詳細情報を返す。
         * @summary カメラ - 詳細情報
         * @param {string} droneCaSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingCaRead(
            droneCaSerialNumber: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingCaCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingCaRead(droneCaSerialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 測量機の新規シリアル番号を発番する。
         * @summary 測量機 - 発番
         * @param {JigApiV2NumberingSyCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingSyCreate(
            data: JigApiV2NumberingSyCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingSyCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingSyCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 測量機一覧を取得する。
         * @summary 測量機一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationSy] アクティベーション済み測量機のデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingSyList(
            limit?: number,
            offset?: number,
            activationSy?: boolean,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingSyList(limit, offset, activationSy, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 測量機の詳細情報を返す。
         * @summary 測量機 - 詳細情報
         * @param {string} droneSySerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingSyRead(
            droneSySerialNumber: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingSyCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingSyRead(droneSySerialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * タブレットの新規シリアル番号を発番する。
         * @summary タブレット - 発番
         * @param {JigApiV2NumberingTbCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingTbCreate(
            data: JigApiV2NumberingTbCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingTbCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingTbCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * タブレット一覧を取得する。
         * @summary タブレット一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingTbList(
            limit?: number,
            offset?: number,
            activationTb?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingTbList(limit, offset, activationTb, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * タブレットの詳細情報を返す。
         * @summary タブレット - 詳細情報
         * @param {string} droneTbSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigNumberingTbRead(
            droneTbSerialNumber: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV2NumberingTbCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2JigNumberingTbRead(droneTbSerialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * VPN一覧を取得する。
         * @summary VPN一覧の取得
         * @param {string} [kind] VPN HUB種別
         * @param {string} [ordering] \&quot;name\&quot;: GID(VPN HUB名)(昇順), \&quot;-name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigVpnsList(
            kind?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(configuration).v2JigVpnsList(
                kind,
                ordering,
                limit,
                offset,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * VPNを取得する。
         * @summary VPNの取得
         * @param {string} name e.g. G00001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2JigVpnsRead(
            name: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigApiV2Vpn>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(configuration).v2JigVpnsRead(
                name,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体の接続を登録し接続状態を更新する。
         * @summary 機体接続情報登録
         * @param {MachineApiV2ConnectionAcCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionAcCreate(
            data: MachineApiV2ConnectionAcCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionAcCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionAcCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体接続情報一覧を取得する。
         * @summary 機体接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionAcList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionAcList(limit, offset, connecting, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体接続情報を取得する。
         * @summary 機体接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionAcRead(
            imei: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionAcCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionAcRead(imei, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局の接続を登録し接続状態を更新する。
         * @summary 基地局接続情報登録
         * @param {MachineApiV2ConnectionBsCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionBsCreate(
            data: MachineApiV2ConnectionBsCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionBsCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionBsCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局接続情報一覧を取得する。
         * @summary 基地局接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionBsList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionBsList(limit, offset, connecting, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局接続情報を取得する。
         * @summary 基地局接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionBsRead(
            imei: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionBsCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionBsRead(imei, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * カメラの接続を登録し接続状態を更新する。
         * @summary カメラ接続情報登録
         * @param {MachineApiV2ConnectionCaCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionCaCreate(
            data: MachineApiV2ConnectionCaCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionCaCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionCaCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * カメラ接続情報一覧を取得する。
         * @summary カメラ接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionCaList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionCaList(limit, offset, connecting, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * カメラ接続情報を取得する。
         * @summary カメラ接続情報の取得
         * @param {string} code カメラの識別子。T20から追加。カメラ毎にユニークだが、T19カメラコード未登録のため、unique&#x3D;Falseにしている。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionCaRead(
            code: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionCaCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionCaRead(code, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 測量機の接続を登録し接続状態を更新する。
         * @summary 測量機接続情報登録
         * @param {MachineApiV2ConnectionSyCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionSyCreate(
            data: MachineApiV2ConnectionSyCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionSyCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionSyCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 測量機接続情報一覧を取得する。
         * @summary 測量機接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionSyList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionSyList(limit, offset, connecting, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 測量機接続情報を取得する。
         * @summary 測量機接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionSyRead(
            imei: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionSyCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionSyRead(imei, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * タブレットの接続を登録し接続状態を更新する。
         * @summary タブレット接続情報登録
         * @param {MachineApiV2ConnectionTbCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionTbCreate(
            data: MachineApiV2ConnectionTbCreateRequest,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionTbCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionTbCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * タブレット接続情報一覧を取得する。
         * @summary タブレット接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionTbList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionTbList(limit, offset, connecting, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * タブレット接続情報を取得する。
         * @summary タブレット接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineConnectionTbRead(
            imei: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MachineApiV2ConnectionTbCommonResponse>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineConnectionTbRead(imei, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/ac/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary 機体接続情報登録
         * @param {MachineApiV2ConnectionAcCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineStatusAcCreate(
            data: MachineApiV2ConnectionAcCreateRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineStatusAcCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 基地局の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/bs/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary 基地局接続情報登録
         * @param {MachineApiV2ConnectionBsCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineStatusBsCreate(
            data: MachineApiV2ConnectionBsCreateRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineStatusBsCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * カメラの接続を登録し接続状態を更新する。  \'api/v2/machine/connection/ca/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary カメラ接続情報登録
         * @param {MachineApiV2ConnectionCaCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineStatusCaCreate(
            data: MachineApiV2ConnectionCaCreateRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineStatusCaCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 測量機の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/sy/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary 測量機接続情報登録
         * @param {MachineApiV2ConnectionSyCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineStatusSyCreate(
            data: MachineApiV2ConnectionSyCreateRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineStatusSyCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * タブレットの接続を登録し接続状態を更新する。  \'api/v2/machine/status/bs/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary タブレット接続情報登録
         * @param {MachineApiV2ConnectionTbCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MachineStatusTbCreate(
            data: MachineApiV2ConnectionTbCreateRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MachineStatusTbCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * NileStation for Android apk ファイルダウンロード
         * @summary NileStation for Android apk ファイルダウンロード
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileAppsNsAndroidKioskApkFileRead(
            id: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MobileApiV2AppNsAndroidKioskApkFile>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MobileAppsNsAndroidKioskApkFileRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * NileStation for Android apk 最新版ファイルダウンロード
         * @summary NileStation for Android apk 最新版ファイルダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileAppsNsAndroidKioskApkLatestFileRead(
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MobileApiV2AppNsAndroidKioskApkFile>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MobileAppsNsAndroidKioskApkLatestFileRead(options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * NileStation for Android バージョン情報を取得する。
         * @summary NileStation for Android バージョン情報
         * @param {string} [ordering] \&quot;version_code\&quot;: バージョンコード(昇順), \&quot;-version_code\&quot;: バージョンコード(降順),
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileAppsNsAndroidKioskApkList(
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MobileAppsNsAndroidKioskApkList(ordering, limit, offset, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * NileStation for Android バージョン情報の詳細情報を返す。
         * @summary NileStation for Android バージョン情報 - 詳細情報
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileAppsNsAndroidKioskApkRead(
            id: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MobileApiV2AppNsAndroidKioskApkList>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MobileAppsNsAndroidKioskApkRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 圃場情報を取得する。
         * @summary 圃場情報の取得
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileFarmsFieldsRead(
            farmPk: string,
            id: string,
            valid?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileApiV2FarmFieldList>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MobileFarmsFieldsRead(farmPk, id, valid, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 農場情報を取得する。オプションでteam_idを付与すればチームでフィルタする。
         * @summary 農場情報の取得
         * @param {string} [idIn] Multiple values may be separated by commas.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {number} [lat] 中心点の緯度(latitude)
         * @param {number} [_long] 中心点の緯度(longitude)
         * @param {number} [distance] データを取得する中心点からの距離半径(単位：メートル)
         * @param {string} [teamId] チームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileFarmsList(
            idIn?: string,
            valid?: boolean,
            lat?: number,
            _long?: number,
            distance?: number,
            teamId?: string,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MobileApiV2FarmList>>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(configuration).v2MobileFarmsList(
                idIn,
                valid,
                lat,
                _long,
                distance,
                teamId,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 障害物測量結果情報を取得する。
         * @summary 障害物測量結果情報の取得
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileFarmsObstaclesurveysRead(
            farmPk: string,
            id: string,
            valid?: boolean,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MobileApiV2FarmObstacleSurveyList>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MobileFarmsObstaclesurveysRead(farmPk, id, valid, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 農場情報を取得する。オプションでteam_idを付与すればチームでフィルタする。
         * @summary 農場情報の取得
         * @param {string} id A UUID string identifying this 農場マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {number} [lat] 中心点の緯度(latitude)
         * @param {number} [_long] 中心点の緯度(longitude)
         * @param {number} [distance] データを取得する中心点からの距離半径(単位：メートル)
         * @param {string} [teamId] チームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileFarmsRead(
            id: string,
            valid?: boolean,
            lat?: number,
            _long?: number,
            distance?: number,
            teamId?: string,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileApiV2FarmList>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(configuration).v2MobileFarmsRead(
                id,
                valid,
                lat,
                _long,
                distance,
                teamId,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 機体飛行履歴サマリーの取得
         * @summary 機体飛行履歴サマリー
         * @param {string} serialNumber 機体シリアル番号。e.g. AC190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileMachinesAcFlightSummaryRead(
            serialNumber: string,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MobileApiV2MachineAcFlightSummaryList>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MobileMachinesAcFlightSummaryRead(serialNumber, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 飛行履歴一覧を取得する。
         * @summary 飛行履歴一覧の取得(v2)
         * @param {string} [fieldId] 圃場IDの完全一致
         * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
         * @param {string} [toDatetime] 取得終端日時(iso8601形式)
         * @param {string} [flightUsername] 飛行ユーザー名(ログインID)の完全一致
         * @param {string} [droneBsVpnUserVpnHubName] GID(VPN HUB名)の完全一致
         * @param {string} [droneTbSerialNumber] タブレットシリアル番号の完全一致
         * @param {string} [droneBsSerialNumber] 基地局シリアル番号の完全一致
         * @param {string} [droneAcSerialNumber] 機体シリアル番号の完全一致
         * @param {string} [droneCaSerialNumber] カメラシリアル番号の完全一致
         * @param {string} [referenceNumber] 国交省申請の整理番号の完全一致
         * @param {number} [nwSerialNumber] NW通し番号の完全一致
         * @param {string} [ordering] \&quot;flight_started\&quot;: 飛行開始日時(昇順), \&quot;-flight_started\&quot;: 飛行開始日時(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileOperationFlightsList(
            fieldId?: string,
            fromDatetime?: string,
            toDatetime?: string,
            flightUsername?: string,
            droneBsVpnUserVpnHubName?: string,
            droneTbSerialNumber?: string,
            droneBsSerialNumber?: string,
            droneAcSerialNumber?: string,
            droneCaSerialNumber?: string,
            referenceNumber?: string,
            nwSerialNumber?: number,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MobileOperationFlightsList(
                fieldId,
                fromDatetime,
                toDatetime,
                flightUsername,
                droneBsVpnUserVpnHubName,
                droneTbSerialNumber,
                droneBsSerialNumber,
                droneAcSerialNumber,
                droneCaSerialNumber,
                referenceNumber,
                nwSerialNumber,
                ordering,
                limit,
                offset,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 国交省申請管理簿のレコードと、それにひもづくメンバーとその属する組織+親組織リストの一覧を返す。
         * @summary 国交省申請管理簿の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2MobileOperationMinistryreportsList(
            limit?: number,
            offset?: number,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>
        > {
            const localVarAxiosArgs = await V2ApiAxiosParamCreator(
                configuration
            ).v2MobileOperationMinistryreportsList(limit, offset, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
    }
}

/**
 * V2Api - factory interface
 * @export
 */
export const V2ApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    return {
        /**
         * 有効な全ての圃場の位置情報を三次メッシュコード(1kmメッシュ)の形式に変換してリストで返す。
         * @summary 圃場の三次メッシュコードへの変換
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AgriFieldstomeshesList(options?: any): AxiosPromise<Array<object>> {
            return V2ApiFp(configuration)
                .v2AgriFieldstomeshesList(options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局、機体、カメラ、測量機、タブレットと VPN HUBを紐付ける。
         * @summary アクティベーション
         * @param {JigApiV2ActivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigActivationCreate(
            data: JigApiV2ActivationCreate,
            options?: any
        ): AxiosPromise<JigApiV2ActivationCreateResponse> {
            return V2ApiFp(configuration)
                .v2JigActivationCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局、機体、カメラ、測量機、タブレットと VPN HUBのアクティベーションを解除する。
         * @summary アクティベーション解除
         * @param {JigApiV2DeactivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigDeactivationCreate(
            data: JigApiV2DeactivationCreate,
            options?: any
        ): AxiosPromise<JigApiV2DeactivationCreate> {
            return V2ApiFp(configuration)
                .v2JigDeactivationCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 組織一覧を取得する。
         * @summary 組織一覧の取得
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigFarmsOrganizationsList(
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): AxiosPromise<InlineResponse2005> {
            return V2ApiFp(configuration)
                .v2JigFarmsOrganizationsList(ordering, limit, offset, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 組織を取得する。
         * @summary 組織の取得
         * @param {string} id A UUID string identifying this 組織マスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigFarmsOrganizationsRead(
            id: string,
            options?: any
        ): AxiosPromise<JigApiV2FarmOrganizationList> {
            return V2ApiFp(configuration)
                .v2JigFarmsOrganizationsRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体の新規シリアル番号を発番する。
         * @summary 機体 - 発番
         * @param {JigApiV2NumberingAcCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingAcCreate(
            data: JigApiV2NumberingAcCreateRequest,
            options?: any
        ): AxiosPromise<JigApiV2NumberingAcCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingAcCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体一覧を取得する。
         * @summary 機体一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationAc] アクティベーション済み機体のデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingAcList(
            limit?: number,
            offset?: number,
            activationAc?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse2006> {
            return V2ApiFp(configuration)
                .v2JigNumberingAcList(limit, offset, activationAc, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体の詳細情報を返す。
         * @summary 機体 - 詳細情報
         * @param {string} droneAcSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingAcRead(
            droneAcSerialNumber: string,
            options?: any
        ): AxiosPromise<JigApiV2NumberingAcCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingAcRead(droneAcSerialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局の新規シリアル番号を発番する。
         * @summary 基地局 - 発番
         * @param {JigApiV2NumberingBsCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingBsCreate(
            data: JigApiV2NumberingBsCreateRequest,
            options?: any
        ): AxiosPromise<JigApiV2NumberingBsCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingBsCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局一覧を取得する。
         * @summary 基地局一覧の取得
         * @param {string} [ordering] \&quot;serial_number\&quot;: シリアル番号(昇順), \&quot;-serial_number\&quot;: シリアル番号(降順), \&quot;vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(昇順), \&quot;-vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationBs] アクティベーション済み基地局のデータのみ取得する場合、true
         * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingBsList(
            ordering?: string,
            limit?: number,
            offset?: number,
            activationBs?: boolean,
            activationTb?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse2007> {
            return V2ApiFp(configuration)
                .v2JigNumberingBsList(ordering, limit, offset, activationBs, activationTb, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局の詳細情報を返す。
         * @summary 基地局 - 詳細情報
         * @param {string} droneBsSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingBsRead(
            droneBsSerialNumber: string,
            options?: any
        ): AxiosPromise<JigApiV2NumberingBsCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingBsRead(droneBsSerialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * カメラの新規シリアル番号を発番する。
         * @summary カメラ - 発番
         * @param {JigApiV2NumberingCaCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingCaCreate(
            data: JigApiV2NumberingCaCreateRequest,
            options?: any
        ): AxiosPromise<JigApiV2NumberingCaCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingCaCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * カメラ一覧を取得する。
         * @summary カメラ一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationCa] アクティベーション済みカメラのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingCaList(
            limit?: number,
            offset?: number,
            activationCa?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse2008> {
            return V2ApiFp(configuration)
                .v2JigNumberingCaList(limit, offset, activationCa, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * カメラの詳細情報を返す。
         * @summary カメラ - 詳細情報
         * @param {string} droneCaSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingCaRead(
            droneCaSerialNumber: string,
            options?: any
        ): AxiosPromise<JigApiV2NumberingCaCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingCaRead(droneCaSerialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 測量機の新規シリアル番号を発番する。
         * @summary 測量機 - 発番
         * @param {JigApiV2NumberingSyCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingSyCreate(
            data: JigApiV2NumberingSyCreateRequest,
            options?: any
        ): AxiosPromise<JigApiV2NumberingSyCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingSyCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 測量機一覧を取得する。
         * @summary 測量機一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationSy] アクティベーション済み測量機のデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingSyList(
            limit?: number,
            offset?: number,
            activationSy?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse2009> {
            return V2ApiFp(configuration)
                .v2JigNumberingSyList(limit, offset, activationSy, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 測量機の詳細情報を返す。
         * @summary 測量機 - 詳細情報
         * @param {string} droneSySerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingSyRead(
            droneSySerialNumber: string,
            options?: any
        ): AxiosPromise<JigApiV2NumberingSyCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingSyRead(droneSySerialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * タブレットの新規シリアル番号を発番する。
         * @summary タブレット - 発番
         * @param {JigApiV2NumberingTbCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingTbCreate(
            data: JigApiV2NumberingTbCreateRequest,
            options?: any
        ): AxiosPromise<JigApiV2NumberingTbCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingTbCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * タブレット一覧を取得する。
         * @summary タブレット一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingTbList(
            limit?: number,
            offset?: number,
            activationTb?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse20010> {
            return V2ApiFp(configuration)
                .v2JigNumberingTbList(limit, offset, activationTb, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * タブレットの詳細情報を返す。
         * @summary タブレット - 詳細情報
         * @param {string} droneTbSerialNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigNumberingTbRead(
            droneTbSerialNumber: string,
            options?: any
        ): AxiosPromise<JigApiV2NumberingTbCommonResponse> {
            return V2ApiFp(configuration)
                .v2JigNumberingTbRead(droneTbSerialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * VPN一覧を取得する。
         * @summary VPN一覧の取得
         * @param {string} [kind] VPN HUB種別
         * @param {string} [ordering] \&quot;name\&quot;: GID(VPN HUB名)(昇順), \&quot;-name\&quot;: GID(VPN HUB名)(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigVpnsList(
            kind?: string,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): AxiosPromise<InlineResponse20011> {
            return V2ApiFp(configuration)
                .v2JigVpnsList(kind, ordering, limit, offset, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * VPNを取得する。
         * @summary VPNの取得
         * @param {string} name e.g. G00001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2JigVpnsRead(name: string, options?: any): AxiosPromise<JigApiV2Vpn> {
            return V2ApiFp(configuration)
                .v2JigVpnsRead(name, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体の接続を登録し接続状態を更新する。
         * @summary 機体接続情報登録
         * @param {MachineApiV2ConnectionAcCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionAcCreate(
            data: MachineApiV2ConnectionAcCreateRequest,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionAcCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionAcCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体接続情報一覧を取得する。
         * @summary 機体接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionAcList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse20012> {
            return V2ApiFp(configuration)
                .v2MachineConnectionAcList(limit, offset, connecting, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体接続情報を取得する。
         * @summary 機体接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionAcRead(
            imei: string,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionAcCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionAcRead(imei, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局の接続を登録し接続状態を更新する。
         * @summary 基地局接続情報登録
         * @param {MachineApiV2ConnectionBsCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionBsCreate(
            data: MachineApiV2ConnectionBsCreateRequest,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionBsCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionBsCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局接続情報一覧を取得する。
         * @summary 基地局接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionBsList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse20013> {
            return V2ApiFp(configuration)
                .v2MachineConnectionBsList(limit, offset, connecting, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局接続情報を取得する。
         * @summary 基地局接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionBsRead(
            imei: string,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionBsCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionBsRead(imei, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * カメラの接続を登録し接続状態を更新する。
         * @summary カメラ接続情報登録
         * @param {MachineApiV2ConnectionCaCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionCaCreate(
            data: MachineApiV2ConnectionCaCreateRequest,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionCaCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionCaCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * カメラ接続情報一覧を取得する。
         * @summary カメラ接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionCaList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse20014> {
            return V2ApiFp(configuration)
                .v2MachineConnectionCaList(limit, offset, connecting, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * カメラ接続情報を取得する。
         * @summary カメラ接続情報の取得
         * @param {string} code カメラの識別子。T20から追加。カメラ毎にユニークだが、T19カメラコード未登録のため、unique&#x3D;Falseにしている。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionCaRead(
            code: string,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionCaCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionCaRead(code, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 測量機の接続を登録し接続状態を更新する。
         * @summary 測量機接続情報登録
         * @param {MachineApiV2ConnectionSyCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionSyCreate(
            data: MachineApiV2ConnectionSyCreateRequest,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionSyCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionSyCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 測量機接続情報一覧を取得する。
         * @summary 測量機接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionSyList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse20015> {
            return V2ApiFp(configuration)
                .v2MachineConnectionSyList(limit, offset, connecting, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 測量機接続情報を取得する。
         * @summary 測量機接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionSyRead(
            imei: string,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionSyCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionSyRead(imei, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * タブレットの接続を登録し接続状態を更新する。
         * @summary タブレット接続情報登録
         * @param {MachineApiV2ConnectionTbCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionTbCreate(
            data: MachineApiV2ConnectionTbCreateRequest,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionTbCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionTbCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * タブレット接続情報一覧を取得する。
         * @summary タブレット接続情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionTbList(
            limit?: number,
            offset?: number,
            connecting?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse20016> {
            return V2ApiFp(configuration)
                .v2MachineConnectionTbList(limit, offset, connecting, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * タブレット接続情報を取得する。
         * @summary タブレット接続情報の取得
         * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineConnectionTbRead(
            imei: string,
            options?: any
        ): AxiosPromise<MachineApiV2ConnectionTbCommonResponse> {
            return V2ApiFp(configuration)
                .v2MachineConnectionTbRead(imei, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/ac/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary 機体接続情報登録
         * @param {MachineApiV2ConnectionAcCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusAcCreate(
            data: MachineApiV2ConnectionAcCreateRequest,
            options?: any
        ): AxiosPromise<void> {
            return V2ApiFp(configuration)
                .v2MachineStatusAcCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 基地局の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/bs/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary 基地局接続情報登録
         * @param {MachineApiV2ConnectionBsCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusBsCreate(
            data: MachineApiV2ConnectionBsCreateRequest,
            options?: any
        ): AxiosPromise<void> {
            return V2ApiFp(configuration)
                .v2MachineStatusBsCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * カメラの接続を登録し接続状態を更新する。  \'api/v2/machine/connection/ca/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary カメラ接続情報登録
         * @param {MachineApiV2ConnectionCaCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusCaCreate(
            data: MachineApiV2ConnectionCaCreateRequest,
            options?: any
        ): AxiosPromise<void> {
            return V2ApiFp(configuration)
                .v2MachineStatusCaCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 測量機の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/sy/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary 測量機接続情報登録
         * @param {MachineApiV2ConnectionSyCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusSyCreate(
            data: MachineApiV2ConnectionSyCreateRequest,
            options?: any
        ): AxiosPromise<void> {
            return V2ApiFp(configuration)
                .v2MachineStatusSyCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * タブレットの接続を登録し接続状態を更新する。  \'api/v2/machine/status/bs/\' との違いは、HTTPレスポンスデータを返すか返さないか。
         * @summary タブレット接続情報登録
         * @param {MachineApiV2ConnectionTbCreateRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MachineStatusTbCreate(
            data: MachineApiV2ConnectionTbCreateRequest,
            options?: any
        ): AxiosPromise<void> {
            return V2ApiFp(configuration)
                .v2MachineStatusTbCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * NileStation for Android apk ファイルダウンロード
         * @summary NileStation for Android apk ファイルダウンロード
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileAppsNsAndroidKioskApkFileRead(
            id: string,
            options?: any
        ): AxiosPromise<MobileApiV2AppNsAndroidKioskApkFile> {
            return V2ApiFp(configuration)
                .v2MobileAppsNsAndroidKioskApkFileRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * NileStation for Android apk 最新版ファイルダウンロード
         * @summary NileStation for Android apk 最新版ファイルダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileAppsNsAndroidKioskApkLatestFileRead(
            options?: any
        ): AxiosPromise<MobileApiV2AppNsAndroidKioskApkFile> {
            return V2ApiFp(configuration)
                .v2MobileAppsNsAndroidKioskApkLatestFileRead(options)
                .then((request) => request(axios, basePath))
        },
        /**
         * NileStation for Android バージョン情報を取得する。
         * @summary NileStation for Android バージョン情報
         * @param {string} [ordering] \&quot;version_code\&quot;: バージョンコード(昇順), \&quot;-version_code\&quot;: バージョンコード(降順),
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileAppsNsAndroidKioskApkList(
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): AxiosPromise<InlineResponse20017> {
            return V2ApiFp(configuration)
                .v2MobileAppsNsAndroidKioskApkList(ordering, limit, offset, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * NileStation for Android バージョン情報の詳細情報を返す。
         * @summary NileStation for Android バージョン情報 - 詳細情報
         * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileAppsNsAndroidKioskApkRead(
            id: string,
            options?: any
        ): AxiosPromise<MobileApiV2AppNsAndroidKioskApkList> {
            return V2ApiFp(configuration)
                .v2MobileAppsNsAndroidKioskApkRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 圃場情報を取得する。
         * @summary 圃場情報の取得
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 圃場マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileFarmsFieldsRead(
            farmPk: string,
            id: string,
            valid?: boolean,
            options?: any
        ): AxiosPromise<MobileApiV2FarmFieldList> {
            return V2ApiFp(configuration)
                .v2MobileFarmsFieldsRead(farmPk, id, valid, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 農場情報を取得する。オプションでteam_idを付与すればチームでフィルタする。
         * @summary 農場情報の取得
         * @param {string} [idIn] Multiple values may be separated by commas.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {number} [lat] 中心点の緯度(latitude)
         * @param {number} [_long] 中心点の緯度(longitude)
         * @param {number} [distance] データを取得する中心点からの距離半径(単位：メートル)
         * @param {string} [teamId] チームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileFarmsList(
            idIn?: string,
            valid?: boolean,
            lat?: number,
            _long?: number,
            distance?: number,
            teamId?: string,
            options?: any
        ): AxiosPromise<Array<MobileApiV2FarmList>> {
            return V2ApiFp(configuration)
                .v2MobileFarmsList(idIn, valid, lat, _long, distance, teamId, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 障害物測量結果情報を取得する。
         * @summary 障害物測量結果情報の取得
         * @param {string} farmPk
         * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileFarmsObstaclesurveysRead(
            farmPk: string,
            id: string,
            valid?: boolean,
            options?: any
        ): AxiosPromise<MobileApiV2FarmObstacleSurveyList> {
            return V2ApiFp(configuration)
                .v2MobileFarmsObstaclesurveysRead(farmPk, id, valid, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 農場情報を取得する。オプションでteam_idを付与すればチームでフィルタする。
         * @summary 農場情報の取得
         * @param {string} id A UUID string identifying this 農場マスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {number} [lat] 中心点の緯度(latitude)
         * @param {number} [_long] 中心点の緯度(longitude)
         * @param {number} [distance] データを取得する中心点からの距離半径(単位：メートル)
         * @param {string} [teamId] チームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileFarmsRead(
            id: string,
            valid?: boolean,
            lat?: number,
            _long?: number,
            distance?: number,
            teamId?: string,
            options?: any
        ): AxiosPromise<MobileApiV2FarmList> {
            return V2ApiFp(configuration)
                .v2MobileFarmsRead(id, valid, lat, _long, distance, teamId, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 機体飛行履歴サマリーの取得
         * @summary 機体飛行履歴サマリー
         * @param {string} serialNumber 機体シリアル番号。e.g. AC190001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileMachinesAcFlightSummaryRead(
            serialNumber: string,
            options?: any
        ): AxiosPromise<MobileApiV2MachineAcFlightSummaryList> {
            return V2ApiFp(configuration)
                .v2MobileMachinesAcFlightSummaryRead(serialNumber, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 飛行履歴一覧を取得する。
         * @summary 飛行履歴一覧の取得(v2)
         * @param {string} [fieldId] 圃場IDの完全一致
         * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
         * @param {string} [toDatetime] 取得終端日時(iso8601形式)
         * @param {string} [flightUsername] 飛行ユーザー名(ログインID)の完全一致
         * @param {string} [droneBsVpnUserVpnHubName] GID(VPN HUB名)の完全一致
         * @param {string} [droneTbSerialNumber] タブレットシリアル番号の完全一致
         * @param {string} [droneBsSerialNumber] 基地局シリアル番号の完全一致
         * @param {string} [droneAcSerialNumber] 機体シリアル番号の完全一致
         * @param {string} [droneCaSerialNumber] カメラシリアル番号の完全一致
         * @param {string} [referenceNumber] 国交省申請の整理番号の完全一致
         * @param {number} [nwSerialNumber] NW通し番号の完全一致
         * @param {string} [ordering] \&quot;flight_started\&quot;: 飛行開始日時(昇順), \&quot;-flight_started\&quot;: 飛行開始日時(降順)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileOperationFlightsList(
            fieldId?: string,
            fromDatetime?: string,
            toDatetime?: string,
            flightUsername?: string,
            droneBsVpnUserVpnHubName?: string,
            droneTbSerialNumber?: string,
            droneBsSerialNumber?: string,
            droneAcSerialNumber?: string,
            droneCaSerialNumber?: string,
            referenceNumber?: string,
            nwSerialNumber?: number,
            ordering?: string,
            limit?: number,
            offset?: number,
            options?: any
        ): AxiosPromise<InlineResponse20018> {
            return V2ApiFp(configuration)
                .v2MobileOperationFlightsList(
                    fieldId,
                    fromDatetime,
                    toDatetime,
                    flightUsername,
                    droneBsVpnUserVpnHubName,
                    droneTbSerialNumber,
                    droneBsSerialNumber,
                    droneAcSerialNumber,
                    droneCaSerialNumber,
                    referenceNumber,
                    nwSerialNumber,
                    ordering,
                    limit,
                    offset,
                    options
                )
                .then((request) => request(axios, basePath))
        },
        /**
         * 国交省申請管理簿のレコードと、それにひもづくメンバーとその属する組織+親組織リストの一覧を返す。
         * @summary 国交省申請管理簿の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2MobileOperationMinistryreportsList(
            limit?: number,
            offset?: number,
            options?: any
        ): AxiosPromise<InlineResponse20019> {
            return V2ApiFp(configuration)
                .v2MobileOperationMinistryreportsList(limit, offset, options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * V2Api - object-oriented interface
 * @export
 * @class V2Api
 * @extends {BaseAPI}
 */
export class V2Api extends BaseAPI {
    /**
     * 有効な全ての圃場の位置情報を三次メッシュコード(1kmメッシュ)の形式に変換してリストで返す。
     * @summary 圃場の三次メッシュコードへの変換
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2AgriFieldstomeshesList(options?: any) {
        return V2ApiFp(this.configuration)
            .v2AgriFieldstomeshesList(options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局、機体、カメラ、測量機、タブレットと VPN HUBを紐付ける。
     * @summary アクティベーション
     * @param {JigApiV2ActivationCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigActivationCreate(data: JigApiV2ActivationCreate, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigActivationCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局、機体、カメラ、測量機、タブレットと VPN HUBのアクティベーションを解除する。
     * @summary アクティベーション解除
     * @param {JigApiV2DeactivationCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigDeactivationCreate(data: JigApiV2DeactivationCreate, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigDeactivationCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 組織一覧を取得する。
     * @summary 組織一覧の取得
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigFarmsOrganizationsList(
        ordering?: string,
        limit?: number,
        offset?: number,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2JigFarmsOrganizationsList(ordering, limit, offset, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 組織を取得する。
     * @summary 組織の取得
     * @param {string} id A UUID string identifying this 組織マスタ.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigFarmsOrganizationsRead(id: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigFarmsOrganizationsRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体の新規シリアル番号を発番する。
     * @summary 機体 - 発番
     * @param {JigApiV2NumberingAcCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingAcCreate(data: JigApiV2NumberingAcCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingAcCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体一覧を取得する。
     * @summary 機体一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [activationAc] アクティベーション済み機体のデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingAcList(
        limit?: number,
        offset?: number,
        activationAc?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingAcList(limit, offset, activationAc, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体の詳細情報を返す。
     * @summary 機体 - 詳細情報
     * @param {string} droneAcSerialNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingAcRead(droneAcSerialNumber: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingAcRead(droneAcSerialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局の新規シリアル番号を発番する。
     * @summary 基地局 - 発番
     * @param {JigApiV2NumberingBsCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingBsCreate(data: JigApiV2NumberingBsCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingBsCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局一覧を取得する。
     * @summary 基地局一覧の取得
     * @param {string} [ordering] \&quot;serial_number\&quot;: シリアル番号(昇順), \&quot;-serial_number\&quot;: シリアル番号(降順), \&quot;vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(昇順), \&quot;-vpn_user__vpn_hub__name\&quot;: GID(VPN HUB名)(降順)
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [activationBs] アクティベーション済み基地局のデータのみ取得する場合、true
     * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingBsList(
        ordering?: string,
        limit?: number,
        offset?: number,
        activationBs?: boolean,
        activationTb?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingBsList(ordering, limit, offset, activationBs, activationTb, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局の詳細情報を返す。
     * @summary 基地局 - 詳細情報
     * @param {string} droneBsSerialNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingBsRead(droneBsSerialNumber: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingBsRead(droneBsSerialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * カメラの新規シリアル番号を発番する。
     * @summary カメラ - 発番
     * @param {JigApiV2NumberingCaCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingCaCreate(data: JigApiV2NumberingCaCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingCaCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * カメラ一覧を取得する。
     * @summary カメラ一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [activationCa] アクティベーション済みカメラのデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingCaList(
        limit?: number,
        offset?: number,
        activationCa?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingCaList(limit, offset, activationCa, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * カメラの詳細情報を返す。
     * @summary カメラ - 詳細情報
     * @param {string} droneCaSerialNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingCaRead(droneCaSerialNumber: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingCaRead(droneCaSerialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 測量機の新規シリアル番号を発番する。
     * @summary 測量機 - 発番
     * @param {JigApiV2NumberingSyCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingSyCreate(data: JigApiV2NumberingSyCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingSyCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 測量機一覧を取得する。
     * @summary 測量機一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [activationSy] アクティベーション済み測量機のデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingSyList(
        limit?: number,
        offset?: number,
        activationSy?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingSyList(limit, offset, activationSy, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 測量機の詳細情報を返す。
     * @summary 測量機 - 詳細情報
     * @param {string} droneSySerialNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingSyRead(droneSySerialNumber: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingSyRead(droneSySerialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * タブレットの新規シリアル番号を発番する。
     * @summary タブレット - 発番
     * @param {JigApiV2NumberingTbCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingTbCreate(data: JigApiV2NumberingTbCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingTbCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * タブレット一覧を取得する。
     * @summary タブレット一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [activationTb] アクティベーション済みタブレットのデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingTbList(
        limit?: number,
        offset?: number,
        activationTb?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingTbList(limit, offset, activationTb, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * タブレットの詳細情報を返す。
     * @summary タブレット - 詳細情報
     * @param {string} droneTbSerialNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigNumberingTbRead(droneTbSerialNumber: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigNumberingTbRead(droneTbSerialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * VPN一覧を取得する。
     * @summary VPN一覧の取得
     * @param {string} [kind] VPN HUB種別
     * @param {string} [ordering] \&quot;name\&quot;: GID(VPN HUB名)(昇順), \&quot;-name\&quot;: GID(VPN HUB名)(降順)
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigVpnsList(
        kind?: string,
        ordering?: string,
        limit?: number,
        offset?: number,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2JigVpnsList(kind, ordering, limit, offset, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * VPNを取得する。
     * @summary VPNの取得
     * @param {string} name e.g. G00001
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2JigVpnsRead(name: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2JigVpnsRead(name, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体の接続を登録し接続状態を更新する。
     * @summary 機体接続情報登録
     * @param {MachineApiV2ConnectionAcCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionAcCreate(data: MachineApiV2ConnectionAcCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionAcCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体接続情報一覧を取得する。
     * @summary 機体接続情報一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionAcList(
        limit?: number,
        offset?: number,
        connecting?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionAcList(limit, offset, connecting, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体接続情報を取得する。
     * @summary 機体接続情報の取得
     * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionAcRead(imei: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionAcRead(imei, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局の接続を登録し接続状態を更新する。
     * @summary 基地局接続情報登録
     * @param {MachineApiV2ConnectionBsCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionBsCreate(data: MachineApiV2ConnectionBsCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionBsCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局接続情報一覧を取得する。
     * @summary 基地局接続情報一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionBsList(
        limit?: number,
        offset?: number,
        connecting?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionBsList(limit, offset, connecting, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局接続情報を取得する。
     * @summary 基地局接続情報の取得
     * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionBsRead(imei: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionBsRead(imei, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * カメラの接続を登録し接続状態を更新する。
     * @summary カメラ接続情報登録
     * @param {MachineApiV2ConnectionCaCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionCaCreate(data: MachineApiV2ConnectionCaCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionCaCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * カメラ接続情報一覧を取得する。
     * @summary カメラ接続情報一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionCaList(
        limit?: number,
        offset?: number,
        connecting?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionCaList(limit, offset, connecting, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * カメラ接続情報を取得する。
     * @summary カメラ接続情報の取得
     * @param {string} code カメラの識別子。T20から追加。カメラ毎にユニークだが、T19カメラコード未登録のため、unique&#x3D;Falseにしている。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionCaRead(code: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionCaRead(code, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 測量機の接続を登録し接続状態を更新する。
     * @summary 測量機接続情報登録
     * @param {MachineApiV2ConnectionSyCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionSyCreate(data: MachineApiV2ConnectionSyCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionSyCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 測量機接続情報一覧を取得する。
     * @summary 測量機接続情報一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionSyList(
        limit?: number,
        offset?: number,
        connecting?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionSyList(limit, offset, connecting, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 測量機接続情報を取得する。
     * @summary 測量機接続情報の取得
     * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionSyRead(imei: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionSyRead(imei, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * タブレットの接続を登録し接続状態を更新する。
     * @summary タブレット接続情報登録
     * @param {MachineApiV2ConnectionTbCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionTbCreate(data: MachineApiV2ConnectionTbCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionTbCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * タブレット接続情報一覧を取得する。
     * @summary タブレット接続情報一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [connecting] 接続中の機材のみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionTbList(
        limit?: number,
        offset?: number,
        connecting?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionTbList(limit, offset, connecting, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * タブレット接続情報を取得する。
     * @summary タブレット接続情報の取得
     * @param {string} imei LTEモジュールに付与されている15桁の数字. e.g. 355819084674096
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineConnectionTbRead(imei: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineConnectionTbRead(imei, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/ac/\' との違いは、HTTPレスポンスデータを返すか返さないか。
     * @summary 機体接続情報登録
     * @param {MachineApiV2ConnectionAcCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineStatusAcCreate(data: MachineApiV2ConnectionAcCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineStatusAcCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 基地局の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/bs/\' との違いは、HTTPレスポンスデータを返すか返さないか。
     * @summary 基地局接続情報登録
     * @param {MachineApiV2ConnectionBsCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineStatusBsCreate(data: MachineApiV2ConnectionBsCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineStatusBsCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * カメラの接続を登録し接続状態を更新する。  \'api/v2/machine/connection/ca/\' との違いは、HTTPレスポンスデータを返すか返さないか。
     * @summary カメラ接続情報登録
     * @param {MachineApiV2ConnectionCaCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineStatusCaCreate(data: MachineApiV2ConnectionCaCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineStatusCaCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 測量機の接続を登録し接続状態を更新する。  \'api/v2/machine/connection/sy/\' との違いは、HTTPレスポンスデータを返すか返さないか。
     * @summary 測量機接続情報登録
     * @param {MachineApiV2ConnectionSyCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineStatusSyCreate(data: MachineApiV2ConnectionSyCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineStatusSyCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * タブレットの接続を登録し接続状態を更新する。  \'api/v2/machine/status/bs/\' との違いは、HTTPレスポンスデータを返すか返さないか。
     * @summary タブレット接続情報登録
     * @param {MachineApiV2ConnectionTbCreateRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MachineStatusTbCreate(data: MachineApiV2ConnectionTbCreateRequest, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MachineStatusTbCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * NileStation for Android apk ファイルダウンロード
     * @summary NileStation for Android apk ファイルダウンロード
     * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileAppsNsAndroidKioskApkFileRead(id: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MobileAppsNsAndroidKioskApkFileRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * NileStation for Android apk 最新版ファイルダウンロード
     * @summary NileStation for Android apk 最新版ファイルダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileAppsNsAndroidKioskApkLatestFileRead(options?: any) {
        return V2ApiFp(this.configuration)
            .v2MobileAppsNsAndroidKioskApkLatestFileRead(options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * NileStation for Android バージョン情報を取得する。
     * @summary NileStation for Android バージョン情報
     * @param {string} [ordering] \&quot;version_code\&quot;: バージョンコード(昇順), \&quot;-version_code\&quot;: バージョンコード(降順),
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileAppsNsAndroidKioskApkList(
        ordering?: string,
        limit?: number,
        offset?: number,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MobileAppsNsAndroidKioskApkList(ordering, limit, offset, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * NileStation for Android バージョン情報の詳細情報を返す。
     * @summary NileStation for Android バージョン情報 - 詳細情報
     * @param {string} id A UUID string identifying this NileStation for Android バージョン管理.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileAppsNsAndroidKioskApkRead(id: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MobileAppsNsAndroidKioskApkRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 圃場情報を取得する。
     * @summary 圃場情報の取得
     * @param {string} farmPk
     * @param {string} id A UUID string identifying this 圃場マスタ.
     * @param {boolean} [valid] 有効なデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileFarmsFieldsRead(farmPk: string, id: string, valid?: boolean, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MobileFarmsFieldsRead(farmPk, id, valid, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 農場情報を取得する。オプションでteam_idを付与すればチームでフィルタする。
     * @summary 農場情報の取得
     * @param {string} [idIn] Multiple values may be separated by commas.
     * @param {boolean} [valid] 有効なデータのみ取得する場合、true
     * @param {number} [lat] 中心点の緯度(latitude)
     * @param {number} [_long] 中心点の緯度(longitude)
     * @param {number} [distance] データを取得する中心点からの距離半径(単位：メートル)
     * @param {string} [teamId] チームID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileFarmsList(
        idIn?: string,
        valid?: boolean,
        lat?: number,
        _long?: number,
        distance?: number,
        teamId?: string,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MobileFarmsList(idIn, valid, lat, _long, distance, teamId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 障害物測量結果情報を取得する。
     * @summary 障害物測量結果情報の取得
     * @param {string} farmPk
     * @param {string} id A UUID string identifying this 障害物測量結果マスタ.
     * @param {boolean} [valid] 有効なデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileFarmsObstaclesurveysRead(
        farmPk: string,
        id: string,
        valid?: boolean,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MobileFarmsObstaclesurveysRead(farmPk, id, valid, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 農場情報を取得する。オプションでteam_idを付与すればチームでフィルタする。
     * @summary 農場情報の取得
     * @param {string} id A UUID string identifying this 農場マスタ.
     * @param {boolean} [valid] 有効なデータのみ取得する場合、true
     * @param {number} [lat] 中心点の緯度(latitude)
     * @param {number} [_long] 中心点の緯度(longitude)
     * @param {number} [distance] データを取得する中心点からの距離半径(単位：メートル)
     * @param {string} [teamId] チームID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileFarmsRead(
        id: string,
        valid?: boolean,
        lat?: number,
        _long?: number,
        distance?: number,
        teamId?: string,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MobileFarmsRead(id, valid, lat, _long, distance, teamId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 機体飛行履歴サマリーの取得
     * @summary 機体飛行履歴サマリー
     * @param {string} serialNumber 機体シリアル番号。e.g. AC190001
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileMachinesAcFlightSummaryRead(serialNumber: string, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MobileMachinesAcFlightSummaryRead(serialNumber, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 飛行履歴一覧を取得する。
     * @summary 飛行履歴一覧の取得(v2)
     * @param {string} [fieldId] 圃場IDの完全一致
     * @param {string} [fromDatetime] 取得始端日時(iso8601形式)
     * @param {string} [toDatetime] 取得終端日時(iso8601形式)
     * @param {string} [flightUsername] 飛行ユーザー名(ログインID)の完全一致
     * @param {string} [droneBsVpnUserVpnHubName] GID(VPN HUB名)の完全一致
     * @param {string} [droneTbSerialNumber] タブレットシリアル番号の完全一致
     * @param {string} [droneBsSerialNumber] 基地局シリアル番号の完全一致
     * @param {string} [droneAcSerialNumber] 機体シリアル番号の完全一致
     * @param {string} [droneCaSerialNumber] カメラシリアル番号の完全一致
     * @param {string} [referenceNumber] 国交省申請の整理番号の完全一致
     * @param {number} [nwSerialNumber] NW通し番号の完全一致
     * @param {string} [ordering] \&quot;flight_started\&quot;: 飛行開始日時(昇順), \&quot;-flight_started\&quot;: 飛行開始日時(降順)
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileOperationFlightsList(
        fieldId?: string,
        fromDatetime?: string,
        toDatetime?: string,
        flightUsername?: string,
        droneBsVpnUserVpnHubName?: string,
        droneTbSerialNumber?: string,
        droneBsSerialNumber?: string,
        droneAcSerialNumber?: string,
        droneCaSerialNumber?: string,
        referenceNumber?: string,
        nwSerialNumber?: number,
        ordering?: string,
        limit?: number,
        offset?: number,
        options?: any
    ) {
        return V2ApiFp(this.configuration)
            .v2MobileOperationFlightsList(
                fieldId,
                fromDatetime,
                toDatetime,
                flightUsername,
                droneBsVpnUserVpnHubName,
                droneTbSerialNumber,
                droneBsSerialNumber,
                droneAcSerialNumber,
                droneCaSerialNumber,
                referenceNumber,
                nwSerialNumber,
                ordering,
                limit,
                offset,
                options
            )
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 国交省申請管理簿のレコードと、それにひもづくメンバーとその属する組織+親組織リストの一覧を返す。
     * @summary 国交省申請管理簿の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public v2MobileOperationMinistryreportsList(limit?: number, offset?: number, options?: any) {
        return V2ApiFp(this.configuration)
            .v2MobileOperationMinistryreportsList(limit, offset, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * V3Api - axios parameter creator
 * @export
 */
export const V3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Hasura, Nodejsサーバーにアクセスするときに使うJWTを生成して返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3AccountsGenerateJwtList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/accounts/generate_jwt/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * GID（VPN HUB）、基地局、機体、カメラ、測量機、タブレットを紐付ける。
         * @summary アクティベーション
         * @param {JigApiV3ActivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigActivationCreate: async (
            data: JigApiV3ActivationCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v3JigActivationCreate.'
                )
            }
            const localVarPath = `/v3/jig/activation/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * GIDを指定してVPN HUBのアクティベーションを解除する。 同じGIDに複数ペアリングされている場合はすべて解除する。
         * @summary アクティベーション解除
         * @param {JigApiV3DeactivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigDeactivationCreate: async (
            data: JigApiV3DeactivationCreate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v3JigDeactivationCreate.'
                )
            }
            const localVarPath = `/v3/jig/deactivation/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(機体用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {JigApiV3EnvSyncAc} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigEnvSyncAcCreate: async (
            data: JigApiV3EnvSyncAc,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v3JigEnvSyncAcCreate.'
                )
            }
            const localVarPath = `/v3/jig/env_sync_ac/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(機体用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {string} id A UUID string identifying this drone ac proxy for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigEnvSyncAcRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v3JigEnvSyncAcRead.'
                )
            }
            const localVarPath = `/v3/jig/env_sync_ac/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(カメラ用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {JigApiV3EnvSyncCa} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigEnvSyncCaCreate: async (
            data: JigApiV3EnvSyncCa,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v3JigEnvSyncCaCreate.'
                )
            }
            const localVarPath = `/v3/jig/env_sync_ca/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(カメラ用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {string} id A UUID string identifying this drone ca proxy for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigEnvSyncCaRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v3JigEnvSyncCaRead.'
                )
            }
            const localVarPath = `/v3/jig/env_sync_ca/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * GIDとペアリングされている機材の一覧を取得する。
         * @summary GIDペアリング一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [includeT19] T19のGIDを含める場合,true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigPairingList: async (
            limit?: number,
            offset?: number,
            includeT19?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v3/jig/pairing/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (includeT19 !== undefined) {
                localVarQueryParameter['include_t19'] = includeT19
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * 指定したGIDにペアリングされている機材を取得する。
         * @summary GIDペアリングの取得
         * @param {string} vpnHubName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigPairingRead: async (vpnHubName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vpnHubName' is not null or undefined
            if (vpnHubName === null || vpnHubName === undefined) {
                throw new RequiredError(
                    'vpnHubName',
                    'Required parameter vpnHubName was null or undefined when calling v3JigPairingRead.'
                )
            }
            const localVarPath = `/v3/jig/pairing/{vpn_hub__name}/`.replace(
                `{${'vpn_hub__name'}}`,
                encodeURIComponent(String(vpnHubName))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * アプリログを新規登録する。
         * @summary アプリログの新規登録
         * @param {MobileApiV3AppLog} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileAppLogCreate: async (
            data: MobileApiV3AppLog,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v3MobileAppLogCreate.'
                )
            }
            const localVarPath = `/v3/mobile/app_log/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * リクエストしたユーザーが属する組織とその子組織一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileOrganizationsList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/mobile/organizations/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * チーム情報を新規登録する。
         * @summary チーム情報の新規登録
         * @param {MobileApiV3TeamUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamCreate: async (
            data: MobileApiV3TeamUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v3MobileTeamCreate.'
                )
            }
            const localVarPath = `/v3/mobile/team/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * チーム情報を無効化する。
         * @summary チーム情報の無効化
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v3MobileTeamDelete.'
                )
            }
            const localVarPath = `/v3/mobile/team/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * チーム情報一覧を取得する。
         * @summary チーム情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {boolean} [assigned] 自分がアサインされたチームのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamList: async (
            limit?: number,
            offset?: number,
            valid?: boolean,
            assigned?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/v3/mobile/team/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset
            }

            if (valid !== undefined) {
                localVarQueryParameter['valid'] = valid
            }

            if (assigned !== undefined) {
                localVarQueryParameter['assigned'] = assigned
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * チーム情報を部分更新する。
         * @summary チーム情報の部分更新
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {MobileApiV3TeamUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamPartialUpdate: async (
            id: string,
            data: MobileApiV3TeamUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v3MobileTeamPartialUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v3MobileTeamPartialUpdate.'
                )
            }
            const localVarPath = `/v3/mobile/team/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * チーム情報を取得する。
         * @summary チーム情報の取得
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamRead: async (
            id: string,
            valid?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v3MobileTeamRead.'
                )
            }
            const localVarPath = `/v3/mobile/team/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (valid !== undefined) {
                localVarQueryParameter['valid'] = valid
            }

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * チーム情報を更新する。
         * @summary チーム情報の更新
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {MobileApiV3TeamUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamUpdate: async (
            id: string,
            data: MobileApiV3TeamUpdate,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    'id',
                    'Required parameter id was null or undefined when calling v3MobileTeamUpdate.'
                )
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError(
                    'data',
                    'Required parameter data was null or undefined when calling v3MobileTeamUpdate.'
                )
            }
            const localVarPath = `/v3/mobile/team/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            const nonString = typeof data !== 'string'
            const needsSerialization =
                nonString && configuration && configuration.isJsonMime
                    ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                    : nonString
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : data || ''

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
        /**
         * ログインしているユーザ組織以下の組織に属する有効なユーザーを取得する。
         * @summary 指定できるユーザー一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileUserList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/mobile/user/`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com')
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            const queryParameters = new URLSearchParams(localVarUrlObj.search)
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key])
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key])
            }
            localVarUrlObj.search = new URLSearchParams(queryParameters).toString()
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * V3Api - functional programming interface
 * @export
 */
export const V3ApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Hasura, Nodejsサーバーにアクセスするときに使うJWTを生成して返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3AccountsGenerateJwtList(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateJWT>> {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3AccountsGenerateJwtList(options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * GID（VPN HUB）、基地局、機体、カメラ、測量機、タブレットを紐付ける。
         * @summary アクティベーション
         * @param {JigApiV3ActivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3JigActivationCreate(
            data: JigApiV3ActivationCreate,
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<JigApiV3ActivationCreateResponse>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3JigActivationCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * GIDを指定してVPN HUBのアクティベーションを解除する。 同じGIDに複数ペアリングされている場合はすべて解除する。
         * @summary アクティベーション解除
         * @param {JigApiV3DeactivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3JigDeactivationCreate(
            data: JigApiV3DeactivationCreate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigApiV3DeactivationCreate>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3JigDeactivationCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(機体用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {JigApiV3EnvSyncAc} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3JigEnvSyncAcCreate(
            data: JigApiV3EnvSyncAc,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigApiV3EnvSyncAc>> {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3JigEnvSyncAcCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(機体用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {string} id A UUID string identifying this drone ac proxy for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3JigEnvSyncAcRead(
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigApiV3EnvSyncAc>> {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3JigEnvSyncAcRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(カメラ用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {JigApiV3EnvSyncCa} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3JigEnvSyncCaCreate(
            data: JigApiV3EnvSyncCa,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigApiV3EnvSyncCa>> {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3JigEnvSyncCaCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(カメラ用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {string} id A UUID string identifying this drone ca proxy for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3JigEnvSyncCaRead(
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigApiV3EnvSyncCa>> {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3JigEnvSyncCaRead(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * GIDとペアリングされている機材の一覧を取得する。
         * @summary GIDペアリング一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [includeT19] T19のGIDを含める場合,true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3JigPairingList(
            limit?: number,
            offset?: number,
            includeT19?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(configuration).v3JigPairingList(
                limit,
                offset,
                includeT19,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * 指定したGIDにペアリングされている機材を取得する。
         * @summary GIDペアリングの取得
         * @param {string} vpnHubName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3JigPairingRead(
            vpnHubName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JigApiV3Pairing>> {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(configuration).v3JigPairingRead(
                vpnHubName,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * アプリログを新規登録する。
         * @summary アプリログの新規登録
         * @param {MobileApiV3AppLog} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3MobileAppLogCreate(
            data: MobileApiV3AppLog,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileApiV3AppLog>> {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3MobileAppLogCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * リクエストしたユーザーが属する組織とその子組織一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3MobileOrganizationsList(
            options?: any
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<Array<MobileApiV3OrganizationList>>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3MobileOrganizationsList(options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * チーム情報を新規登録する。
         * @summary チーム情報の新規登録
         * @param {MobileApiV3TeamUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3MobileTeamCreate(
            data: MobileApiV3TeamUpdate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileApiV3TeamUpdate>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3MobileTeamCreate(data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * チーム情報を無効化する。
         * @summary チーム情報の無効化
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3MobileTeamDelete(
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3MobileTeamDelete(id, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * チーム情報一覧を取得する。
         * @summary チーム情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {boolean} [assigned] 自分がアサインされたチームのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3MobileTeamList(
            limit?: number,
            offset?: number,
            valid?: boolean,
            assigned?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(configuration).v3MobileTeamList(
                limit,
                offset,
                valid,
                assigned,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * チーム情報を部分更新する。
         * @summary チーム情報の部分更新
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {MobileApiV3TeamUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3MobileTeamPartialUpdate(
            id: string,
            data: MobileApiV3TeamUpdate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileApiV3TeamUpdate>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3MobileTeamPartialUpdate(id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * チーム情報を取得する。
         * @summary チーム情報の取得
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3MobileTeamRead(
            id: string,
            valid?: boolean,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileApiV3TeamList>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(configuration).v3MobileTeamRead(
                id,
                valid,
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * チーム情報を更新する。
         * @summary チーム情報の更新
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {MobileApiV3TeamUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3MobileTeamUpdate(
            id: string,
            data: MobileApiV3TeamUpdate,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileApiV3TeamUpdate>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(
                configuration
            ).v3MobileTeamUpdate(id, data, options)
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
        /**
         * ログインしているユーザ組織以下の組織に属する有効なユーザーを取得する。
         * @summary 指定できるユーザー一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3MobileUserList(
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MobileV3UserList>>
        > {
            const localVarAxiosArgs = await V3ApiAxiosParamCreator(configuration).v3MobileUserList(
                options
            )
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {
                    ...localVarAxiosArgs.options,
                    url: basePath + localVarAxiosArgs.url,
                }
                return axios.request(axiosRequestArgs)
            }
        },
    }
}

/**
 * V3Api - factory interface
 * @export
 */
export const V3ApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    return {
        /**
         * Hasura, Nodejsサーバーにアクセスするときに使うJWTを生成して返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3AccountsGenerateJwtList(options?: any): AxiosPromise<GenerateJWT> {
            return V3ApiFp(configuration)
                .v3AccountsGenerateJwtList(options)
                .then((request) => request(axios, basePath))
        },
        /**
         * GID（VPN HUB）、基地局、機体、カメラ、測量機、タブレットを紐付ける。
         * @summary アクティベーション
         * @param {JigApiV3ActivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigActivationCreate(
            data: JigApiV3ActivationCreate,
            options?: any
        ): AxiosPromise<JigApiV3ActivationCreateResponse> {
            return V3ApiFp(configuration)
                .v3JigActivationCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * GIDを指定してVPN HUBのアクティベーションを解除する。 同じGIDに複数ペアリングされている場合はすべて解除する。
         * @summary アクティベーション解除
         * @param {JigApiV3DeactivationCreate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigDeactivationCreate(
            data: JigApiV3DeactivationCreate,
            options?: any
        ): AxiosPromise<JigApiV3DeactivationCreate> {
            return V3ApiFp(configuration)
                .v3JigDeactivationCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(機体用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {JigApiV3EnvSyncAc} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigEnvSyncAcCreate(
            data: JigApiV3EnvSyncAc,
            options?: any
        ): AxiosPromise<JigApiV3EnvSyncAc> {
            return V3ApiFp(configuration)
                .v3JigEnvSyncAcCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(機体用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {string} id A UUID string identifying this drone ac proxy for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigEnvSyncAcRead(id: string, options?: any): AxiosPromise<JigApiV3EnvSyncAc> {
            return V3ApiFp(configuration)
                .v3JigEnvSyncAcRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(カメラ用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {JigApiV3EnvSyncCa} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigEnvSyncCaCreate(
            data: JigApiV3EnvSyncCa,
            options?: any
        ): AxiosPromise<JigApiV3EnvSyncCa> {
            return V3ApiFp(configuration)
                .v3JigEnvSyncCaCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 環境間で機材データのSyncをする場合に使用するAPI(カメラ用) GETでデータを取得し、同期先の環境でPOSTを実行する
         * @param {string} id A UUID string identifying this drone ca proxy for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigEnvSyncCaRead(id: string, options?: any): AxiosPromise<JigApiV3EnvSyncCa> {
            return V3ApiFp(configuration)
                .v3JigEnvSyncCaRead(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * GIDとペアリングされている機材の一覧を取得する。
         * @summary GIDペアリング一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [includeT19] T19のGIDを含める場合,true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigPairingList(
            limit?: number,
            offset?: number,
            includeT19?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse20020> {
            return V3ApiFp(configuration)
                .v3JigPairingList(limit, offset, includeT19, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * 指定したGIDにペアリングされている機材を取得する。
         * @summary GIDペアリングの取得
         * @param {string} vpnHubName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3JigPairingRead(vpnHubName: string, options?: any): AxiosPromise<JigApiV3Pairing> {
            return V3ApiFp(configuration)
                .v3JigPairingRead(vpnHubName, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * アプリログを新規登録する。
         * @summary アプリログの新規登録
         * @param {MobileApiV3AppLog} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileAppLogCreate(
            data: MobileApiV3AppLog,
            options?: any
        ): AxiosPromise<MobileApiV3AppLog> {
            return V3ApiFp(configuration)
                .v3MobileAppLogCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * リクエストしたユーザーが属する組織とその子組織一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileOrganizationsList(options?: any): AxiosPromise<Array<MobileApiV3OrganizationList>> {
            return V3ApiFp(configuration)
                .v3MobileOrganizationsList(options)
                .then((request) => request(axios, basePath))
        },
        /**
         * チーム情報を新規登録する。
         * @summary チーム情報の新規登録
         * @param {MobileApiV3TeamUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamCreate(
            data: MobileApiV3TeamUpdate,
            options?: any
        ): AxiosPromise<MobileApiV3TeamUpdate> {
            return V3ApiFp(configuration)
                .v3MobileTeamCreate(data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * チーム情報を無効化する。
         * @summary チーム情報の無効化
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamDelete(id: string, options?: any): AxiosPromise<void> {
            return V3ApiFp(configuration)
                .v3MobileTeamDelete(id, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * チーム情報一覧を取得する。
         * @summary チーム情報一覧の取得
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {boolean} [assigned] 自分がアサインされたチームのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamList(
            limit?: number,
            offset?: number,
            valid?: boolean,
            assigned?: boolean,
            options?: any
        ): AxiosPromise<InlineResponse20021> {
            return V3ApiFp(configuration)
                .v3MobileTeamList(limit, offset, valid, assigned, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * チーム情報を部分更新する。
         * @summary チーム情報の部分更新
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {MobileApiV3TeamUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamPartialUpdate(
            id: string,
            data: MobileApiV3TeamUpdate,
            options?: any
        ): AxiosPromise<MobileApiV3TeamUpdate> {
            return V3ApiFp(configuration)
                .v3MobileTeamPartialUpdate(id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * チーム情報を取得する。
         * @summary チーム情報の取得
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {boolean} [valid] 有効なデータのみ取得する場合、true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamRead(
            id: string,
            valid?: boolean,
            options?: any
        ): AxiosPromise<MobileApiV3TeamList> {
            return V3ApiFp(configuration)
                .v3MobileTeamRead(id, valid, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * チーム情報を更新する。
         * @summary チーム情報の更新
         * @param {string} id A UUID string identifying this チームマスタ.
         * @param {MobileApiV3TeamUpdate} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileTeamUpdate(
            id: string,
            data: MobileApiV3TeamUpdate,
            options?: any
        ): AxiosPromise<MobileApiV3TeamUpdate> {
            return V3ApiFp(configuration)
                .v3MobileTeamUpdate(id, data, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * ログインしているユーザ組織以下の組織に属する有効なユーザーを取得する。
         * @summary 指定できるユーザー一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3MobileUserList(options?: any): AxiosPromise<Array<MobileV3UserList>> {
            return V3ApiFp(configuration)
                .v3MobileUserList(options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * V3Api - object-oriented interface
 * @export
 * @class V3Api
 * @extends {BaseAPI}
 */
export class V3Api extends BaseAPI {
    /**
     * Hasura, Nodejsサーバーにアクセスするときに使うJWTを生成して返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3AccountsGenerateJwtList(options?: any) {
        return V3ApiFp(this.configuration)
            .v3AccountsGenerateJwtList(options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * GID（VPN HUB）、基地局、機体、カメラ、測量機、タブレットを紐付ける。
     * @summary アクティベーション
     * @param {JigApiV3ActivationCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3JigActivationCreate(data: JigApiV3ActivationCreate, options?: any) {
        return V3ApiFp(this.configuration)
            .v3JigActivationCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * GIDを指定してVPN HUBのアクティベーションを解除する。 同じGIDに複数ペアリングされている場合はすべて解除する。
     * @summary アクティベーション解除
     * @param {JigApiV3DeactivationCreate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3JigDeactivationCreate(data: JigApiV3DeactivationCreate, options?: any) {
        return V3ApiFp(this.configuration)
            .v3JigDeactivationCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 環境間で機材データのSyncをする場合に使用するAPI(機体用) GETでデータを取得し、同期先の環境でPOSTを実行する
     * @param {JigApiV3EnvSyncAc} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3JigEnvSyncAcCreate(data: JigApiV3EnvSyncAc, options?: any) {
        return V3ApiFp(this.configuration)
            .v3JigEnvSyncAcCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 環境間で機材データのSyncをする場合に使用するAPI(機体用) GETでデータを取得し、同期先の環境でPOSTを実行する
     * @param {string} id A UUID string identifying this drone ac proxy for sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3JigEnvSyncAcRead(id: string, options?: any) {
        return V3ApiFp(this.configuration)
            .v3JigEnvSyncAcRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 環境間で機材データのSyncをする場合に使用するAPI(カメラ用) GETでデータを取得し、同期先の環境でPOSTを実行する
     * @param {JigApiV3EnvSyncCa} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3JigEnvSyncCaCreate(data: JigApiV3EnvSyncCa, options?: any) {
        return V3ApiFp(this.configuration)
            .v3JigEnvSyncCaCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 環境間で機材データのSyncをする場合に使用するAPI(カメラ用) GETでデータを取得し、同期先の環境でPOSTを実行する
     * @param {string} id A UUID string identifying this drone ca proxy for sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3JigEnvSyncCaRead(id: string, options?: any) {
        return V3ApiFp(this.configuration)
            .v3JigEnvSyncCaRead(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * GIDとペアリングされている機材の一覧を取得する。
     * @summary GIDペアリング一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [includeT19] T19のGIDを含める場合,true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3JigPairingList(limit?: number, offset?: number, includeT19?: boolean, options?: any) {
        return V3ApiFp(this.configuration)
            .v3JigPairingList(limit, offset, includeT19, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * 指定したGIDにペアリングされている機材を取得する。
     * @summary GIDペアリングの取得
     * @param {string} vpnHubName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3JigPairingRead(vpnHubName: string, options?: any) {
        return V3ApiFp(this.configuration)
            .v3JigPairingRead(vpnHubName, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * アプリログを新規登録する。
     * @summary アプリログの新規登録
     * @param {MobileApiV3AppLog} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3MobileAppLogCreate(data: MobileApiV3AppLog, options?: any) {
        return V3ApiFp(this.configuration)
            .v3MobileAppLogCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * リクエストしたユーザーが属する組織とその子組織一覧を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3MobileOrganizationsList(options?: any) {
        return V3ApiFp(this.configuration)
            .v3MobileOrganizationsList(options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * チーム情報を新規登録する。
     * @summary チーム情報の新規登録
     * @param {MobileApiV3TeamUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3MobileTeamCreate(data: MobileApiV3TeamUpdate, options?: any) {
        return V3ApiFp(this.configuration)
            .v3MobileTeamCreate(data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * チーム情報を無効化する。
     * @summary チーム情報の無効化
     * @param {string} id A UUID string identifying this チームマスタ.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3MobileTeamDelete(id: string, options?: any) {
        return V3ApiFp(this.configuration)
            .v3MobileTeamDelete(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * チーム情報一覧を取得する。
     * @summary チーム情報一覧の取得
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [valid] 有効なデータのみ取得する場合、true
     * @param {boolean} [assigned] 自分がアサインされたチームのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3MobileTeamList(
        limit?: number,
        offset?: number,
        valid?: boolean,
        assigned?: boolean,
        options?: any
    ) {
        return V3ApiFp(this.configuration)
            .v3MobileTeamList(limit, offset, valid, assigned, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * チーム情報を部分更新する。
     * @summary チーム情報の部分更新
     * @param {string} id A UUID string identifying this チームマスタ.
     * @param {MobileApiV3TeamUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3MobileTeamPartialUpdate(id: string, data: MobileApiV3TeamUpdate, options?: any) {
        return V3ApiFp(this.configuration)
            .v3MobileTeamPartialUpdate(id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * チーム情報を取得する。
     * @summary チーム情報の取得
     * @param {string} id A UUID string identifying this チームマスタ.
     * @param {boolean} [valid] 有効なデータのみ取得する場合、true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3MobileTeamRead(id: string, valid?: boolean, options?: any) {
        return V3ApiFp(this.configuration)
            .v3MobileTeamRead(id, valid, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * チーム情報を更新する。
     * @summary チーム情報の更新
     * @param {string} id A UUID string identifying this チームマスタ.
     * @param {MobileApiV3TeamUpdate} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3MobileTeamUpdate(id: string, data: MobileApiV3TeamUpdate, options?: any) {
        return V3ApiFp(this.configuration)
            .v3MobileTeamUpdate(id, data, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * ログインしているユーザ組織以下の組織に属する有効なユーザーを取得する。
     * @summary 指定できるユーザー一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V3Api
     */
    public v3MobileUserList(options?: any) {
        return V3ApiFp(this.configuration)
            .v3MobileUserList(options)
            .then((request) => request(this.axios, this.basePath))
    }
}
