/**
 * 状態管理はFluxパターンを採用
 * 仕組みはHooksを利用
 */
import { UserProfile } from '../lib-ns-service'

export interface RootState {
    user?: UserProfile
    isLoading?: boolean
}

export interface RootAction {
    type: ActionType
    payload: {
        user?: UserProfile
        isLoading?: boolean
    }
}

// eslint-disable-next-line no-shadow
export enum ActionType {
    ACTION_UPDATE_USER,
    ACTION_SET_LOADING,
}

export const appReducer: React.Reducer<RootState, RootAction> = (
    state: RootState,
    action: RootAction
) => {
    switch (action.type) {
        case ActionType.ACTION_UPDATE_USER:
            return {
                ...state,
                user: action.payload.user,
            }
        case ActionType.ACTION_SET_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            }
        default:
            throw new Error()
    }
}
