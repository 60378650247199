// APIサーバのパス
export const API_SERVER_URL = ((env?: string): string => {
    switch (env) {
        case 'production':
            return 'https://app.nileworks.io/'
        case 'staging':
            return 'https://app-staging.nileworks.net/'
        case 'development':
            return 'https://app-development.nileworks.org/'
        default:
            return 'http://localhost:8000/'
    }
})(process.env.REACT_APP_TARGET)

export const API_BASE_URL = `${API_SERVER_URL}api`
export const AUTHORIZE_URL = `${API_SERVER_URL}accounts/o/authorize/`
export const ACCESS_TOKEN_URL = `${API_SERVER_URL}accounts/o/token/`
export const LOGOUT_URL = `${API_SERVER_URL}api/a/logout/?next=/accounts/logout_complete/`

export const spinnerColorMain = 'rgba(75, 200, 80, 0.1)'
export const spinnerColorBack = 'rgba(75, 200, 80, 1.0)'

export const headerBackgroundColor = '#6d993a'
export const headerFontColor = 'white'

export const listitemSelectedColor = '#9ad654'
