import * as Sentry from '@sentry/react'
import axios from 'axios'
import * as React from 'react'
import styled from 'styled-components'
import { API_BASE_URL, API_SERVER_URL } from '../common/Const'
import { Configuration, V1Api } from '../lib-ns-service'
import { ActionType, appReducer, RootAction, RootState } from '../store/Store'
import GidList from './GidList'
import Header from './Header'
import LoadingScreen from './LoadingScreen'

export const AppContext = React.createContext(
    {} as {
        state: RootState
        dispatch: React.Dispatch<RootAction>
    }
)

export const getAxiosConfig = (): Configuration => {
    const config = new Configuration()
    config.basePath = API_BASE_URL
    return config
}

export const goLoginPage = (): void => {
    window.location.href = `${API_SERVER_URL}api/a/login/?next=${window.location.href}`
}

/**
 * メイン画面
 */
const App: React.FunctionComponent = () => {
    const [state, dispatch] = React.useReducer(appReducer, {})

    // 起動時の処理
    React.useEffect(() => {
        initAxiosConfig()
        createAxiosLoginInterceptor()
        getLoginUserProfile()
    }, [])

    // Axiosのdefaultを設定
    const initAxiosConfig = () => {
        axios.defaults.xsrfHeaderName = 'X-CSRFToken'
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.withCredentials = true
    }

    // 認証エラーの場合はログインページに飛ばす
    const createAxiosLoginInterceptor = () => {
        const interceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                const status = error.response ? error.response.status : null
                // if (status !== 401 && status !== 403) {
                if (status !== 401) {
                    return Promise.reject(error)
                }
                goLoginPage()
                return Promise.resolve()
            }
        )
    }

    // ユーザ情報取得
    const getLoginUserProfile = () => {
        const config = getAxiosConfig()
        const v1Api = new V1Api(config, config.basePath, axios)
        v1Api
            .v1AccountsProfileList()
            .then((result) => {
                Sentry.setContext('UserProfile', result.data)
                console.log(result.data)
                dispatch({
                    type: ActionType.ACTION_UPDATE_USER,
                    payload: { user: result.data },
                })
            })
            .catch((ex: any) => {
                console.error(ex)
            })
    }

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            <Wrapper>
                <Header />
                <LoadingScreen />
                <GidList />
            </Wrapper>
        </AppContext.Provider>
    )
}

const Wrapper = styled.div`
    @media (max-width: 768px) {
        width: 1350px;
    }
`

export default App
